// ** Actions Types for Manufacturers
const REQUEST_MANUFACTURERS = "REQUEST_MANUFACTURERS";
const REQUEST_MANUFACTURERS_SUCCESS = "REQUEST_MANUFACTURERS_SUCCESS";
const REQUEST_MANUFACTURERS_FAILED = "REQUEST_MANUFACTURERS_FAILED";
// ** Actions Types for Manufacturers Item
const REQUEST_MANUFACTURERS_ITEM = "REQUEST_MANUFACTURERS_ITEM";
const REQUEST_MANUFACTURERS_ITEM_SUCCESS = "REQUEST_MANUFACTURERS_ITEM_SUCCESS";
const REQUEST_MANUFACTURERS_ITEM_FAILED = "REQUEST_MANUFACTURERS_ITEM_FAILED";
//** Actions Types For Add Manufacturer
const REQUEST_ADD_MANUFACTURER = "REQUEST_ADD_MANUFACTURER";
const REQUEST_ADD_MANUFACTURER_SUCCESS = "REQUEST_ADD_MANUFACTURER_SUCCESS";
const REQUEST_ADD_MANUFACTURER_FAILED = "REQUEST_ADD_MANUFACTURER_FAILED";
//** Actions Types For Edit Manufacturer
const REQUEST_EDIT_MANUFACTURER = "REQUEST_EDIT_MANUFACTURER";
const REQUEST_EDIT_MANUFACTURER_SUCCESS = "REQUEST_EDIT_MANUFACTURER_SUCCESS";
const REQUEST_EDIT_MANUFACTURER_FAILED = "REQUEST_EDIT_MANUFACTURER_FAILED";

// ** Initial State
const initialState = {
  manufacturersList: [],
  manufacturersTotalNum: "",
  isLoadingGetManufacturersList: false,
  isFailedGetManufacturersList: false,
  // ** State For Manufacturers Item
  manufacturerItem: {},
  isLoadingManufacturerItem: false,
  isfailedManufacturerItem: false,
  // ** Add Manufacturer
  isloadingAddManufacturer: false,
  successAddManufacturer: false,
  isfailedAddManufacturer: false,
  //** Edit Manufacturer
  isloadingEditManufacturer: false,
  successEditManufacturer: false,
  isfailedEditManufacturer: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Manufacturers List
    case REQUEST_MANUFACTURERS:
      return {
        ...state,
        isLoadingGetManufacturersList: true,
      };
    case REQUEST_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        manufacturersList: action.manufacturersList,
        manufacturersTotalNum: action.manufacturersTotalNum,
        isLoadingGetManufacturersList: false,
        isFailedGetManufacturersList: false,
      };
    case REQUEST_MANUFACTURERS_FAILED:
      return {
        ...state,
        isLoadingGetManufacturersList: false,
        isFailedGetManufacturersList: true,
      };
    // ** Reducers for Manufacturers Item
    case REQUEST_MANUFACTURERS_ITEM:
      return {
        ...state,
        isLoadingManufacturerItem: true,
      };
    case REQUEST_MANUFACTURERS_ITEM_SUCCESS:
      return {
        ...state,
        manufacturerItem: action.manufacturerItem,
        isLoadingManufacturerItem: false,
        isfailedManufacturerItem: false,
      };
    case REQUEST_MANUFACTURERS_ITEM_FAILED:
      return {
        ...state,
        isLoadingManufacturerItem: false,
        isfailedManufacturerItem: true,
      };
    // ** Reducers for Add Manufacturer
    case REQUEST_ADD_MANUFACTURER:
      return {
        ...state,
        isloadingAddManufacturer: true,
      };
    case REQUEST_ADD_MANUFACTURER_SUCCESS:
      return {
        ...state,
        isloadingAddManufacturer: false,
        successAddManufacturer: true,
        isfailedEditManufacturer: false,
      };
    case REQUEST_ADD_MANUFACTURER_FAILED:
      return {
        ...state,
        isloadingAddManufacturer: false,
        successAddManufacturer: false,
        isfailedEditManufacturer: true,
      };
    //**  Reducers for Edit Manufacturer
    case REQUEST_EDIT_MANUFACTURER:
      return {
        ...state,
        isloadingEditManufacturer: true,
      };
    case REQUEST_EDIT_MANUFACTURER_SUCCESS:
      return {
        ...state,
        isloadingEditManufacturer: false,
        successEditManufacturer: true,
        isfailedEditManufacturer: false,
      };
    case REQUEST_EDIT_MANUFACTURER_FAILED:
      return {
        ...state,
        isloadingEditManufacturer: false,
        successEditManufacturer: false,
        isfailedEditManufacturer: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Manufacturer
export const requestGetManufacturersList = () => {
  return {
    type: REQUEST_MANUFACTURERS,
  };
};
export const requestGetManufacturersListSuccess = (
  manufacturersList,
  manufacturersTotalNum
) => {
  return {
    type: REQUEST_MANUFACTURERS_SUCCESS,
    manufacturersList: manufacturersList,
    manufacturersTotalNum: manufacturersTotalNum,
  };
};
export const requestGetManufacturersListFailed = (message) => {
  return {
    type: REQUEST_MANUFACTURERS_FAILED,
  };
};

// ** Actions for Manufacturer Item
export const requestGetManufacturerItem = () => {
  return {
    type: REQUEST_MANUFACTURERS_ITEM,
  };
};
export const requestGetManufacturerItemSuccess = (manufacturerItem) => {
  return {
    type: REQUEST_MANUFACTURERS_ITEM_SUCCESS,
    manufacturerItem: manufacturerItem,
  };
};
export const requestGetManufacturerItemFailed = () => {
  return {
    type: REQUEST_MANUFACTURERS_ITEM_FAILED,
  };
};
//** Actions For Add Servic
export const requestAddServic = () => {
  return {
    type: REQUEST_ADD_MANUFACTURER,
  };
};
export const requestAddServicSuccess = (message) => {
  return {
    type: REQUEST_ADD_MANUFACTURER_SUCCESS,
    message: message,
  };
};
export const requestAddServicFaield = (message) => {
  return {
    type: REQUEST_ADD_MANUFACTURER_FAILED,
    message: message,
  };
};

//** Actions For Edit Manufacturer
export const requestEditServic = () => {
  return {
    type: REQUEST_EDIT_MANUFACTURER,
  };
};
export const requestEditServicSuccess = (message) => {
  return {
    type: REQUEST_EDIT_MANUFACTURER_SUCCESS,
    message: message,
  };
};
export const requestEditServicFaield = (message) => {
  return {
    type: REQUEST_EDIT_MANUFACTURER_FAILED,
    message: message,
  };
};
