// ** Actions Types for Log In Request
const REQUEST_LOG_IN = "REQUEST_LOG_IN";
const REQUEST_LOG_IN_SUCCESS = "REQUEST_LOG_IN_SUCCESS";
const REQUEST_LOG_IN_FAILED = "REQUEST_LOG_IN_FAILED";
const REQUEST_LOG_OUT = "REQUEST_LOG_OUT";

// ** Initial State
const initialState = {
  user: null,
  isSignedIn: false,
  isLoadingLogInRequest: false,
  isFailedLogInRequest: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LOG_IN:
      return {
        ...state,
        isLoadingLogInRequest: true,
      };
    case REQUEST_LOG_IN_SUCCESS:
      return {
        ...state,
        user: action.user,
        isSignedIn: true,
        isLoadingLogInRequest: false,
      };
    case REQUEST_LOG_IN_FAILED:
      return {
        ...state,
        isLoadingLogInRequest: false,
        isFailedLogInRequest: true,
      };
    case REQUEST_LOG_OUT:
      return {
        ...state,
        user: null,
        isSignedIn: false,
      };

    default:
      return state;
  }
};

// ** Action Creators For Log In Request
export const requestLogin = () => {
  return {
    type: REQUEST_LOG_IN,
  };
};
export const successLogin = (user) => {
  return {
    type: REQUEST_LOG_IN_SUCCESS,
    user: user,
  };
};
export const failedLogin = () => {
  return {
    type: REQUEST_LOG_IN_FAILED,
  };
};
export const logout = () => {
  return {
    type: REQUEST_LOG_OUT,
  };
};
