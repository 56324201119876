// Actions Types for Blog Gallery
const REQUEST_BLOG_GALLERY = "REQUEST_BLOG_GALLERY";
const REQUEST_BLOG_GALLERY_SUCCESS = "REQUEST_BLOG_GALLERY_SUCCESS";
const REQUEST_BLOG_GALLERY_FAILED = "REQUEST_BLOG_GALLERY_FAILED";
// Actions Types for Blog Gallery Item
const REQUEST_BLOG_GALLERY_ITEM = "REQUEST_BLOG_GALLERY_ITEM";
const REQUEST_BLOG_GALLERY_ITEM_SUCCESS = "REQUEST_BLOG_GALLERY_ITEM_SUCCESS";
const REQUEST_BLOG_GALLERY_ITEM_FAILED = "REQUEST_BLOG_GALLERY_ITEM_FAILED";
// Actions Types For Add Blog Gallery
const REQUEST_ADD_BLOG_GALLERY = "REQUEST_ADD_BLOG_GALLERY";
const REQUEST_ADD_BLOG_GALLERY_SUCCESS = "REQUEST_ADD_BLOG_GALLERY_SUCCESS";
const REQUEST_ADD_BLOG_GALLERY_FAILED = "REQUEST_ADD_BLOG_GALLERY_FAILED";
// Actions Types For Edit Blog Gallery
const REQUEST_EDIT_BLOG_GALLERY = "REQUEST_EDIT_BLOG_GALLERY";
const REQUEST_EDIT_BLOG_GALLERY_SUCCESS = "REQUEST_EDIT_BLOG_GALLERY_SUCCESS";
const REQUEST_EDIT_BLOG_GALLERY_FAILED = "REQUEST_EDIT_BLOG_GALLERY_FAILED";

// Initial State
const initialState = {
  blogGalleryList: [],
  blogsGalleryTotalNum: "",
  isLoadingGetBlogsGalleryList: false,
  isFailedGetBlogsGalleryList: false,
  // State For BLog Gallery Item
  blogGalleryItem: {},
  isLoadingBLogGalleryItem: false,
  isFailedBLogGalleryItem: false,
  // Add BLog Gallery
  isLoadingAddBLogGallery: false,
  successAddBLogGallery: false,
  isFailedAddBLogGallery: false,
  // Edit BLog Gallery
  isLoadingEditBLogGallery: false,
  successEditBLogGallery: false,
  isFailedEditBLogGallery: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // Reducers for BLogs Gallery List
    case REQUEST_BLOG_GALLERY:
      return {
        ...state,
        isLoadingGetBlogsGalleryList: true,
      };
    case REQUEST_BLOG_GALLERY_SUCCESS:
      return {
        ...state,
        blogGalleryList: action.blogGalleryList,
        blogsGalleryTotalNum: action.blogsGalleryTotalNum,
        isLoadingGetBlogsGalleryList: false,
        isFailedGetBlogsGalleryList: false,
      };
    case REQUEST_BLOG_GALLERY_FAILED:
      return {
        ...state,
        isLoadingGetBlogsGalleryList: false,
        isFailedGetBlogsGalleryList: true,
      };
    // Reducers for BLog Gallery Item
    case REQUEST_BLOG_GALLERY_ITEM:
      return {
        ...state,
        isLoadingBLogGalleryItem: true,
      };
    case REQUEST_BLOG_GALLERY_ITEM_SUCCESS:
      return {
        ...state,
        blogGalleryItem: action.blogGalleryItem,
        isLoadingBLogGalleryItem: false,
        isFailedBLogGalleryItem: false,
      };
    case REQUEST_BLOG_GALLERY_ITEM_FAILED:
      return {
        ...state,
        isLoadingBLogGalleryItem: false,
        isFailedBLogGalleryItem: true,
      };
    // Reducers for Add BLog Gallery
    case REQUEST_ADD_BLOG_GALLERY:
      return {
        ...state,
        isLoadingAddBLogGallery: true,
      };
    case REQUEST_ADD_BLOG_GALLERY_SUCCESS:
      return {
        ...state,
        isLoadingAddBLogGallery: false,
        successAddBLogGallery: true,
        isFailedAddBLogGallery: false,
      };
    case REQUEST_ADD_BLOG_GALLERY_FAILED:
      return {
        ...state,
        isLoadingAddBLogGallery: false,
        successAddBLogGallery: false,
        isFailedAddBLogGallery: true,
      };
    // Reducers for Edit BLog Gallery
    case REQUEST_EDIT_BLOG_GALLERY:
      return {
        ...state,
        isLoadingEditBLogGallery: true,
      };
    case REQUEST_EDIT_BLOG_GALLERY_SUCCESS:
      return {
        ...state,
        isLoadingEditBLog: false,
        successEditBLogGallery: true,
        isFailedEditBLogGallery: false,
      };
    case REQUEST_EDIT_BLOG_GALLERY_FAILED:
      return {
        ...state,
        isLoadingEditBLog: false,
        successEditBLogGallery: false,
        isFailedEditBLogGallery: true,
      };
    default:
      return state;
  }
};

// Actions for All BLogs Gallery List
export const requestGetBLogsGalleryList = () => {
  return {
    type: REQUEST_BLOG_GALLERY,
  };
};
export const requestGetBLogsGalleryListSuccess = (
  blogGalleryList,
  blogsGalleryTotalNum
) => {
  return {
    type: REQUEST_BLOG_GALLERY_SUCCESS,
    blogGalleryList: blogGalleryList,
    blogsGalleryTotalNum: blogsGalleryTotalNum,
  };
};
export const requestGetBLogsGalleryListFailed = (message) => {
  return {
    type: REQUEST_BLOG_GALLERY_FAILED,
  };
};

// Actions for BLog Gallery Item
export const requestGetBLogsGalleryItem = () => {
  return {
    type: REQUEST_BLOG_GALLERY_ITEM,
  };
};
export const requestGetBLogsItemGallerySuccess = (blogGalleryItem) => {
  return {
    type: REQUEST_BLOG_GALLERY_ITEM_SUCCESS,
    blogGalleryItem: blogGalleryItem,
  };
};
export const requestGetBLogsItemGalleryFailed = () => {
  return {
    type: REQUEST_BLOG_GALLERY_ITEM_FAILED,
  };
};

// Actions For Add BLog Gallery
export const requestAddBLogGallery = () => {
  return {
    type: REQUEST_ADD_BLOG_GALLERY,
  };
};
export const requestAddBLogGallerySuccess = (message) => {
  return {
    type: REQUEST_ADD_BLOG_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestAddBLogGalleryFaield = (message) => {
  return {
    type: REQUEST_ADD_BLOG_GALLERY_FAILED,
    message: message,
  };
};

// Actions For Edit BLog Gallery
export const requestEditBLogGallery = () => {
  return {
    type: REQUEST_EDIT_BLOG_GALLERY,
  };
};
export const requestEditBLogGallerySuccess = (message) => {
  return {
    type: REQUEST_EDIT_BLOG_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestEditBLogGalleryFaield = (message) => {
  return {
    type: REQUEST_EDIT_BLOG_GALLERY_FAILED,
    message: message,
  };
};
