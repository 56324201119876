// ** Actions Types for Job Offers
const REQUEST_JOB_OFFERS = "REQUEST_JOB_OFFERS";
const REQUEST_JOB_OFFERS_SUCCESS = "REQUEST_JOB_OFFERS_SUCCESS";
const REQUEST_JOB_OFFERS_FAILED = "REQUEST_JOB_OFFERS_FAILED";
// ** Actions Types for Job Offer Item
const REQUEST_JOB_OFFER_ITEM = "REQUEST_JOB_OFFER_ITEM";
const REQUEST_JOB_OFFER_ITEM_SUCCESS = "REQUEST_JOB_OFFER_ITEM_SUCCESS";
const REQUEST_JOB_OFFER_ITEM_FAILED = "REQUEST_JOB_OFFER_ITEM_FAILED";
//** Actions Types For Add Job Offer
const REQUEST_ADD_JOB_OFFER = "REQUEST_ADD_JOB_OFFER";
const REQUEST_ADD_JOB_OFFER_SUCCESS = "REQUEST_ADD_JOB_OFFER_SUCCESS";
const REQUEST_ADD_JOB_OFFER_FAILED = "REQUEST_ADD_JOB_OFFER_FAILED";
//** Actions Types For Edit Job Offer
const REQUEST_EDIT_JOB_OFFER = "REQUEST_EDIT_JOB_OFFER";
const REQUEST_EDIT_JOB_OFFER_SUCCESS = "REQUEST_EDIT_JOB_OFFER_SUCCESS";
const REQUEST_EDIT_JOB_OFFER_FAILED = "REQUEST_EDIT_JOB_OFFER_FAILED";

// ** Initial State
const initialState = {
  jobOffersList: [],
  jobsTotalNum: "",
  isLoadingGetJobOffersList: false,
  isFailedGetJobOffersList: false,
  // ** State For Job Offer Item
  JobOfferItem: {},
  isLoadingJobOfferItem: false,
  isfailedJobOfferItem: false,
  // ** Add Job Offer
  isloadingAddJobOffer: false,
  successAddJobOffer: false,
  isfailedAddJobOffer: false,
  //** Edit Job Offer
  isloadingEditJobOffer: false,
  successEditJobOffer: false,
  isfailedEditJobOffer: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Job Offers List
    case REQUEST_JOB_OFFERS:
      return {
        ...state,
        isLoadingGetJobOffersList: true,
      };
    case REQUEST_JOB_OFFERS_SUCCESS:
      return {
        ...state,
        jobOffersList: action.jobOffersList,
        jobsTotalNum: action.jobsTotalNum,
        isLoadingGetJobOffersList: false,
        isFailedGetJobOffersList: false,
      };
    case REQUEST_JOB_OFFERS_FAILED:
      return {
        ...state,
        isLoadingGetJobOffersList: false,
        isFailedGetJobOffersList: true,
      };
    // ** Reducers for Job Offer Item
    case REQUEST_JOB_OFFER_ITEM:
      return {
        ...state,
        isLoadingJobOfferItem: true,
      };
    case REQUEST_JOB_OFFER_ITEM_SUCCESS:
      return {
        ...state,
        JobOfferItem: action.JobOfferItem,
        isLoadingJobOfferItem: false,
        isfailedJobOfferItem: false,
      };
    case REQUEST_JOB_OFFER_ITEM_FAILED:
      return {
        ...state,
        isLoadingJobOfferItem: false,
        isfailedJobOfferItem: true,
      };
    // ** Reducers for Add Job Offer
    case REQUEST_ADD_JOB_OFFER:
      return {
        ...state,
        isloadingAddJobOffer: true,
      };
    case REQUEST_ADD_JOB_OFFER_SUCCESS:
      return {
        ...state,
        isloadingAddJobOffer: false,
        successAddJobOffer: true,
        isfailedAddJobOffer: false,
      };
    case REQUEST_ADD_JOB_OFFER_FAILED:
      return {
        ...state,
        isloadingAddJobOffer: false,
        successAddJobOffer: false,
        isfailedAddJobOffer: true,
      };
    //**  Reducers for Edit Job Offer
    case REQUEST_EDIT_JOB_OFFER:
      return {
        ...state,
        isloadingEditJobOffer: true,
      };
    case REQUEST_EDIT_JOB_OFFER_SUCCESS:
      return {
        ...state,
        isloadingEditJobOffer: false,
        successEditJobOffer: true,
        isfailedEditJobOffer: false,
      };
    case REQUEST_EDIT_JOB_OFFER_FAILED:
      return {
        ...state,
        isloadingEditJobOffer: false,
        successEditJobOffer: false,
        isfailedEditJobOffer: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Job Offers  List
export const requestGetJobOffersList = () => {
  return {
    type: REQUEST_JOB_OFFERS,
  };
};
export const requestGetJobOffersListSuccess = (jobOffersList, jobsTotalNum) => {
  return {
    type: REQUEST_JOB_OFFERS_SUCCESS,
    jobOffersList: jobOffersList,
    jobsTotalNum: jobsTotalNum,
  };
};
export const requestGetJobOffersListFailed = (message) => {
  return {
    type: REQUEST_JOB_OFFERS_FAILED,
  };
};

// ** Actions for Job Offer Item
export const requestGetJobOfferItem = () => {
  return {
    type: REQUEST_JOB_OFFER_ITEM,
  };
};
export const requestGetJobOfferItemSuccess = (JobOfferItem) => {
  return {
    type: REQUEST_JOB_OFFER_ITEM_SUCCESS,
    JobOfferItem: JobOfferItem,
  };
};
export const requestGetJobOfferItemFailed = () => {
  return {
    type: REQUEST_JOB_OFFER_ITEM_FAILED,
  };
};
//** Actions For Add Job Offer
export const requestAddJobOffer = () => {
  return {
    type: REQUEST_ADD_JOB_OFFER,
  };
};
export const requestAddJobOfferSuccess = (message) => {
  return {
    type: REQUEST_ADD_JOB_OFFER_SUCCESS,
    message: message,
  };
};
export const requestAddJobOfferFaield = (message) => {
  return {
    type: REQUEST_ADD_JOB_OFFER_FAILED,
    message: message,
  };
};

//** Actions For Edit Job Offer
export const requestEditJobOffer = () => {
  return {
    type: REQUEST_EDIT_JOB_OFFER,
  };
};
export const requestEditJobOfferSuccess = (message) => {
  return {
    type: REQUEST_EDIT_JOB_OFFER_SUCCESS,
    message: message,
  };
};
export const requestEditJobOfferFaield = (message) => {
  return {
    type: REQUEST_EDIT_JOB_OFFER_FAILED,
    message: message,
  };
};
