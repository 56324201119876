// ** Actions Types for Technologies
const REQUEST_TECHNOLOGIES = "REQUEST_TECHNOLOGIES";
const REQUEST_TECHNOLOGIES_SUCCESS = "REQUEST_TECHNOLOGIES_SUCCESS";
const REQUEST_TECHNOLOGIES_FAILED = "REQUEST_TECHNOLOGIES_FAILED";
// ** Actions Types for Technology Item
const REQUEST_TECHNOLOGY_ITEM = "REQUEST_TECHNOLOGY_ITEM";
const REQUEST_TECHNOLOGY_ITEM_SUCCESS = "REQUEST_TECHNOLOGY_ITEM_SUCCESS";
const REQUEST_TECHNOLOGY_ITEM_FAILED = "REQUEST_TECHNOLOGY_ITEM_FAILED";
//** Actions Types For Add Technology
const REQUEST_ADD_TECHNOLOGY = "REQUEST_ADD_TECHNOLOGY";
const REQUEST_ADD_TECHNOLOGY_SUCCESS = "REQUEST_ADD_TECHNOLOGY_SUCCESS";
const REQUEST_ADD_TECHNOLOGY_FAILED = "REQUEST_ADD_TECHNOLOGY_FAILED";
//** Actions Types For Edit Technology
const REQUEST_EDIT_TECHNOLOGY = "REQUEST_EDIT_TECHNOLOGY";
const REQUEST_EDIT_TECHNOLOGY_SUCCESS = "REQUEST_EDIT_TECHNOLOGY_SUCCESS";
const REQUEST_EDIT_TECHNOLOGY_FAILED = "REQUEST_EDIT_TECHNOLOGY_FAILED";

// ** Initial State
const initialState = {
  technologiesList: [],
  technologiesTotalNum: "",
  isLoadingGetTechnologiesList: false,
  isFailedGetTechnologiesList: false,
  // ** State For Technology Item
  technologylItem: {},
  isLoadingTechnologyItem: false,
  isFailedTechnologyItem: false,
  // ** Add Technology
  isLoadingAddTechnology: false,
  successAddTechnology: false,
  isFailedAddTechnology: false,
  //** Edit Technology
  isLoadingEditTechnology: false,
  successEditTechnology: false,
  isFailedEditTechnology: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Technologies List
    case REQUEST_TECHNOLOGIES:
      return {
        ...state,
        isLoadingGetTechnologiesList: true,
      };
    case REQUEST_TECHNOLOGIES_SUCCESS:
      return {
        ...state,
        technologiesList: action.technologiesList,
        technologiesTotalNum: action.technologiesTotalNum,
        isLoadingGetTechnologiesList: false,
        isFailedGetTechnologiesList: false,
      };
    case REQUEST_TECHNOLOGIES_FAILED:
      return {
        ...state,
        isLoadingGetTechnologiesList: false,
        isFailedGetTechnologiesList: true,
      };
    // ** Reducers for Technology Item
    case REQUEST_TECHNOLOGY_ITEM:
      return {
        ...state,
        isLoadingTestimonialItem: true,
      };
    case REQUEST_TECHNOLOGY_ITEM_SUCCESS:
      return {
        ...state,
        technologylItem: action.technologylItem,
        isLoadingTechnologyItem: false,
        isFailedTechnologyItem: false,
      };
    case REQUEST_TECHNOLOGY_ITEM_FAILED:
      return {
        ...state,
        isLoadingTechnologyItem: false,
        isFailedTechnologyItem: true,
      };
    // ** Reducers for Add Technology
    case REQUEST_ADD_TECHNOLOGY:
      return {
        ...state,
        isLoadingAddTechnology: true,
      };
    case REQUEST_ADD_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        isLoadingAddTechnology: false,
        successAddTechnology: true,
        isFailedAddTechnology: false,
      };
    case REQUEST_ADD_TECHNOLOGY_FAILED:
      return {
        ...state,
        isLoadingAddTechnology: false,
        successAddTechnology: false,
        isFailedAddTechnology: true,
      };
    //**  Reducers for Edit Technology
    case REQUEST_EDIT_TECHNOLOGY:
      return {
        ...state,
        isLoadingEditTechnology: true,
      };
    case REQUEST_EDIT_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        isLoadingEditTechnology: false,
        successEditTechnology: true,
        isFailedEditTechnology: false,
      };
    case REQUEST_EDIT_TECHNOLOGY_FAILED:
      return {
        ...state,
        isLoadingEditTechnology: false,
        successEditTechnology: false,
        isFailedEditTechnology: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Technologies
export const requestGetTechnologiesList = () => {
  return {
    type: REQUEST_TECHNOLOGIES,
  };
};
export const requestGetTechnologiesListSuccess = (
  technologiesList,
  technologiesTotalNum
) => {
  return {
    type: REQUEST_TECHNOLOGIES_SUCCESS,
    technologiesList: technologiesList,
    technologiesTotalNum: technologiesTotalNum,
  };
};
export const requestGetTechnologiesListFailed = (message) => {
  return {
    type: REQUEST_TECHNOLOGIES_FAILED,
  };
};

// ** Actions for Technology Item
export const requestGetTechnologyItem = () => {
  return {
    type: REQUEST_TECHNOLOGY_ITEM,
  };
};
export const requestGetTechnologyItemSuccess = (technologylItem) => {
  return {
    type: REQUEST_TECHNOLOGY_ITEM_SUCCESS,
    technologylItem: technologylItem,
  };
};
export const requestGetTechnologyItemFailed = () => {
  return {
    type: REQUEST_TECHNOLOGY_ITEM_FAILED,
  };
};

//** Actions For Add Technology
export const requestAddTechnology = () => {
  return {
    type: REQUEST_ADD_TECHNOLOGY,
  };
};
export const requestAddTechnologySuccess = (message) => {
  return {
    type: REQUEST_ADD_TECHNOLOGY_SUCCESS,
    message: message,
  };
};
export const requestAddTechnologyFaield = (message) => {
  return {
    type: REQUEST_ADD_TECHNOLOGY_FAILED,
    message: message,
  };
};

//** Actions For Edit Technology
export const requestEditTechnology = () => {
  return {
    type: REQUEST_EDIT_TECHNOLOGY,
  };
};
export const requestEditTechnologySuccess = (message) => {
  return {
    type: REQUEST_EDIT_TECHNOLOGY_SUCCESS,
    message: message,
  };
};
export const requestEditTechnologyFaield = (message) => {
  return {
    type: REQUEST_EDIT_TECHNOLOGY_FAILED,
    message: message,
  };
};
