// ** Actions Types for Events
const REQUEST_EVENTS = "REQUEST_EVENTS";
const REQUEST_EVENTS_SUCCESS = "REQUEST_EVENTS_SUCCESS";
const REQUEST_EVENTS_FAILED = "REQUEST_EVENTS_FAILED";
// ** Actions Types for Event Item
const REQUEST_EVENT_ITEM = "REQUEST_EVENT_ITEM";
const REQUEST_EVENT_ITEM_SUCCESS = "REQUEST_EVENT_ITEM_SUCCESS";
const REQUEST_EVENT_ITEM_FAILED = "REQUEST_EVENT_ITEM_FAILED";
//** Actions Types For Add Event
const REQUEST_ADD_EVENT = "REQUEST_ADD_EVENT";
const REQUEST_ADD_EVENT_SUCCESS = "REQUEST_ADD_EVENT_SUCCESS";
const REQUEST_ADD_EVENT_FAILED = "REQUEST_ADD_EVENT_FAILED";
//** Actions Types For Edit Event
const REQUEST_EDIT_EVENT = "REQUEST_EDIT_EVENT";
const REQUEST_EDIT_EVENT_SUCCESS = "REQUEST_EDIT_EVENT_SUCCESS";
const REQUEST_EDIT_EVENT_FAILED = "REQUEST_EDIT_EVENT_FAILED";

// ** Initial State
const initialState = {
  eventsList: [],
  eventsTotalNum: "",
  isLoadingGetEventsList: false,
  isFailedGetEventsList: false,
  // ** State For Event Item
  eventItem: {},
  isLoadingEventItem: false,
  isFailedEventItem: false,
  // ** Add Event
  isLoadingAddEvent: false,
  successAddEvent: false,
  isFailedAddEvent: false,
  //** Edit Event
  isLoadingEditEvent: false,
  successEditEvent: false,
  isFailedEditEvent: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Events List
    case REQUEST_EVENTS:
      return {
        ...state,
        isLoadingGetEventsList: true,
      };
    case REQUEST_EVENTS_SUCCESS:
      return {
        ...state,
        eventsList: action.eventsList,
        eventsTotalNum: action.eventsTotalNum,
        isLoadingGetEventsList: false,
        isFailedGetEventsList: false,
      };
    case REQUEST_EVENTS_FAILED:
      return {
        ...state,
        isLoadingGetEventsList: false,
        isFailedGetEventsList: true,
      };
    // ** Reducers for Event Item
    case REQUEST_EVENT_ITEM:
      return {
        ...state,
        isLoadingEventItem: true,
      };
    case REQUEST_EVENT_ITEM_SUCCESS:
      return {
        ...state,
        eventItem: action.eventItem,
        isLoadingEventItem: false,
        isFailedEventItem: false,
      };
    case REQUEST_EVENT_ITEM_FAILED:
      return {
        ...state,
        isLoadingEventItem: false,
        isFailedEventItem: true,
      };
    // ** Reducers for Add Event
    case REQUEST_ADD_EVENT:
      return {
        ...state,
        isLoadingAddEvent: true,
      };
    case REQUEST_ADD_EVENT_SUCCESS:
      return {
        ...state,
        isLoadingAddEvent: false,
        successAddEvent: true,
        isFailedAddEvent: false,
      };
    case REQUEST_ADD_EVENT_FAILED:
      return {
        ...state,
        isLoadingAddEvent: false,
        successAddEvent: false,
        isFailedAddEvent: true,
      };
    //**  Reducers for Edit Event
    case REQUEST_EDIT_EVENT:
      return {
        ...state,
        isLoadingEditEvent: true,
      };
    case REQUEST_EDIT_EVENT_SUCCESS:
      return {
        ...state,
        isLoadingEditEvent: false,
        successEditEvent: true,
        isFailedEditEvent: false,
      };
    case REQUEST_EDIT_EVENT_FAILED:
      return {
        ...state,
        isLoadingEditEvent: false,
        successEditEvent: false,
        isFailedEditEvent: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Events  List
export const requestGetEventsList = () => {
  return {
    type: REQUEST_EVENTS,
  };
};
export const requestGetEventsListSuccess = (eventsList, eventsTotalNum) => {
  return {
    type: REQUEST_EVENTS_SUCCESS,
    eventsList: eventsList,
    eventsTotalNum: eventsTotalNum,
  };
};
export const requestGetEventsListFailed = (message) => {
  return {
    type: REQUEST_EVENTS_FAILED,
  };
};

// ** Actions for Event Item
export const requestGetEventItem = () => {
  return {
    type: REQUEST_EVENT_ITEM,
  };
};
export const requestGetEventItemSuccess = (eventItem) => {
  return {
    type: REQUEST_EVENT_ITEM_SUCCESS,
    eventItem: eventItem,
  };
};
export const requestGetEventItemFailed = () => {
  return {
    type: REQUEST_EVENT_ITEM_FAILED,
  };
};

//** Actions For Add Event
export const requestAddEvent = () => {
  return {
    type: REQUEST_ADD_EVENT,
  };
};
export const requestAddEventSuccess = (message) => {
  return {
    type: REQUEST_ADD_EVENT_SUCCESS,
    message: message,
  };
};
export const requestAddEventFaield = (message) => {
  return {
    type: REQUEST_ADD_EVENT_FAILED,
    message: message,
  };
};

//** Actions For Edit Event
export const requestEditEvent = () => {
  return {
    type: REQUEST_EDIT_EVENT,
  };
};
export const requestEditEventSuccess = (message) => {
  return {
    type: REQUEST_EDIT_EVENT_SUCCESS,
    message: message,
  };
};
export const requestEditEventFaield = (message) => {
  return {
    type: REQUEST_EDIT_EVENT_FAILED,
    message: message,
  };
};
