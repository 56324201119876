// ** Actions Types for Events Programme
const REQUEST_EVENTS_PROGRAMME = "REQUEST_EVENTS_PROGRAMME";
const REQUEST_EVENTS_PROGRAMME_SUCCESS = "REQUEST_EVENTS_PROGRAMME_SUCCESS";
const REQUEST_EVENTS_PROGRAMME_FAILED = "REQUEST_EVENTS_PROGRAMME_FAILED";
// ** Actions Types for Event Programme Item
const REQUEST_EVENT_PROGRAMME_ITEM = "REQUEST_EVENT_PROGRAMME_ITEM";
const REQUEST_EVENT_PROGRAMME_ITEM_SUCCESS =
  "REQUEST_EVENT_PROGRAMME_ITEM_SUCCESS";
const REQUEST_EVENT_PROGRAMME_ITEM_FAILED =
  "REQUEST_EVENT_PROGRAMME_ITEM_FAILED";
//** Actions Types For Add Event Programme
const REQUEST_ADD_EVENT_PROGRAMME = "REQUEST_ADD_EVENT_PROGRAMME";
const REQUEST_ADD_EVENT_PROGRAMME_SUCCESS =
  "REQUEST_ADD_EVENT_PROGRAMME_SUCCESS";
const REQUEST_ADD_EVENT_PROGRAMME_FAILED = "REQUEST_ADD_EVENT_PROGRAMME_FAILED";
//** Actions Types For Edit Event Programme
const REQUEST_EDIT_EVENT_PROGRAMME = "REQUEST_EDIT_EVENT_PROGRAMME";
const REQUEST_EDIT_EVENT_PROGRAMME_SUCCESS =
  "REQUEST_EDIT_EVENT_PROGRAMME_SUCCESS";
const REQUEST_EDIT_EVENT_PROGRAMME_FAILED =
  "REQUEST_EDIT_EVENT_PROGRAMME_FAILED";

// ** Initial State
const initialState = {
  eventsProgrammeList: [],
  eventsProgrammeTotalNum: "",
  isLoadingGetEventsProgrammeList: false,
  isFailedGetEventsProgrammeList: false,
  // ** State For Event Programme Item
  eventProgrammeItem: {},
  isLoadingEventProgrammeItem: false,
  isFailedEventProgrammeItem: false,
  // ** Add Event Programme
  isLoadingAddEventProgramme: false,
  successAddEventProgramme: false,
  isFailedAddEventProgramme: false,
  //** Edit Event Programme
  isLoadingEditEventProgramme: false,
  successEditEventProgramme: false,
  isFailedEditEventProgramme: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Events Programme List
    case REQUEST_EVENTS_PROGRAMME:
      return {
        ...state,
        isLoadingGetEventsProgrammeList: true,
      };
    case REQUEST_EVENTS_PROGRAMME_SUCCESS:
      return {
        ...state,
        eventsProgrammeList: action.eventsProgrammeList,
        eventsProgrammeTotalNum: action.eventsProgrammeTotalNum,
        isLoadingGetEventsProgrammeList: false,
        isFailedGetEventsProgrammeList: false,
      };
    case REQUEST_EVENTS_PROGRAMME_FAILED:
      return {
        ...state,
        isLoadingGetEventsProgrammeList: false,
        isFailedGetEventsProgrammeList: true,
      };
    // ** Reducers for Event Programme Item
    case REQUEST_EVENT_PROGRAMME_ITEM:
      return {
        ...state,
        isLoadingEventProgrammeItem: true,
      };
    case REQUEST_EVENT_PROGRAMME_ITEM_SUCCESS:
      return {
        ...state,
        eventProgrammeItem: action.eventProgrammeItem,
        isLoadingEventProgrammeItem: false,
        isFailedEventProgrammeItem: false,
      };
    case REQUEST_EVENT_PROGRAMME_ITEM_FAILED:
      return {
        ...state,
        isLoadingEventProgrammeItem: false,
        isFailedEventProgrammeItem: true,
      };
    // ** Reducers for Add Event Programme
    case REQUEST_ADD_EVENT_PROGRAMME:
      return {
        ...state,
        isLoadingAddEventProgramme: true,
      };
    case REQUEST_ADD_EVENT_PROGRAMME_SUCCESS:
      return {
        ...state,
        isLoadingAddEventProgramme: false,
        successAddEventProgramme: true,
        isFailedAddEventProgramme: false,
      };
    case REQUEST_ADD_EVENT_PROGRAMME_FAILED:
      return {
        ...state,
        isLoadingAddEventProgramme: false,
        successAddEventProgramme: false,
        isFailedAddEventProgramme: true,
      };
    //**  Reducers for Edit Event Programme
    case REQUEST_EDIT_EVENT_PROGRAMME:
      return {
        ...state,
        isLoadingEditEventProgramme: true,
      };
    case REQUEST_EDIT_EVENT_PROGRAMME_SUCCESS:
      return {
        ...state,
        isLoadingEditEventProgramme: false,
        successEditEventProgramme: true,
        isFailedEditEventProgramme: false,
      };
    case REQUEST_EDIT_EVENT_PROGRAMME_FAILED:
      return {
        ...state,
        isLoadingEditEventProgramme: false,
        successEditEventProgramme: false,
        isFailedEditEventProgramme: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Events Programme List
export const requestGetEventsProgrammeList = () => {
  return {
    type: REQUEST_EVENTS_PROGRAMME,
  };
};
export const requestGetEventsProgrammeListSuccess = (
  eventsProgrammeList,
  eventsProgrammeTotalNum
) => {
  return {
    type: REQUEST_EVENTS_PROGRAMME_SUCCESS,
    eventsProgrammeList: eventsProgrammeList,
    eventsProgrammeTotalNum: eventsProgrammeTotalNum,
  };
};
export const requestGetEventsProgrammeListFailed = (message) => {
  return {
    type: REQUEST_EVENTS_PROGRAMME_FAILED,
  };
};

// ** Actions for Event Programme Item
export const requestGetEventProgrammeItem = () => {
  return {
    type: REQUEST_EVENT_PROGRAMME_ITEM,
  };
};
export const requestGetEventProgrammeItemSuccess = (eventProgrammeItem) => {
  return {
    type: REQUEST_EVENT_PROGRAMME_ITEM_SUCCESS,
    eventProgrammeItem: eventProgrammeItem,
  };
};
export const requestGetEventProgrammeItemFailed = () => {
  return {
    type: REQUEST_EVENT_PROGRAMME_ITEM_FAILED,
  };
};

//** Actions For Add Event Programme
export const requestAddEventProgramme = () => {
  return {
    type: REQUEST_ADD_EVENT_PROGRAMME,
  };
};
export const requestAddEventProgrammeSuccess = (message) => {
  return {
    type: REQUEST_ADD_EVENT_PROGRAMME_SUCCESS,
    message: message,
  };
};
export const requestAddEventProgrammeFaield = (message) => {
  return {
    type: REQUEST_ADD_EVENT_PROGRAMME_FAILED,
    message: message,
  };
};

//** Actions For Edit Event Programme
export const requestEditEventProgramme = () => {
  return {
    type: REQUEST_EDIT_EVENT_PROGRAMME,
  };
};
export const requestEditEventProgrammeSuccess = (message) => {
  return {
    type: REQUEST_EDIT_EVENT_PROGRAMME_SUCCESS,
    message: message,
  };
};
export const requestEditEventProgrammeFaield = (message) => {
  return {
    type: REQUEST_EDIT_EVENT_PROGRAMME_FAILED,
    message: message,
  };
};
