// ** Actions Types for Events Programme Gallery
const REQUEST_EVENTS_PROGRAMME_GALLERY = "REQUEST_EVENTS_PROGRAMME_GALLERY";
const REQUEST_EVENTS_PROGRAMME_GALLERY_SUCCESS =
  "REQUEST_EVENTS_PROGRAMME_GALLERY_SUCCESS";
const REQUEST_EVENTS_PROGRAMME_GALLERY_FAILED =
  "REQUEST_EVENTS_PROGRAMME_GALLERY_FAILED";
// ** Actions Types for Event Programme Gallery Item
const REQUEST_EVENT_PROGRAMME_GALLERY_ITEM =
  "REQUEST_EVENT_PROGRAMME_GALLERY_ITEM";
const REQUEST_EVENT_PROGRAMME_GALLERY_ITEM_SUCCESS =
  "REQUEST_EVENT_PROGRAMME_GALLERY_ITEM_SUCCESS";
const REQUEST_EVENT_PROGRAMME_GALLERY_ITEM_FAILED =
  "REQUEST_EVENT_PROGRAMME_GALLERY_ITEM_FAILED";
//** Actions Types For Add Event Programme Gallery
const REQUEST_ADD_EVENT_PROGRAMME_GALLERY =
  "REQUEST_ADD_EVENT_PROGRAMME_GALLERY";
const REQUEST_ADD_EVENT_PROGRAMME_GALLERY_SUCCESS =
  "REQUEST_ADD_EVENT_PROGRAMME_GALLERY_SUCCESS";
const REQUEST_ADD_EVENT_PROGRAMME_GALLERY_FAILED =
  "REQUEST_ADD_EVENT_PROGRAMME_GALLERY_FAILED";
//** Actions Types For Edit Event Programme Gallery
const REQUEST_EDIT_EVENT_PROGRAMME_GALLERY =
  "REQUEST_EDIT_EVENT_PROGRAMME_GALLERY";
const REQUEST_EDIT_EVENT_PROGRAMME_GALLERY_SUCCESS =
  "REQUEST_EDIT_EVENT_PROGRAMME_GALLERY_SUCCESS";
const REQUEST_EDIT_EVENT_PROGRAMME_GALLERY_FAILED =
  "REQUEST_EDIT_EVENT_PROGRAMME_GALLERY_FAILED";

// ** Initial State
const initialState = {
  eventsProgrammeGalleryList: [],
  eventsProgrammeGalleryTotalNum: "",
  isLoadingGetEventsProgrammeGalleryList: false,
  isFailedGetEventsProgrammeGalleryList: false,
  // ** State For Event Programme Gallery Item
  eventProgrammeGalleryItem: {},
  isLoadingEventProgrammeGalleryItem: false,
  isFailedEventProgrammeGalleryItem: false,
  // ** Add Event Programme Gallery
  isLoadingAddEventProgrammeGallery: false,
  successAddEventProgrammeGallery: false,
  isFailedAddEventProgrammeGallery: false,
  //** Edit Event Programme Gallery
  isLoadingEditEventProgrammeGallery: false,
  successEditEventProgrammeGallery: false,
  isFailedEditEventProgrammeGallery: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Events Programme Gallery List
    case REQUEST_EVENTS_PROGRAMME_GALLERY:
      return {
        ...state,
        isLoadingGetEventsProgrammeGalleryList: true,
      };
    case REQUEST_EVENTS_PROGRAMME_GALLERY_SUCCESS:
      return {
        ...state,
        eventsProgrammeGalleryList: action.eventsProgrammeGalleryList,
        eventsProgrammeGalleryTotalNum: action.eventsProgrammeGalleryTotalNum,
        isLoadingGetEventsProgrammeGalleryList: false,
        isFailedGetEventsProgrammeGalleryList: false,
      };
    case REQUEST_EVENTS_PROGRAMME_GALLERY_FAILED:
      return {
        ...state,
        isLoadingGetEventsProgrammeGalleryList: false,
        isFailedGetEventsProgrammeGalleryList: true,
      };
    // ** Reducers for Event Programme Gallery Item
    case REQUEST_EVENT_PROGRAMME_GALLERY_ITEM:
      return {
        ...state,
        isLoadingEventProgrammeGalleryItem: true,
      };
    case REQUEST_EVENT_PROGRAMME_GALLERY_ITEM_SUCCESS:
      return {
        ...state,
        eventProgrammeGalleryItem: action.eventProgrammeGalleryItem,
        isLoadingEventProgrammeGalleryItem: false,
        isFailedEventProgrammeGalleryItem: false,
      };
    case REQUEST_EVENT_PROGRAMME_GALLERY_ITEM_FAILED:
      return {
        ...state,
        isLoadingEventProgrammeGalleryItem: false,
        isFailedEventProgrammeGalleryItem: true,
      };
    // ** Reducers for Add Event Programme Gallery
    case REQUEST_ADD_EVENT_PROGRAMME_GALLERY:
      return {
        ...state,
        isLoadingAddEventProgrammeGallery: true,
      };
    case REQUEST_ADD_EVENT_PROGRAMME_GALLERY_SUCCESS:
      return {
        ...state,
        isLoadingAddEventProgrammeGallery: false,
        successAddEventProgrammeGallery: true,
        isFailedAddEventProgrammeGallery: false,
      };
    case REQUEST_ADD_EVENT_PROGRAMME_GALLERY_FAILED:
      return {
        ...state,
        isLoadingAddEventProgrammeGallery: false,
        successAddEventProgrammeGallery: false,
        isFailedAddEventProgrammeGallery: true,
      };
    //**  Reducers for Edit Event Programme Gallery
    case REQUEST_EDIT_EVENT_PROGRAMME_GALLERY:
      return {
        ...state,
        isLoadingEditEventProgrammeGallery: true,
      };
    case REQUEST_EDIT_EVENT_PROGRAMME_GALLERY_SUCCESS:
      return {
        ...state,
        isLoadingEditEventProgrammeGallery: false,
        successEditEventProgrammeGallery: true,
        isFailedEditEventProgrammeGallery: false,
      };
    case REQUEST_EDIT_EVENT_PROGRAMME_GALLERY_FAILED:
      return {
        ...state,
        isLoadingEditEventProgrammeGallery: false,
        successEditEventProgrammeGallery: false,
        isFailedEditEventProgrammeGallery: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Events Programme Gallery List
export const requestGetEventsProgrammeGalleryList = () => {
  return {
    type: REQUEST_EVENTS_PROGRAMME_GALLERY,
  };
};
export const requestGetEventsProgrammeGalleryListSuccess = (
  eventsProgrammeGalleryList,
  eventsProgrammeGalleryTotalNum
) => {
  return {
    type: REQUEST_EVENTS_PROGRAMME_GALLERY_SUCCESS,
    eventsProgrammeGalleryList: eventsProgrammeGalleryList,
    eventsProgrammeGalleryTotalNum: eventsProgrammeGalleryTotalNum,
  };
};
export const requestGetEventsProgrammeGalleryListFailed = (message) => {
  return {
    type: REQUEST_EVENTS_PROGRAMME_GALLERY_FAILED,
  };
};

// ** Actions for Event Programme Gallery Item
export const requestGetEventProgrammeGalleryItem = () => {
  return {
    type: REQUEST_EVENT_PROGRAMME_GALLERY_ITEM,
  };
};
export const requestGetEventProgrammeGalleryItemSuccess = (
  eventProgrammeGalleryItem
) => {
  return {
    type: REQUEST_EVENT_PROGRAMME_GALLERY_ITEM_SUCCESS,
    eventProgrammeGalleryItem: eventProgrammeGalleryItem,
  };
};
export const requestGetEventProgrammeGalleryItemFailed = () => {
  return {
    type: REQUEST_EVENT_PROGRAMME_GALLERY_ITEM_FAILED,
  };
};

//** Actions For Add Event Programme Gallery
export const requestAddEventProgrammeGallery = () => {
  return {
    type: REQUEST_ADD_EVENT_PROGRAMME_GALLERY,
  };
};
export const requestAddEventProgrammeGallerySuccess = (message) => {
  return {
    type: REQUEST_ADD_EVENT_PROGRAMME_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestAddEventProgrammeGalleryFaield = (message) => {
  return {
    type: REQUEST_ADD_EVENT_PROGRAMME_GALLERY_FAILED,
    message: message,
  };
};

//** Actions For Edit Event Programme Gallery
export const requestEditEventProgrammeGallery = () => {
  return {
    type: REQUEST_EDIT_EVENT_PROGRAMME_GALLERY,
  };
};
export const requestEditEventProgrammeGallerySuccess = (message) => {
  return {
    type: REQUEST_EDIT_EVENT_PROGRAMME_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestEditEventProgrammeGalleryFaield = (message) => {
  return {
    type: REQUEST_EDIT_EVENT_PROGRAMME_GALLERY_FAILED,
    message: message,
  };
};
