// Actions Types for BLogs
const REQUEST_BLOGS = "REQUEST_BLOGS";
const REQUEST_BLOGS_SUCCESS = "REQUEST_BLOGS_SUCCESS";
const REQUEST_BLOGS_FAILED = "REQUEST_BLOGS_FAILED";
// Actions Types for BLog Item
const REQUEST_BLOGS_ITEM = "REQUEST_BLOGS_ITEM";
const REQUEST_BLOGS_ITEM_SUCCESS = "REQUEST_BLOGS_ITEM_SUCCESS";
const REQUEST_BLOGS_ITEM_FAILED = "REQUEST_BLOGS_ITEM_FAILED";
// Actions Types For Add BLog
const REQUEST_ADD_BLOG = "REQUEST_ADD_BLOG";
const REQUEST_ADD_BLOG_SUCCESS = "REQUEST_ADD_BLOG_SUCCESS";
const REQUEST_ADD_BLOG_FAILED = "REQUEST_ADD_BLOG_FAILED";
// Actions Types For Edit BLog
const REQUEST_EDIT_BLOG = "REQUEST_EDIT_BLOG";
const REQUEST_EDIT_BLOG_SUCCESS = "REQUEST_EDIT_BLOG_SUCCESS";
const REQUEST_EDIT_BLOG_FAILED = "REQUEST_EDIT_BLOG_FAILED";

// Initial State
const initialState = {
  blogsList: [],
  blogsTotalNum: "",
  isLoadingGetBlogsList: false,
  isFailedGetBlogsList: false,
  // State For BLog Item
  blogItem: {},
  isLoadingBlogItem: false,
  isFailedBlogItem: false,
  // Add BLog
  isLoadingAddBLog: false,
  successAddBLog: false,
  isFailedAddBLog: false,
  // Edit BLog
  isLoadingEditBLog: false,
  successEditBLog: false,
  isFailedEditBLog: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // Reducers for BLogs List
    case REQUEST_BLOGS:
      return {
        ...state,
        isLoadingGetBlogsList: true,
      };
    case REQUEST_BLOGS_SUCCESS:
      return {
        ...state,
        blogsList: action.blogsList,
        blogsTotalNum: action.blogsTotalNum,
        isLoadingGetBlogsList: false,
        isFailedGetBlogsList: false,
      };
    case REQUEST_BLOGS_FAILED:
      return {
        ...state,
        isLoadingGetBlogsList: false,
        isFailedGetBlogsList: true,
      };
    // Reducers for BLog Item
    case REQUEST_BLOGS_ITEM:
      return {
        ...state,
        isLoadingBlogItem: true,
      };
    case REQUEST_BLOGS_ITEM_SUCCESS:
      return {
        ...state,
        blogItem: action.blogItem,
        isLoadingBlogItem: false,
        isFailedBlogItem: false,
      };
    case REQUEST_BLOGS_ITEM_FAILED:
      return {
        ...state,
        isLoadingBlogItem: false,
        isFailedBlogItem: true,
      };
    // Reducers for Add BLog
    case REQUEST_ADD_BLOG:
      return {
        ...state,
        isLoadingAddBLog: true,
      };
    case REQUEST_ADD_BLOG_SUCCESS:
      return {
        ...state,
        isLoadingAddBLog: false,
        successAddBLog: true,
        isFailedAddBLog: false,
      };
    case REQUEST_ADD_BLOG_FAILED:
      return {
        ...state,
        isLoadingAddBLog: false,
        successAddBLog: false,
        isFailedAddBLog: true,
      };
    // Reducers for Edit BLog
    case REQUEST_EDIT_BLOG:
      return {
        ...state,
        isLoadingEditBLog: true,
      };
    case REQUEST_EDIT_BLOG_SUCCESS:
      return {
        ...state,
        isLoadingEditBLog: false,
        successEditBLog: true,
        isFailedEditBLog: false,
      };
    case REQUEST_EDIT_BLOG_FAILED:
      return {
        ...state,
        isLoadingEditBLog: false,
        successEditBLog: false,
        isFailedEditBLog: true,
      };
    default:
      return state;
  }
};

// Actions for All BLogs List
export const requestGetBLogsList = () => {
  return {
    type: REQUEST_BLOGS,
  };
};
export const requestGetBLogsListSuccess = (blogsList, blogsTotalNum) => {
  return {
    type: REQUEST_BLOGS_SUCCESS,
    blogsList: blogsList,
    blogsTotalNum: blogsTotalNum,
  };
};
export const requestGetBLogsListFailed = (message) => {
  return {
    type: REQUEST_BLOGS_FAILED,
  };
};

// Actions for BLog Item
export const requestGetBlogsItem = () => {
  return {
    type: REQUEST_BLOGS_ITEM,
  };
};
export const requestGetBlogsItemSuccess = (blogItem) => {
  return {
    type: REQUEST_BLOGS_ITEM_SUCCESS,
    blogItem: blogItem,
  };
};
export const requestGetBlogsItemFailed = () => {
  return {
    type: REQUEST_BLOGS_ITEM_FAILED,
  };
};

// Actions For Add BLog
export const requestAddBLog = () => {
  return {
    type: REQUEST_ADD_BLOG,
  };
};
export const requestAddBLogSuccess = (message) => {
  return {
    type: REQUEST_ADD_BLOG_SUCCESS,
    message: message,
  };
};
export const requestAddBLogFaield = (message) => {
  return {
    type: REQUEST_ADD_BLOG_FAILED,
    message: message,
  };
};

// Actions For Edit BLog
export const requestEditBLog = () => {
  return {
    type: REQUEST_EDIT_BLOG,
  };
};
export const requestEditBLogSuccess = (message) => {
  return {
    type: REQUEST_EDIT_BLOG_SUCCESS,
    message: message,
  };
};
export const requestEditBLogFaield = (message) => {
  return {
    type: REQUEST_EDIT_BLOG_FAILED,
    message: message,
  };
};
