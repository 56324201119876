// Actions Types for Open Applications
const REQUEST_OPEN_APPLICATIONS = "REQUEST_OPEN_APPLICATIONS";
const REQUEST_OPEN_APPLICATIONS_SUCCESS = "REQUEST_OPEN_APPLICATIONS_SUCCESS";
const REQUEST_OPEN_APPLICATIONS_FAILED = "REQUEST_OPEN_APPLICATIONS_FAILED";
//  Actions Types for Open Applications Item
const REQUEST_OPEN_APPLICATIONS_ITEM = "REQUEST_OPEN_APPLICATIONS_ITEM";
const REQUEST_OPEN_APPLICATIONS_ITEM_SUCCESS =
  "REQUEST_OPEN_APPLICATIONS_ITEM_SUCCESS";
const REQUEST_OPEN_APPLICATIONS_ITEM_FAILED =
  "REQUEST_OPEN_APPLICATIONS_ITEM_FAILED";

// Initial State
const initialState = {
  openApplicationsList: [],
  openApplicationsTotalNum: "",
  isLoadingGetOpenApplicationsList: false,
  isFailedGetOpenApplicationsList: false,
  // State For Open Applications Item
  openApplicationsItem: {},
  isLoadingOpenApplicationsItem: false,
  isFailedOpenApplicationsItem: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // Reducers for Open Applications List
    case REQUEST_OPEN_APPLICATIONS:
      return {
        ...state,
        isLoadingGetOpenApplicationsList: true,
      };
    case REQUEST_OPEN_APPLICATIONS_SUCCESS:
      return {
        ...state,
        openApplicationsList: action.openApplicationsList,
        openApplicationsTotalNum: action.openApplicationsTotalNum,
        isLoadingGetOpenApplicationsList: false,
        isFailedGetOpenApplicationsList: false,
      };
    case REQUEST_OPEN_APPLICATIONS_FAILED:
      return {
        ...state,
        isLoadingGetOpenApplicationsList: false,
        isFailedGetOpenApplicationsList: true,
      };
    // Reducers for Open Applications Item
    case REQUEST_OPEN_APPLICATIONS_ITEM:
      return {
        ...state,
        isLoadingOpenApplicationsItem: true,
      };
    case REQUEST_OPEN_APPLICATIONS_ITEM_SUCCESS:
      return {
        ...state,
        openApplicationsItem: action.openApplicationsItem,
        isLoadingOpenApplicationsItem: false,
        isFailedOpenApplicationsItem: false,
      };
    case REQUEST_OPEN_APPLICATIONS_ITEM_FAILED:
      return {
        ...state,
        isLoadingOpenApplicationsItem: false,
        isFailedOpenApplicationsItem: true,
      };
    default:
      return state;
  }
};

// Actions for All Open Applications List
export const requestGetOpenApplicationsList = () => {
  return {
    type: REQUEST_OPEN_APPLICATIONS,
  };
};
export const requestGetOpenApplicationsListSuccess = (
  openApplicationsList,
  openApplicationsTotalNum
) => {
  return {
    type: REQUEST_OPEN_APPLICATIONS_SUCCESS,
    openApplicationsList: openApplicationsList,
    openApplicationsTotalNum: openApplicationsTotalNum,
  };
};
export const requestGetOpenApplicationsListFailed = (message) => {
  return {
    type: REQUEST_OPEN_APPLICATIONS_FAILED,
  };
};

// Actions for Open Applications Item
export const requestGetOpenApplicationsItem = () => {
  return {
    type: REQUEST_OPEN_APPLICATIONS_ITEM,
  };
};
export const requestGetOpenApplicationsItemSuccess = (openApplicationsItem) => {
  return {
    type: REQUEST_OPEN_APPLICATIONS_ITEM_SUCCESS,
    openApplicationsItem: openApplicationsItem,
  };
};
export const requestGetOpenApplicationsItemFailed = () => {
  return {
    type: REQUEST_OPEN_APPLICATIONS_ITEM_FAILED,
  };
};
