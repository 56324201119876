// Actions Types for Users Messages
const REQUEST_USERS_MESSAGES = "REQUEST_USERS_MESSAGES";
const REQUEST_USERS_MESSAGES_SUCCESS = "REQUEST_USERS_MESSAGES_SUCCESS";
const REQUEST_USERS_MESSAGES_FAILED = "REQUEST_USERS_MESSAGES_FAILED";
// Actions Types for User Messages Item
const REQUEST_USER_MESSAGES_ITEM = "REQUEST_USER_MESSAGES_ITEM";
const REQUEST_USER_MESSAGES_ITEM_SUCCESS = "REQUEST_USER_MESSAGES_ITEM_SUCCESS";
const REQUEST_USER_MESSAGES_ITEM_FAILED = "REQUEST_USER_MESSAGES_ITEM_FAILED";

// Initial State
const initialState = {
  usersMessagesList: [],
  usersMessagesTotalNum: "",
  isLoadingGetUsersMessagesList: false,
  isFailedGetUsersMessagesList: false,
  // State For User Messages Item
  userMessagesItem: {},
  isLoadingUserMessagesItem: false,
  isFailedUserMessagesItem: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // Reducers for Users Messages List
    case REQUEST_USERS_MESSAGES:
      return {
        ...state,
        isLoadingGetUsersMessagesList: true,
      };
    case REQUEST_USERS_MESSAGES_SUCCESS:
      return {
        ...state,
        usersMessagesList: action.usersMessagesList,
        usersMessagesTotalNum: action.usersMessagesTotalNum,
        isLoadingGetUsersMessagesList: false,
        isFailedGetUsersMessagesList: false,
      };
    case REQUEST_USERS_MESSAGES_FAILED:
      return {
        ...state,
        isLoadingGetUsersMessagesList: false,
        isFailedGetUsersMessagesList: true,
      };
    // Reducers for User Messages Item
    case REQUEST_USER_MESSAGES_ITEM:
      return {
        ...state,
        isLoadingUserMessagesItem: true,
      };
    case REQUEST_USER_MESSAGES_ITEM_SUCCESS:
      return {
        ...state,
        userMessagesItem: action.userMessagesItem,
        isLoadingUserMessagesItem: false,
        isFailedUserMessagesItem: false,
      };
    case REQUEST_USER_MESSAGES_ITEM_FAILED:
      return {
        ...state,
        isLoadingUserMessagesItem: false,
        isFailedUserMessagesItem: true,
      };
    default:
      return state;
  }
};

//  Actions for All Users Messages
export const requestGetUsersMessagesList = () => {
  return {
    type: REQUEST_USERS_MESSAGES,
  };
};
export const requestGetUsersMessagesListSuccess = (
  usersMessagesList,
  usersMessagesTotalNum
) => {
  return {
    type: REQUEST_USERS_MESSAGES_SUCCESS,
    usersMessagesList: usersMessagesList,
    usersMessagesTotalNum: usersMessagesTotalNum,
  };
};
export const requestGetUsersMessagesListFailed = (message) => {
  return {
    type: REQUEST_USERS_MESSAGES_FAILED,
  };
};

// Actions for User Messages Item
export const requestGetUserMessagesItem = () => {
  return {
    type: REQUEST_USER_MESSAGES_ITEM,
  };
};
export const requestGetUserMessagesItemSuccess = (userMessagesItem) => {
  return {
    type: REQUEST_USER_MESSAGES_ITEM_SUCCESS,
    userMessagesItem: userMessagesItem,
  };
};
export const requestGetUserMessagesItemFailed = () => {
  return {
    type: REQUEST_USER_MESSAGES_ITEM_FAILED,
  };
};
