// ** Actions Types for Product Gallery
const REQUEST_PRODUCT_GALLERY = "REQUEST_PRODUCT_GALLERY";
const REQUEST_PRODUCT_GALLERY_SUCCESS = "REQUEST_PRODUCT_GALLERY_SUCCESS";
const REQUEST_PRODUCT_GALLERY_FAILED = "REQUEST_PRODUCT_GALLERY_FAILED";
// ** Actions Types for Product Gallery Item
const REQUEST_PRODUCT_GALLERY_ITEM = "REQUEST_PRODUCT_GALLERY_ITEM";
const REQUEST_PRODUCT_GALLERY_ITEM_SUCCESS =
  "REQUEST_PRODUCT_GALLERY_ITEM_SUCCESS";
const REQUEST_PRODUCT_GALLERY_ITEM_FAILED =
  "REQUEST_PRODUCT_GALLERY_ITEM_FAILED";
//** Actions Types For Add Product Gallery
const REQUEST_ADD_PRODUCT_GALLERY = "REQUEST_ADD_PRODUCT_GALLERY";
const REQUEST_ADD_PRODUCT_GALLERY_SUCCESS =
  "REQUEST_ADD_PRODUCT_GALLERY_SUCCESS";
const REQUEST_ADD_PRODUCT_GALLERY_FAILED = "REQUEST_ADD_PRODUCT_GALLERY_FAILED";
//** Actions Types For Edit Product Gallery
const REQUEST_EDIT_PRODUCT_GALLERY = "REQUEST_EDIT_PRODUCT_GALLERY";
const REQUEST_EDIT_PRODUCT_GALLERY_SUCCESS =
  "REQUEST_EDIT_PRODUCT_GALLERY_SUCCESS";
const REQUEST_EDIT_PRODUCT_GALLERY_FAILED =
  "REQUEST_EDIT_PRODUCT_GALLERY_FAILED";

// ** Initial State
const initialState = {
  productsGalleryList: [],
  productsGalleryPagesCount: "",
  isLoadingGetProductsGalleryList: false,
  isFailedGetProductsGalleryList: false,
  // ** State For Product Gallery Item
  productGalleryItem: {},
  isLoadingProductGalleryItem: false,
  isfailedProductGalleryItem: false,
  // ** Add Product Gallery
  isloadingAddProductGallery: false,
  successAddProductGallery: false,
  isfailedAddProductGallery: false,
  //** Edit Product Gallery
  isloadingEditProductGallery: false,
  successEditProductGallery: false,
  isfailedEditProductGallery: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Products Gallery List
    case REQUEST_PRODUCT_GALLERY:
      return {
        ...state,
        isLoadingGetProductsGalleryList: true,
      };
    case REQUEST_PRODUCT_GALLERY_SUCCESS:
      return {
        ...state,
        productsGalleryList: action.productsGalleryList,
        productsGalleryPagesCount: action.productsGalleryPagesCount,
        isLoadingGetProductsGalleryList: false,
        isFailedGetProductsGalleryList: false,
      };
    case REQUEST_PRODUCT_GALLERY_FAILED:
      return {
        ...state,
        isLoadingGetProductsGalleryList: false,
        isFailedGetProductsGalleryList: true,
      };
    // ** Reducers for Product Gallery Item
    case REQUEST_PRODUCT_GALLERY_ITEM:
      return {
        ...state,
        isLoadingProductGalleryItem: true,
      };
    case REQUEST_PRODUCT_GALLERY_ITEM_SUCCESS:
      return {
        ...state,
        productGalleryItem: action.productGalleryItem,
        isLoadingProductGalleryItem: false,
        isfailedProductGalleryItem: false,
      };
    case REQUEST_PRODUCT_GALLERY_ITEM_FAILED:
      return {
        ...state,
        isLoadingProductGalleryItem: false,
        isfailedProductGalleryItem: true,
      };
    // ** Reducers for Add Product Gallery
    case REQUEST_ADD_PRODUCT_GALLERY:
      return {
        ...state,
        isloadingAddProductGallery: true,
      };
    case REQUEST_ADD_PRODUCT_GALLERY_SUCCESS:
      return {
        ...state,
        isloadingAddProductGallery: false,
        successAddProductGallery: true,
        isfailedAddProductGallery: false,
      };
    case REQUEST_ADD_PRODUCT_GALLERY_FAILED:
      return {
        ...state,
        isloadingAddProductGallery: false,
        successAddProductGallery: false,
        isfailedAddProductGallery: true,
      };
    //**  Reducers for Edit Product Gallery
    case REQUEST_EDIT_PRODUCT_GALLERY:
      return {
        ...state,
        isloadingEditProductGallery: true,
      };
    case REQUEST_EDIT_PRODUCT_GALLERY_SUCCESS:
      return {
        ...state,
        isloadingEditProductGallery: false,
        successEditProductGallery: true,
        isfailedEditProductGallery: false,
      };
    case REQUEST_EDIT_PRODUCT_GALLERY_FAILED:
      return {
        ...state,
        isloadingEditProductGallery: false,
        successEditProductGallery: false,
        isfailedEditProductGallery: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Products Gallery
export const requestGetProductsGalleryList = () => {
  return {
    type: REQUEST_PRODUCT_GALLERY,
  };
};
export const requestGetProductsGalleryListSuccess = (
  productsGalleryList,
  productsGalleryPagesCount
) => {
  return {
    type: REQUEST_PRODUCT_GALLERY_SUCCESS,
    productsGalleryList: productsGalleryList,
    productsGalleryPagesCount: productsGalleryPagesCount,
  };
};
export const requestGetProductsGalleryListFailed = (message) => {
  return {
    type: REQUEST_PRODUCT_GALLERY_FAILED,
  };
};

// ** Actions for Product Gallery Item
export const requestGetProductGalleryItem = () => {
  return {
    type: REQUEST_PRODUCT_GALLERY_ITEM,
  };
};
export const requestGetProductGalleryItemSuccess = (productGalleryItem) => {
  return {
    type: REQUEST_PRODUCT_GALLERY_ITEM_SUCCESS,
    productGalleryItem: productGalleryItem,
  };
};
export const requestGetProductGalleryItemFailed = () => {
  return {
    type: REQUEST_PRODUCT_GALLERY_ITEM_FAILED,
  };
};

//** Actions For Add Product Gallery
export const requestAddProductGallery = () => {
  return {
    type: REQUEST_ADD_PRODUCT_GALLERY,
  };
};
export const requestAddProductGallerySuccess = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestAddProductGalleryFaield = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_GALLERY_FAILED,
    message: message,
  };
};

//** Actions For Edit Product Gallery
export const requestEditProductGallery = () => {
  return {
    type: REQUEST_EDIT_PRODUCT_GALLERY,
  };
};
export const requestEditProductGallerySuccess = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestEditProductGalleryFaield = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_GALLERY_FAILED,
    message: message,
  };
};
