// ** Reducers Imports
import { combineReducers } from "redux";
import navbar from "./navbar";
import layout from "./layout";
import auth from "./auth/auth";
import team from "./team/team";
import news from "./news/news";
import services from "./services/services";
import clients from "./clients/clients";
import partners from "./partners/partners";
import jobs from "./jobs/jobs";
import testimonials from "./testimonials/testimonials";
import companies from "./companies/companies";
import contactInfo from "./companyContactInfo/contactInfo";
import projects from "./projects/projects";
import projectGallery from "./projectGallery/projectGallery";
import projectLifeCycles from "./projectLifeCycles/projectLifeCycles";
import projectLifeCycleGallery from "./projectLifeCycles/projectLifeCycleGallery/projectLifeCycleGallery";
import products from "./products/products";
import productGallery from "./productGallery/productGallery";
import productLifeCycles from "./productLifeCycles/productLifeCycles";
import productLifeCycleGallery from "./productLifeCycles/productLifeCycleGallery/productLifeCycleGallery";
import users from "./users/users";
import events from "./events/events";
import enventsGallery from "./events/enventsGallery/enventsGallery";
import eventsProgramms from "./events/eventsProgramms/eventsProgramms";
import eventsProgrammsGallery from "./events/eventsProgramms/eventsProgrammsGallery/eventsProgrammsGallery";
import blogs from "./blogs/blogs";
import blogGallery from "./blogs/blogGallery/blogGallery";
import newsLetter from "./newsLetter/newsLetter";
import userMessages from "./userMessages/userMessages";
import openApplications from "./openApplications/openApplications";
import statistics from "./statistics/statistics";
import webVisitors from "./webVisitors/webVisitors";
import faqs from "./faqs/faqs";
import technologies from "./technologies/technologies";
import jobCandidates from "./jobCandidates/jobCandidates";
import candidateEmploymentPhases from "./candidateEmploymentPhases/candidateEmploymentPhases";
import projectCategories from "./project-categories/project-categories";
import productCategories from "./product-categories/product-categories";
import manufacturers from "./manufacturers/manufacturers";
import countries from "./countries/countries";
import cities from "./cities/cities";
import productFiles from "./productFiles/productFiles";

const rootReducer = combineReducers({
  auth,
  team,
  news,
  services,
  clients,
  partners,
  jobs,
  testimonials,
  projects,
  projectGallery,
  projectLifeCycles,
  projectLifeCycleGallery,
  products,
  productGallery,
  productLifeCycles,
  productLifeCycleGallery,
  users,
  events,
  enventsGallery,
  eventsProgramms,
  eventsProgrammsGallery,
  companies,
  contactInfo,
  blogs,
  blogGallery,
  newsLetter,
  userMessages,
  openApplications,
  statistics,
  webVisitors,
  technologies,
  jobCandidates,
  candidateEmploymentPhases,
  navbar,
  layout,
  faqs,
  projectCategories,
  productCategories,
  manufacturers,
  countries,
  cities,
  productFiles,
});

export default rootReducer;
