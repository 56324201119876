// ** Actions Types for Product File
const REQUEST_PRODUCT_FILE = "REQUEST_PRODUCT_FILE";
const REQUEST_PRODUCT_FILE_SUCCESS = "REQUEST_PRODUCT_FILE_SUCCESS";
const REQUEST_PRODUCT_FILE_FAILED = "REQUEST_PRODUCT_FILE_FAILED";
// ** Actions Types for Product File Item
const REQUEST_PRODUCT_FILE_ITEM = "REQUEST_PRODUCT_FILE_ITEM";
const REQUEST_PRODUCT_FILE_ITEM_SUCCESS = "REQUEST_PRODUCT_FILE_ITEM_SUCCESS";
const REQUEST_PRODUCT_FILE_ITEM_FAILED = "REQUEST_PRODUCT_FILE_ITEM_FAILED";
//** Actions Types For Add Product File
const REQUEST_ADD_PRODUCT_FILE = "REQUEST_ADD_PRODUCT_FILE";
const REQUEST_ADD_PRODUCT_FILE_SUCCESS = "REQUEST_ADD_PRODUCT_FILE_SUCCESS";
const REQUEST_ADD_PRODUCT_FILE_FAILED = "REQUEST_ADD_PRODUCT_FILE_FAILED";
//** Actions Types For Edit Product File
const REQUEST_EDIT_PRODUCT_FILE = "REQUEST_EDIT_PRODUCT_FILE";
const REQUEST_EDIT_PRODUCT_FILE_SUCCESS = "REQUEST_EDIT_PRODUCT_FILE_SUCCESS";
const REQUEST_EDIT_PRODUCT_FILE_FAILED = "REQUEST_EDIT_PRODUCT_FILE_FAILED";

// ** Initial State
const initialState = {
  productsFileList: [],
  productsFilePagesCount: "",
  isLoadingGetProductsFileList: false,
  isFailedGetProductsFileList: false,
  // ** State For Product File Item
  productFileItem: {},
  isLoadingProductFileItem: false,
  isfailedProductFileItem: false,
  // ** Add Product File
  isloadingAddProductFile: false,
  successAddProductFile: false,
  isFailedAddProductFile: false,
  //** Edit Product File
  isLoadingEditProductFile: false,
  successEditProductFile: false,
  isFailedEditProductFile: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Products File List
    case REQUEST_PRODUCT_FILE:
      return {
        ...state,
        isLoadingGetProductsFileList: true,
      };
    case REQUEST_PRODUCT_FILE_SUCCESS:
      return {
        ...state,
        productsFileList: action.productsFileList,
        productsFilePagesCount: action.productsFilePagesCount,
        isLoadingGetProductsFileList: false,
        isFailedGetProductsFileList: false,
      };
    case REQUEST_PRODUCT_FILE_FAILED:
      return {
        ...state,
        isLoadingGetProductsFileList: false,
        isFailedGetProductsFileList: true,
      };
    // ** Reducers for Product File Item
    case REQUEST_PRODUCT_FILE_ITEM:
      return {
        ...state,
        isLoadingProductFileItem: true,
      };
    case REQUEST_PRODUCT_FILE_ITEM_SUCCESS:
      return {
        ...state,
        productFileItem: action.productFileItem,
        isLoadingProductFileItem: false,
        isfailedProductFileItem: false,
      };
    case REQUEST_PRODUCT_FILE_ITEM_FAILED:
      return {
        ...state,
        isLoadingProductFileItem: false,
        isfailedProductFileItem: true,
      };
    // ** Reducers for Add Product File
    case REQUEST_ADD_PRODUCT_FILE:
      return {
        ...state,
        isloadingAddProductFile: true,
      };
    case REQUEST_ADD_PRODUCT_FILE_SUCCESS:
      return {
        ...state,
        isloadingAddProductFile: false,
        successAddProductFile: true,
        isFailedAddProductFile: false,
      };
    case REQUEST_ADD_PRODUCT_FILE_FAILED:
      return {
        ...state,
        isloadingAddProductFile: false,
        successAddProductFile: false,
        isFailedAddProductFile: true,
      };
    //**  Reducers for Edit Product File
    case REQUEST_EDIT_PRODUCT_FILE:
      return {
        ...state,
        isLoadingEditProductFile: true,
      };
    case REQUEST_EDIT_PRODUCT_FILE_SUCCESS:
      return {
        ...state,
        isLoadingEditProductFile: false,
        successEditProductFile: true,
        isFailedEditProductFile: false,
      };
    case REQUEST_EDIT_PRODUCT_FILE_FAILED:
      return {
        ...state,
        isLoadingEditProductFile: false,
        successEditProductFile: false,
        isFailedEditProductFile: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Products File
export const requestGetProductsFileList = () => {
  return {
    type: REQUEST_PRODUCT_FILE,
  };
};
export const requestGetProductsFileListSuccess = (
  productsFileList,
  productsFilePagesCount
) => {
  return {
    type: REQUEST_PRODUCT_FILE_SUCCESS,
    productsFileList: productsFileList,
    productsFilePagesCount: productsFilePagesCount,
  };
};
export const requestGetProductsFileListFailed = (message) => {
  return {
    type: REQUEST_PRODUCT_FILE_FAILED,
  };
};

// ** Actions for Product File Item
export const requestGetProductFileItem = () => {
  return {
    type: REQUEST_PRODUCT_FILE_ITEM,
  };
};
export const requestGetProductFileItemSuccess = (productFileItem) => {
  return {
    type: REQUEST_PRODUCT_FILE_ITEM_SUCCESS,
    productFileItem: productFileItem,
  };
};
export const requestGetProductFileItemFailed = () => {
  return {
    type: REQUEST_PRODUCT_FILE_ITEM_FAILED,
  };
};

//** Actions For Add Product File
export const requestAddProductFile = () => {
  return {
    type: REQUEST_ADD_PRODUCT_FILE,
  };
};
export const requestAddProductFileSuccess = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_FILE_SUCCESS,
    message: message,
  };
};
export const requestAddProductFileFaield = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_FILE_FAILED,
    message: message,
  };
};

//** Actions For Edit Product File
export const requestEditProductFile = () => {
  return {
    type: REQUEST_EDIT_PRODUCT_FILE,
  };
};
export const requestEditProductFileSuccess = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_FILE_SUCCESS,
    message: message,
  };
};
export const requestEditProductFileFaield = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_FILE_FAILED,
    message: message,
  };
};
