// ** Actions Types for News
const REQUEST_NEWS = "REQUEST_NEWS";
const REQUEST_NEWS_SUCCESS = "REQUEST_NEWS_SUCCESS";
const REQUEST_NEWS_FAILED = "REQUEST_NEWS_FAILED";
// ** Actions Types for News Item
const REQUEST_NEWS_ITEM = "REQUEST_NEWS_ITEM";
const REQUEST_NEWS_ITEM_SUCCESS = "REQUEST_NEWS_ITEM_SUCCESS";
const REQUEST_NEWS_ITEM_FAILED = "REQUEST_NEWS_ITEM_FAILED";
//** Actions Types For Add News
const REQUEST_ADD_NEWS = "REQUEST_ADD_NEWS";
const REQUEST_ADD_NEWS_SUCCESS = "REQUEST_ADD_NEWS_SUCCESS";
const REQUEST_ADD_NEWS_FAILED = "REQUEST_ADD_NEWS_FAILED";
//** Actions Types For Edit News
const REQUEST_EDIT_NEWS = "REQUEST_EDIT_NEWS";
const REQUEST_EDIT_NEWS_SUCCESS = "REQUEST_EDIT_NEWS_SUCCESS";
const REQUEST_EDIT_NEWS_FAILED = "REQUEST_EDIT_NEWS_FAILED";

// ** Initial State
const initialState = {
  newsList: [],
  newsTotalNum: "",
  isLoadingGetNewsList: false,
  isFailedGetNewsList: false,
  // ** State For News Item
  newsItem: {},
  isLoadingNewsItem: false,
  isfailedNewsItem: false,
  // ** Add News
  isloadingAddNews: false,
  successAddNews: false,
  isfailedAddNews: false,
  //** Edit News
  isloadingEditNews: false,
  successEditNews: false,
  isfailedEditNews: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for News List
    case REQUEST_NEWS:
      return {
        ...state,
        isLoadingGetNewsList: true,
      };
    case REQUEST_NEWS_SUCCESS:
      return {
        ...state,
        newsList: action.newsList,
        newsTotalNum: action.newsTotalNum,
        isLoadingGetNewsList: false,
        isFailedGetNewsList: false,
      };
    case REQUEST_NEWS_FAILED:
      return {
        ...state,
        isLoadingGetNewsList: false,
        isFailedGetNewsList: true,
      };
    // ** Reducers for News Item
    case REQUEST_NEWS_ITEM:
      return {
        ...state,
        isLoadingNewsItem: true,
      };
    case REQUEST_NEWS_ITEM_SUCCESS:
      return {
        ...state,
        newsItem: action.newsItem,
        isLoadingNewsItem: false,
        isfailedNewsItem: false,
      };
    case REQUEST_NEWS_ITEM_FAILED:
      return {
        ...state,
        isLoadingNewsItem: false,
        isfailedNewsItem: true,
      };
    // ** Reducers for Add News
    case REQUEST_ADD_NEWS:
      return {
        ...state,
        isloadingAddNews: true,
      };
    case REQUEST_ADD_NEWS_SUCCESS:
      return {
        ...state,
        isloadingAddNews: false,
        successAddNews: true,
        isfailedAddNews: false,
      };
    case REQUEST_ADD_NEWS_FAILED:
      return {
        ...state,
        isloadingAddNews: false,
        successAddNews: false,
        isfailedAddNews: true,
      };
    //**  Reducers for Edit News
    case REQUEST_EDIT_NEWS:
      return {
        ...state,
        isloadingEditNews: true,
      };
    case REQUEST_EDIT_NEWS_SUCCESS:
      return {
        ...state,
        isloadingEditNews: false,
        successEditNews: true,
        isfailedEditNews: false,
      };
    case REQUEST_EDIT_NEWS_FAILED:
      return {
        ...state,
        isloadingEditNews: false,
        successEditNews: false,
        isfailedEditNews: true,
      };
    default:
      return state;
  }
};

// ** Actions for All News List
export const requestGetNewsList = () => {
  return {
    type: REQUEST_NEWS,
  };
};
export const requestGetNewsListSuccess = (newsList, newsTotalNum) => {
  return {
    type: REQUEST_NEWS_SUCCESS,
    newsList: newsList,
    newsTotalNum: newsTotalNum,
  };
};
export const requestGetNewsListFailed = (message) => {
  return {
    type: REQUEST_NEWS_FAILED,
  };
};

// ** Actions for News Item
export const requestGetNewsItem = () => {
  return {
    type: REQUEST_NEWS_ITEM,
  };
};
export const requestGetNewsItemSuccess = (newsItem) => {
  return {
    type: REQUEST_NEWS_ITEM_SUCCESS,
    newsItem: newsItem,
  };
};
export const requestGetNewsItemFailed = () => {
  return {
    type: REQUEST_NEWS_ITEM_FAILED,
  };
};
//** Actions For Add News
export const requestAddNews = () => {
  return {
    type: REQUEST_ADD_NEWS,
  };
};
export const requestAddNewsSuccess = (message) => {
  return {
    type: REQUEST_ADD_NEWS_SUCCESS,
    message: message,
  };
};
export const requestAddNewsFaield = (message) => {
  return {
    type: REQUEST_ADD_NEWS_FAILED,
    message: message,
  };
};

//** Actions For Edit News
export const requestEditNews = () => {
  return {
    type: REQUEST_EDIT_NEWS,
  };
};
export const requestEditNewsSuccess = (message) => {
  return {
    type: REQUEST_EDIT_NEWS_SUCCESS,
    message: message,
  };
};
export const requestEditNewsFaield = (message) => {
  return {
    type: REQUEST_EDIT_NEWS_FAILED,
    message: message,
  };
};
