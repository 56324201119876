// ** Actions Types for Companies Contact Info
const REQUEST_COMPANIES_CONTACT_IFNO = "REQUEST_COMPANIES_CONTACT_IFNO";
const REQUEST_COMPANIES_CONTACT_IFNO_SUCCESS =
  "REQUEST_COMPANIES_CONTACT_IFNO_SUCCESS";
const REQUEST_COMPANIES_CONTACT_IFNO_FAILED =
  "REQUEST_COMPANIES_CONTACT_IFNO_FAILED";
// ** Actions Types for Company Contact Info Item
const REQUEST_COMPANIE_CONTACT_IFNO_ITEM = "REQUEST_COMPANIE_CONTACT_IFNO_ITEM";
const REQUEST_COMPANIE_CONTACT_IFNO_ITEM_SUCCESS =
  "REQUEST_COMPANIE_CONTACT_IFNO_ITEM_SUCCESS";
const REQUEST_COMPANIE_CONTACT_IFNO_ITEM_FAILED =
  "REQUEST_COMPANIE_CONTACT_IFNO_ITEM_FAILED";
//** Actions Types For Add Company Contact Info
const REQUEST_ADD_COMPANIE_CONTACT_IFNO = "REQUEST_ADD_COMPANIE_CONTACT_IFNO";
const REQUEST_ADD_COMPANIE_CONTACT_IFNO_SUCCESS =
  "REQUEST_ADD_COMPANIE_CONTACT_IFNO_SUCCESS";
const REQUEST_ADD_COMPANIE_CONTACT_IFNO_FAILED =
  "REQUEST_ADD_COMPANIE_CONTACT_IFNO_FAILED";
//** Actions Types For Edit Company Contact Info
const REQUEST_EDIT_COMPANIE_CONTACT_IFNO = "REQUEST_EDIT_COMPANIE_CONTACT_IFNO";
const REQUEST_EDIT_COMPANIE_CONTACT_IFNO_SUCCESS =
  "REQUEST_EDIT_COMPANIE_CONTACT_IFNO_SUCCESS";
const REQUEST_EDIT_COMPANIE_CONTACT_IFNO_FAILED =
  "REQUEST_EDIT_COMPANIE_CONTACT_IFNO_FAILED";

// ** Initial State
const initialState = {
  companiesContactInfoList: [],
  companiesContactInfoTotalNum: "",

  isLoadingGetCompaniesContactInfoList: false,
  isFailedGetCompaniesContactInfoList: false,
  // ** State For Company Contact Info Item
  companieContactInfotItem: {},
  isLoadingCompanieContactInfoItem: false,
  isfailedCompanieContactInfoItem: false,
  // ** Add Company Contact Info
  isloadingAddCompanieContactInfo: false,
  successAddCompanieContactInfo: false,
  isfailedAddCompanieContactInfo: false,
  //** Edit Company Contact Info
  isloadingEditCompanieContactInfo: false,
  successEditCompanieContactInfo: false,
  isfailedEditCompanieContactInfo: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Company Contact Info List
    case REQUEST_COMPANIES_CONTACT_IFNO:
      return {
        ...state,
        isLoadingGetCompaniesContactInfoList: true,
      };
    case REQUEST_COMPANIES_CONTACT_IFNO_SUCCESS:
      return {
        ...state,
        companiesContactInfoList: action.companiesContactInfoList,
        companiesContactInfoTotalNum: action.companiesContactInfoTotalNum,
        isLoadingGetCompaniesContactInfoList: false,
        isFailedGetCompaniesContactInfoList: false,
      };
    case REQUEST_COMPANIES_CONTACT_IFNO_FAILED:
      return {
        ...state,
        isLoadingGetCompaniesContactInfoList: false,
        isFailedGetCompaniesContactInfoList: true,
      };
    // ** Reducers for Company Contact Info Item
    case REQUEST_COMPANIE_CONTACT_IFNO_ITEM:
      return {
        ...state,
        isLoadingCompanieContactInfoItem: true,
      };
    case REQUEST_COMPANIE_CONTACT_IFNO_ITEM_SUCCESS:
      return {
        ...state,
        companieContactInfotItem: action.companieContactInfotItem,
        isLoadingCompanieContactInfoItem: false,
        isfailedCompanieContactInfoItem: false,
      };
    case REQUEST_COMPANIE_CONTACT_IFNO_ITEM_FAILED:
      return {
        ...state,
        isLoadingCompanieContactInfoItem: false,
        isfailedCompanieContactInfoItem: true,
      };
    // ** Reducers for Add Company Contact Info
    case REQUEST_ADD_COMPANIE_CONTACT_IFNO:
      return {
        ...state,
        isloadingAddCompanieContactInfo: true,
      };
    case REQUEST_ADD_COMPANIE_CONTACT_IFNO_SUCCESS:
      return {
        ...state,
        isloadingAddCompanieContactInfo: false,
        successAddCompanieContactInfo: true,
        isfailedAddCompanieContactInfo: false,
      };
    case REQUEST_ADD_COMPANIE_CONTACT_IFNO_FAILED:
      return {
        ...state,
        isloadingAddCompanieContactInfo: false,
        successAddCompanieContactInfo: false,
        isfailedAddCompanieContactInfo: true,
      };
    //**  Reducers for Edit Company Contact Info
    case REQUEST_EDIT_COMPANIE_CONTACT_IFNO:
      return {
        ...state,
        isloadingEditCompanieContactInfo: true,
      };
    case REQUEST_EDIT_COMPANIE_CONTACT_IFNO_SUCCESS:
      return {
        ...state,
        isloadingEditCompanieContactInfo: false,
        successEditCompanieContactInfo: true,
        isfailedEditCompanieContactInfo: false,
      };
    case REQUEST_EDIT_COMPANIE_CONTACT_IFNO_FAILED:
      return {
        ...state,
        isloadingEditCompanieContactInfo: false,
        successEditCompanieContactInfo: false,
        isfailedEditCompanieContactInfo: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Companies Contact Info List
export const requestGetCompaniesContactInfoList = () => {
  return {
    type: REQUEST_COMPANIES_CONTACT_IFNO,
  };
};
export const requestGetCompaniesContactInfoListSuccess = (
  companiesContactInfoList,
  companiesContactInfoTotalNum,

) => {
  return {
    type: REQUEST_COMPANIES_CONTACT_IFNO_SUCCESS,
    companiesContactInfoList: companiesContactInfoList,
    companiesContactInfoTotalNum: companiesContactInfoTotalNum,

  };
};
export const requestGetCompaniesContactInfoListFailed = (message) => {
  return {
    type: REQUEST_COMPANIES_CONTACT_IFNO_FAILED,
  };
};
// ** Actions for Company Contact Info Item
export const requestGetCompanyContactInfoItem = () => {
  return {
    type: REQUEST_COMPANIE_CONTACT_IFNO_ITEM,
  };
};
export const requestGetCompanyContactInfoItemSuccess = (
  companieContactInfotItem
) => {
  return {
    type: REQUEST_COMPANIE_CONTACT_IFNO_ITEM_SUCCESS,
    companieContactInfotItem: companieContactInfotItem,
  };
};
export const requestGetCompanyContactInfoItemFailed = () => {
  return {
    type: REQUEST_COMPANIE_CONTACT_IFNO_ITEM_FAILED,
  };
};
//** Actions For Add Company Contact Info
export const requestAddCompanyContactInfo = () => {
  return {
    type: REQUEST_ADD_COMPANIE_CONTACT_IFNO,
  };
};
export const requestAddCompanyContactInfoSuccess = (message) => {
  return {
    type: REQUEST_ADD_COMPANIE_CONTACT_IFNO_SUCCESS,
    message: message,
  };
};
export const requestAddCompanyContactInfoFaield = (message) => {
  return {
    type: REQUEST_ADD_COMPANIE_CONTACT_IFNO_FAILED,
    message: message,
  };
};
//** Actions For Edit Company Contact Info
export const requestEditCompanyContactInfo = () => {
  return {
    type: REQUEST_EDIT_COMPANIE_CONTACT_IFNO,
  };
};
export const requestEditCompanyContactInfoSuccess = (message) => {
  return {
    type: REQUEST_EDIT_COMPANIE_CONTACT_IFNO_SUCCESS,
    message: message,
  };
};
export const requestEditCompanyContactInfoFaield = (message) => {
  return {
    type: REQUEST_EDIT_COMPANIE_CONTACT_IFNO_FAILED,
    message: message,
  };
};
