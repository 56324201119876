// ** Actions Types for Users
const REQUEST_USERS = "REQUEST_USERS";
const REQUEST_USERS_SUCCESS = "REQUEST_USERS_SUCCESS";
const REQUEST_USERS_FAILED = "REQUEST_USERS_FAILED";
// ** Actions Types for User Item
const REQUEST_USER_ITEM = "REQUEST_USER_ITEM";
const REQUEST_USER_ITEM_SUCCESS = "REQUEST_USER_ITEM_SUCCESS";
const REQUEST_USER_ITEM_FAILED = "REQUEST_USER_ITEM_FAILED";
// ** Actions Types for User
const REQUEST_ADD_USER = "REQUEST_ADD_USER";
const REQUEST_ADD_USER_SUCCESS = "REQUEST_ADD_USER_SUCCESS";
const REQUEST_ADD_USER_FAILED = "REQUEST_ADD_USER_FAILED";
//** Actions Types For Edit User
const REQUEST_EDIT_USER = "REQUEST_EDIT_USER";
const REQUEST_EDIT_USER_SUCCESS = "REQUEST_EDIT_USER_SUCCESS";
const REQUEST_EDIT_USER_FAILED = "REQUEST_EDIT_USER_FAILED";


//** Actions Types For Reset password
const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";
const REQUEST_RESET_PASSWORD_SUCCESS = "REQUEST_RESET_PASSWORD_SUCCESS";
const REQUEST_RESET_PASSWORD_FAILED = "REQUEST_RESET_PASSWORD_FAILED";

// Initial State
const initialState = {
  usersList: [],
  usersTotalNum: "",
  isLoadingGetUsersList: false,
  isFailedGetUsersList: false,
  // ** State For User Item
  userItem: {},
  isLoadingUserItem: false,
  isFailedUserItem: false,
  // ** Add User
  isloadingAddUser: false,
  successAddUser: false,
  isFailedAddUser: false,
  //** Edit User
  isloadingEditUser: false,
  successEditUser: false,
  isFailedEditUser: false,

  //** Reset Password
  isloadingResetPassword: false,
  successResetPassword: false,
  isFailedResetPassword: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Users List
    case REQUEST_USERS:
      return {
        ...state,
        isLoadingGetUsersList: true,
      };
    case REQUEST_USERS_SUCCESS:
      return {
        ...state,
        usersList: action.usersList,
        usersTotalNum: action.usersTotalNum,
        isLoadingGetUsersList: false,
        isFailedGetUsersList: false,
      };
    case REQUEST_USERS_FAILED:
      return {
        ...state,
        isLoadingGetUsersList: false,
        isFailedGetUsersList: true,
      };
    // ** Reducers for User Item
    case REQUEST_USER_ITEM:
      return {
        ...state,
        isLoadingUserItem: true,
      };
    case REQUEST_USER_ITEM_SUCCESS:
      return {
        ...state,
        userItem: action.userItem,
        isLoadingUserItem: false,
        isFailedUserItem: false,
      };
    case REQUEST_USER_ITEM_FAILED:
      return {
        ...state,
        isLoadingUserItem: false,
        isFailedUserItem: true,
      };
    // ** Reducers for Add User
    case REQUEST_ADD_USER:
      return {
        ...state,
        isloadingAddUser: true,
      };
    case REQUEST_ADD_USER_SUCCESS:
      return {
        ...state,
        isloadingAddUser: false,
        successAddUser: true,
        isFailedAddUser: false,
      };
    case REQUEST_ADD_USER_FAILED:
      return {
        ...state,
        isloadingAddUser: false,
        successAddUser: false,
        isFailedAddUser: true,
      };
    //**  Reducers for Edit User
    case REQUEST_EDIT_USER:
      return {
        ...state,
        isloadingEditUser: true,
      };
    case REQUEST_EDIT_USER_SUCCESS:
      return {
        ...state,
        isloadingEditUser: false,
        successEditUser: true,
        isFailedEditUser: false,
      };
    case REQUEST_EDIT_USER_FAILED:
      return {
        ...state,
        isloadingEditUser: false,
        successEditUser: false,
        isFailedEditUser: true,
      };

    //**  Reducers for Reset Password
    case REQUEST_RESET_PASSWORD:
      return {
        ...state,
        isloadingResetPassword: true,
      };
    case REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isloadingResetPassword: false,
        successResetPassword: true,
        isFailedResetPassword: false,
      };
    case REQUEST_RESET_PASSWORD_FAILED:
      return {
        ...state,
        isloadingResetPassword: false,
        successResetPassword: false,
        isFailedResetPassword: true,
      };

    default:
      return state;
  }
};


//** Actions For Reset password
export const requestResetPassword = () => {
  return {
    type: REQUEST_RESET_PASSWORD,
  };
};
export const requestResetPasswordSuccess = (message) => {
  return {
    type: REQUEST_RESET_PASSWORD_SUCCESS,
    message: message,
  };
};
export const requestResetPasswordFaield = (message) => {
  return {
    type: REQUEST_RESET_PASSWORD_FAILED,
    message: message,
  };
};


// ** Actions for All Users
export const requestGetUsersList = () => {
  return {
    type: REQUEST_USERS,
  };
};
export const requestGetUsersListSuccess = (usersList, usersTotalNum) => {
  return {
    type: REQUEST_USERS_SUCCESS,
    usersList: usersList,
    usersTotalNum: usersTotalNum,
  };
};
export const requestGetUsersListFailed = (message) => {
  return {
    type: REQUEST_USERS_FAILED,
  };
};

// ** Actions for User Item
export const requestGetUserItem = () => {
  return {
    type: REQUEST_USER_ITEM,
  };
};
export const requestGetUserItemSuccess = (userItem) => {
  return {
    type: REQUEST_USER_ITEM_SUCCESS,
    userItem: userItem,
  };
};
export const requestGetUserItemFailed = () => {
  return {
    type: REQUEST_USER_ITEM_FAILED,
  };
};

//** Actions For Add User
export const requestAddUser = () => {
  return {
    type: REQUEST_ADD_USER,
  };
};
export const requestAddUserSuccess = (message) => {
  return {
    type: REQUEST_ADD_USER_SUCCESS,
    message: message,
  };
};
export const requestAddUserFaield = (message) => {
  return {
    type: REQUEST_ADD_USER_FAILED,
    message: message,
  };
};

//** Actions For Edit User
export const requestEditUser = () => {
  return {
    type: REQUEST_EDIT_USER,
  };
};
export const requestEditUserSuccess = (message) => {
  return {
    type: REQUEST_EDIT_USER_SUCCESS,
    message: message,
  };
};
export const requestEditUserFaield = (message) => {
  return {
    type: REQUEST_EDIT_USER_FAILED,
    message: message,
  };
};
