// ** Actions Types for Partners
const REQUEST_PARTNERS = "REQUEST_PARTNERS";
const REQUEST_PARTNERS_SUCCESS = "REQUEST_PARTNERS_SUCCESS";
const REQUEST_PARTNERS_FAILED = "REQUEST_PARTNERS_FAILED";
// ** Actions Types for Partners Item
const REQUEST_PARTNERS_ITEM = "REQUEST_PARTNERS_ITEM";
const REQUEST_PARTNERS_ITEM_SUCCESS = "REQUEST_PARTNERS_ITEM_SUCCESS";
const REQUEST_PARTNERS_ITEM_FAILED = "REQUEST_PARTNERS_ITEM_FAILED";
//** Actions Types For Add Partner
const REQUEST_ADD_PARTNER = "REQUEST_ADD_PARTNER";
const REQUEST_ADD_PARTNER_SUCCESS = "REQUEST_ADD_PARTNER_SUCCESS";
const REQUEST_ADD_PARTNER_FAILED = "REQUEST_ADD_PARTNER_FAILED";
//** Actions Types For Edit Partner
const REQUEST_EDIT_PARTNER = "REQUEST_EDIT_PARTNER";
const REQUEST_EDIT_PARTNER_SUCCESS = "REQUEST_EDIT_PARTNER_SUCCESS";
const REQUEST_EDIT_PARTNER_FAILED = "REQUEST_EDIT_PARTNER_FAILED";

// ** Initial State
const initialState = {
  partnersList: [],
  partnersPagesCount: "",
  isLoadingGetPartnersList: false,
  isFailedGetPartnersList: false,
  // ** State For Partners Item
  partnerItem: {},
  isLoadingPartnersItem: false,
  isfailedPartnersItem: false,
  // ** Add Partner
  isloadingAddPartner: false,
  successAddPartner: false,
  isfailedAddPartner: false,
  //** Edit Partner
  isloadingEditPartner: false,
  successAddPartner: false,
  isfailedEditPartner: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Partners List
    case REQUEST_PARTNERS:
      return {
        ...state,
        isLoadingGetPartnersList: true,
      };
    case REQUEST_PARTNERS_SUCCESS:
      return {
        ...state,
        partnersList: action.partnersList,
        partnersPagesCount: action.partnersPagesCount,
        isLoadingGetPartnersList: false,
        isFailedGetPartnersList: false,
      };
    case REQUEST_PARTNERS_FAILED:
      return {
        ...state,
        isLoadingGetPartnersList: false,
        isFailedGetPartnersList: true,
      };
    // ** Reducers for Partners Item
    case REQUEST_PARTNERS_ITEM:
      return {
        ...state,
        isLoadingPartnersItem: true,
      };
    case REQUEST_PARTNERS_ITEM_SUCCESS:
      return {
        ...state,
        partnerItem: action.partnerItem,
        isLoadingPartnersItem: false,
        isfailedPartnersItem: false,
      };
    case REQUEST_PARTNERS_ITEM_FAILED:
      return {
        ...state,
        isLoadingPartnersItem: false,
        isfailedPartnersItem: true,
      };
    // ** Reducers for Add Partner
    case REQUEST_ADD_PARTNER:
      return {
        ...state,
        isloadingAddPartner: true,
      };
    case REQUEST_ADD_PARTNER_SUCCESS:
      return {
        ...state,
        isloadingAddPartner: false,
        successAddPartner: true,
        isfailedAddPartner: false,
      };
    case REQUEST_ADD_PARTNER_FAILED:
      return {
        ...state,
        isloadingAddPartner: false,
        successAddPartner: false,
        isfailedAddPartner: true,
      };
    //**  Reducers for Edit Partner
    case REQUEST_EDIT_PARTNER:
      return {
        ...state,
        isloadingEditPartner: true,
      };
    case REQUEST_EDIT_PARTNER_SUCCESS:
      return {
        ...state,
        isloadingEditPartner: false,
        successAddPartner: true,
        isfailedEditPartner: false,
      };
    case REQUEST_EDIT_PARTNER_FAILED:
      return {
        ...state,
        isloadingEditPartner: false,
        successAddPartner: false,
        isfailedEditPartner: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Partners
export const requestGetPartnersList = () => {
  return {
    type: REQUEST_PARTNERS,
  };
};
export const requestGetPartnersListSuccess = (
  partnersList,
  partnersPagesCount
) => {
  return {
    type: REQUEST_PARTNERS_SUCCESS,
    partnersList: partnersList,
    partnersPagesCount: partnersPagesCount,
  };
};
export const requestGetPartnersListFailed = (message) => {
  return {
    type: REQUEST_PARTNERS_FAILED,
  };
};
// ** Actions for Partners Item
export const requestGetPartnersItem = () => {
  return {
    type: REQUEST_PARTNERS_ITEM,
  };
};
export const requestGetPartnersItemSuccess = (partnerItem) => {
  return {
    type: REQUEST_PARTNERS_ITEM_SUCCESS,
    partnerItem: partnerItem,
  };
};
export const requestGetPartnersItemFailed = () => {
  return {
    type: REQUEST_PARTNERS_ITEM_FAILED,
  };
};
//** Actions For Add Partner
export const requestAddPartner = () => {
  return {
    type: REQUEST_ADD_PARTNER,
  };
};
export const requestAddPartnerSuccess = (message) => {
  return {
    type: REQUEST_ADD_PARTNER_SUCCESS,
    message: message,
  };
};
export const requestAddPartnerFaield = (message) => {
  return {
    type: REQUEST_ADD_PARTNER_FAILED,
    message: message,
  };
};

//** Actions For Edit Partner
export const requestEditPartner = () => {
  return {
    type: REQUEST_EDIT_PARTNER,
  };
};
export const requestEditPartnerSuccess = (message) => {
  return {
    type: REQUEST_EDIT_PARTNER_SUCCESS,
    message: message,
  };
};
export const requestEditPartnerFaield = (message) => {
  return {
    type: REQUEST_EDIT_PARTNER_FAILED,
    message: message,
  };
};
