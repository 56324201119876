// ** Actions Types for PRODUCT_CATEGORIES
const REQUEST_PRODUCT_CATEGORIES = "REQUEST_PRODUCT_CATEGORIES";
const REQUEST_PRODUCT_CATEGORIES_SUCCESS = "REQUEST_PRODUCT_CATEGORIES_SUCCESS";
const REQUEST_PRODUCT_CATEGORIES_FAILED = "REQUEST_PRODUCT_CATEGORIES_FAILED";
// ** Actions Types for PRODUCT_CATEGORIES Item
const REQUEST_PRODUCT_CATEGORIES_ITEM = "REQUEST_PRODUCT_CATEGORIES_ITEM";
const REQUEST_PRODUCT_CATEGORIES_ITEM_SUCCESS =
  "REQUEST_PRODUCT_CATEGORIES_ITEM_SUCCESS";
const REQUEST_PRODUCT_CATEGORIES_ITEM_FAILED =
  "REQUEST_PRODUCT_CATEGORIES_ITEM_FAILED";
//** Actions Types For Add PRODUCT_CATEGORIE
const REQUEST_ADD_PRODUCT_CATEGORIE = "REQUEST_ADD_PRODUCT_CATEGORIE";
const REQUEST_ADD_PRODUCT_CATEGORIE_SUCCESS =
  "REQUEST_ADD_PRODUCT_CATEGORIE_SUCCESS";
const REQUEST_ADD_PRODUCT_CATEGORIE_FAILED =
  "REQUEST_ADD_PRODUCT_CATEGORIE_FAILED";
//** Actions Types For Edit PRODUCT_CATEGORIE
const REQUEST_EDIT_PRODUCT_CATEGORIE = "REQUEST_EDIT_PRODUCT_CATEGORIE";
const REQUEST_EDIT_PRODUCT_CATEGORIE_SUCCESS =
  "REQUEST_EDIT_PRODUCT_CATEGORIE_SUCCESS";
const REQUEST_EDIT_PRODUCT_CATEGORIE_FAILED =
  "REQUEST_EDIT_PRODUCT_CATEGORIE_FAILED";

// ** Initial State
const initialState = {
  productCategoriesList: [],
  productCategoriesTotalNum: "",
  isLoadingGetProductCategoriesList: false,
  isFailedGetProductCategoriesList: false,
  // ** State For PRODUCT_CATEGORIES Item
  productCategorieItem: {},
  isLoadingProductCategorieItem: false,
  isFailedProductCategorieItem: false,
  // ** Add PRODUCT_CATEGORIE
  isLoadingAddProductCategorie: false,
  successAddProductCategorie: false,
  isFailedAddProductCategorie: false,
  //** Edit PRODUCT_CATEGORIES
  isLoadingEditProductCategorie: false,
  successEditProductCategorie: false,
  isFailedEditProductCategorie: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for PRODUCT_CATEGORIES List
    case REQUEST_PRODUCT_CATEGORIES:
      return {
        ...state,
        isLoadingGetProductCategoriesList: true,
      };
    case REQUEST_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        productCategoriesList: action.productCategoriesList,
        productCategoriesTotalNum: action.productCategoriesTotalNum,
        isLoadingGetProductCategoriesList: false,
        isFailedGetProductCategoriesList: false,
      };
    case REQUEST_PRODUCT_CATEGORIES_FAILED:
      return {
        ...state,
        isLoadingGetProductCategoriesList: false,
        isFailedGetProductCategoriesList: true,
      };
    // ** Reducers for ProductCategories Item
    case REQUEST_PRODUCT_CATEGORIES_ITEM:
      return {
        ...state,
        isLoadingProductCategorieItem: true,
      };
    case REQUEST_PRODUCT_CATEGORIES_ITEM_SUCCESS:
      return {
        ...state,
        productCategorieItem: action.productCategorieItem,
        isLoadingProductCategorieItem: false,
        isFailedProductCategorieItem: false,
      };
    case REQUEST_PRODUCT_CATEGORIES_ITEM_FAILED:
      return {
        ...state,
        isLoadingProductCategorieItem: false,
        isFailedProductCategorieItem: true,
      };
    // ** Reducers for Add PRODUCT_CATEGORIE
    case REQUEST_ADD_PRODUCT_CATEGORIE:
      return {
        ...state,
        isLoadingAddProductCategorie: true,
      };
    case REQUEST_ADD_PRODUCT_CATEGORIE_SUCCESS:
      return {
        ...state,
        isLoadingAddProductCategorie: false,
        successAddProductCategorie: true,
        isFailedAddProductCategorie: false,
      };
    case REQUEST_ADD_PRODUCT_CATEGORIE_FAILED:
      return {
        ...state,
        isLoadingAddProductCategorie: false,
        successAddProductCategorie: false,
        isFailedAddProductCategorie: true,
      };
    //**  Reducers for Edit PRODUCT_CATEGORIE
    case REQUEST_EDIT_PRODUCT_CATEGORIE:
      return {
        ...state,
        isLoadingEditProductCategorie: true,
      };
    case REQUEST_EDIT_PRODUCT_CATEGORIE_SUCCESS:
      return {
        ...state,
        isLoadingEditProductCategorie: false,
        successEditProductCategorie: true,
        isFailedEditProductCategorie: false,
      };
    case REQUEST_EDIT_PRODUCT_CATEGORIE_FAILED:
      return {
        ...state,
        isLoadingEditProductCategorie: false,
        successEditProductCategorie: false,
        isFailedEditProductCategorie: true,
      };
    default:
      return state;
  }
};

// ** Actions for All PRODUCT_CATEGORIEs List
export const requestGetProductCategoriesList = () => {
  return {
    type: REQUEST_PRODUCT_CATEGORIES,
  };
};
export const requestGetProductCategoriesListSuccess = (
  productCategoriesList,
  productCategoriesTotalNum
) => {
  return {
    type: REQUEST_PRODUCT_CATEGORIES_SUCCESS,
    productCategoriesList: productCategoriesList,
    productCategoriesTotalNum: productCategoriesTotalNum,
  };
};
export const requestGetProductCategoriesListFailed = (message) => {
  return {
    type: REQUEST_PRODUCT_CATEGORIES_FAILED,
  };
};

// ** Actions for ProductCategories Item
export const requestGetProductCategoriesItem = () => {
  return {
    type: REQUEST_PRODUCT_CATEGORIES_ITEM,
  };
};
export const requestGetProductCategoriesItemSuccess = (
  productCategorieItem
) => {
  return {
    type: REQUEST_PRODUCT_CATEGORIES_ITEM_SUCCESS,
    productCategorieItem: productCategorieItem,
  };
};
export const requestGetProductCategoriesItemFailed = () => {
  return {
    type: REQUEST_PRODUCT_CATEGORIES_ITEM_FAILED,
  };
};
//** Actions For Add PRODUCT_CATEGORIE
export const requestAddProductCategorie = () => {
  return {
    type: REQUEST_ADD_PRODUCT_CATEGORIE,
  };
};
export const requestAddProductCategorieSuccess = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_CATEGORIE_SUCCESS,
    message: message,
  };
};
export const requestAddProductCategorieFaield = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_CATEGORIE_FAILED,
    message: message,
  };
};

//** Actions For Edit PRODUCT_CATEGORIE
export const requestEditProductCategorie = () => {
  return {
    type: REQUEST_EDIT_PRODUCT_CATEGORIE,
  };
};
export const requestEditProductCategorieSuccess = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_CATEGORIE_SUCCESS,
    message: message,
  };
};
export const requestEditProductCategorieFaield = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_CATEGORIE_FAILED,
    message: message,
  };
};
