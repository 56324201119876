// ** Actions Types for Projects
const REQUEST_PROJECTS = "REQUEST_PROJECTS";
const REQUEST_PROJECTS_SUCCESS = "REQUEST_PROJECTS_SUCCESS";
const REQUEST_PROJECTS_FAILED = "REQUEST_PROJECTS_FAILED";
// ** Actions Types for Project Item
const REQUEST_PROJECT_ITEM = "REQUEST_PROJECT_ITEM";
const REQUEST_PROJECT_ITEM_SUCCESS = "REQUEST_PROJECT_ITEM_SUCCESS";
const REQUEST_PROJECT_ITEM_FAILED = "REQUEST_PROJECT_ITEM_FAILED";
//** Actions Types For Add Project
const REQUEST_ADD_PROJECT = "REQUEST_ADD_PROJECT";
const REQUEST_ADD_PROJECT_SUCCESS = "REQUEST_ADD_PROJECT_SUCCESS";
const REQUEST_ADD_PROJECT_FAILED = "REQUEST_ADD_PROJECT_FAILED";
//** Actions Types For Edit Project
const REQUEST_EDIT_PROJECT = "REQUEST_EDIT_PROJECT";
const REQUEST_EDIT_PROJECT_SUCCESS = "REQUEST_EDIT_PROJECT_SUCCESS";
const REQUEST_EDIT_PROJECT_FAILED = "REQUEST_EDIT_PROJECT_FAILED";

// ** Initial State
const initialState = {
  projectsList: [],
  projectsPagesCount: "",
  isLoadingGetProjectsList: false,
  isFailedGetProjectsList: false,
  // ** State For Project Item
  projectItem: {},
  isLoadingProjectItem: false,
  isfailedProjectItem: false,
  // ** Add Project
  isloadingAddProject: false,
  successAddProject: false,
  isfailedAddProject: false,
  //** Edit Project
  isloadingEditProject: false,
  successEditProject: false,
  isfailedEditProject: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Projects List
    case REQUEST_PROJECTS:
      return {
        ...state,
        isLoadingGetProjectsList: true,
      };
    case REQUEST_PROJECTS_SUCCESS:
      return {
        ...state,
        projectsList: action.projectsList,
        projectsPagesCount: action.projectsPagesCount,
        isLoadingGetProjectsList: false,
        isFailedGetProjectsList: false,
      };
    case REQUEST_PROJECTS_FAILED:
      return {
        ...state,
        isLoadingGetProjectsList: false,
        isFailedGetProjectsList: true,
      };
    // ** Reducers for Project Item
    case REQUEST_PROJECT_ITEM:
      return {
        ...state,
        isLoadingProjectItem: true,
      };
    case REQUEST_PROJECT_ITEM_SUCCESS:
      return {
        ...state,
        projectItem: action.projectItem,
        isLoadingProjectItem: false,
        isfailedProjectItem: false,
      };
    case REQUEST_PROJECT_ITEM_FAILED:
      return {
        ...state,
        isLoadingProjectItem: false,
        isfailedProjectItem: true,
      };
    // ** Reducers for Add Project
    case REQUEST_ADD_PROJECT:
      return {
        ...state,
        isloadingAddProject: true,
      };
    case REQUEST_ADD_PROJECT_SUCCESS:
      return {
        ...state,
        isloadingAddProject: false,
        successAddProject: true,
        isfailedAddProject: false,
      };
    case REQUEST_ADD_PROJECT_FAILED:
      return {
        ...state,
        isloadingAddProject: false,
        successAddProject: false,
        isfailedAddProject: true,
      };
    //**  Reducers for Edit Project
    case REQUEST_EDIT_PROJECT:
      return {
        ...state,
        isloadingEditProject: true,
      };
    case REQUEST_EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        isloadingEditProject: false,
        successEditProject: true,
        isfailedEditProject: false,
      };
    case REQUEST_EDIT_PROJECT_FAILED:
      return {
        ...state,
        isloadingEditProject: false,
        successEditProject: false,
        isfailedEditProject: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Projects
export const requestGetProjectsList = () => {
  return {
    type: REQUEST_PROJECTS,
  };
};
export const requestGetProjectsListSuccess = (
  projectsList,
  projectsPagesCount
) => {
  return {
    type: REQUEST_PROJECTS_SUCCESS,
    projectsList: projectsList,
    projectsPagesCount: projectsPagesCount,
  };
};
export const requestGetProjectsListFailed = (message) => {
  return {
    type: REQUEST_PROJECTS_FAILED,
  };
};

// ** Actions for Project Item
export const requestGetProjectItem = () => {
  return {
    type: REQUEST_PROJECT_ITEM,
  };
};
export const requestGetProjectItemSuccess = (projectItem) => {
  return {
    type: REQUEST_PROJECT_ITEM_SUCCESS,
    projectItem: projectItem,
  };
};
export const requestGetProjectItemFailed = () => {
  return {
    type: REQUEST_PROJECT_ITEM_FAILED,
  };
};

//** Actions For Add Project
export const requestAddProject = () => {
  return {
    type: REQUEST_ADD_PROJECT,
  };
};
export const requestAddProjectSuccess = (message) => {
  return {
    type: REQUEST_ADD_PROJECT_SUCCESS,
    message: message,
  };
};
export const requestAddProjectFaield = (message) => {
  return {
    type: REQUEST_ADD_PROJECT_FAILED,
    message: message,
  };
};

//** Actions For Edit Project
export const requestEditProject = () => {
  return {
    type: REQUEST_EDIT_PROJECT,
  };
};
export const requestEditProjectSuccess = (message) => {
  return {
    type: REQUEST_EDIT_PROJECT_SUCCESS,
    message: message,
  };
};
export const requestEditProjectFaield = (message) => {
  return {
    type: REQUEST_EDIT_PROJECT_FAILED,
    message: message,
  };
};
