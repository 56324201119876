// ** Actions Types for Country
const REQUEST_COUNTRIES = "REQUEST_COUNTRIES";
const REQUEST_COUNTRIES_SUCCESS = "REQUEST_COUNTRIES_SUCCESS";
const REQUEST_COUNTRIES_FAILED = "REQUEST_COUNTRIES_FAILED";
// ** Actions Types for Country Item
const REQUEST_COUNTRIES_ITEM = "REQUEST_COUNTRIES_ITEM";
const REQUEST_COUNTRIES_ITEM_SUCCESS = "REQUEST_COUNTRIES_ITEM_SUCCESS";
const REQUEST_COUNTRIES_ITEM_FAILED = "REQUEST_COUNTRIES_ITEM_FAILED";
//** Actions Types For Add Country
const REQUEST_ADD_COUNTRY = "REQUEST_ADD_COUNTRY";
const REQUEST_ADD_COUNTRY_SUCCESS = "REQUEST_ADD_COUNTRY_SUCCESS";
const REQUEST_ADD_COUNTRY_FAILED = "REQUEST_ADD_COUNTRY_FAILED";
//** Actions Types For Edit Country
const REQUEST_EDIT_COUNTRY = "REQUEST_EDIT_COUNTRY";
const REQUEST_EDIT_COUNTRY_SUCCESS = "REQUEST_EDIT_COUNTRY_SUCCESS";
const REQUEST_EDIT_COUNTRY_FAILED = "REQUEST_EDIT_COUNTRY_FAILED";

// ** Initial State
const initialState = {
  countriesList: [],
  countriesTotalNum: "",
  isLoadingGetCountriesList: false,
  isFailedGetCountriesList: false,
  // ** State For Country Item
  countrytItem: {},
  isLoadingCountrytItem: false,
  isFailedCountrytItem: false,
  // ** Add Country
  isLoadingAddCountry: false,
  successAddCountry: false,
  isFailedAddCountry: false,
  //** Edit Country
  isLoadingEditCountry: false,
  successEditCountry: false,
  isFailedEditCountry: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Country List
    case REQUEST_COUNTRIES:
      return {
        ...state,
        isLoadingGetCountriesList: true,
      };
    case REQUEST_COUNTRIES_SUCCESS:
      return {
        ...state,
        countriesList: action.countriesList,
        countriesTotalNum: action.countriesTotalNum,
        isLoadingGetCountriesList: false,
        isFailedGetCountriesList: false,
      };
    case REQUEST_COUNTRIES_FAILED:
      return {
        ...state,
        isLoadingGetCountriesList: false,
        isFailedGetCountriesList: true,
      };
    // ** Reducers for Country Item
    case REQUEST_COUNTRIES_ITEM:
      return {
        ...state,
        isLoadingCountrytItem: true,
      };
    case REQUEST_COUNTRIES_ITEM_SUCCESS:
      return {
        ...state,
        countrytItem: action.countrytItem,
        isLoadingCountrytItem: false,
        isFailedCountrytItem: false,
      };
    case REQUEST_COUNTRIES_ITEM_FAILED:
      return {
        ...state,
        isLoadingCountrytItem: false,
        isFailedCountrytItem: true,
      };
    // ** Reducers for Add Country
    case REQUEST_ADD_COUNTRY:
      return {
        ...state,
        isLoadingAddCountry: true,
      };
    case REQUEST_ADD_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoadingAddCountry: false,
        successAddCountry: true,
        isFailedAddCountry: false,
      };
    case REQUEST_ADD_COUNTRY_FAILED:
      return {
        ...state,
        isLoadingAddCountry: false,
        successAddCountry: false,
        isFailedAddCountry: true,
      };
    //**  Reducers for Edit Country
    case REQUEST_EDIT_COUNTRY:
      return {
        ...state,
        isLoadingEditCountry: true,
      };
    case REQUEST_EDIT_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoadingEditCountry: false,
        successEditCountry: true,
        isFailedEditCountry: false,
      };
    case REQUEST_EDIT_COUNTRY_FAILED:
      return {
        ...state,
        isLoadingEditCountry: false,
        successEditCountry: false,
        isFailedEditCountry: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Country List
export const requestGetCountriesList = () => {
  return {
    type: REQUEST_COUNTRIES,
  };
};
export const requestGetCountriesListSuccess = (
  countriesList,
  countriesTotalNum
) => {
  return {
    type: REQUEST_COUNTRIES_SUCCESS,
    countriesList: countriesList,
    countriesTotalNum: countriesTotalNum,
  };
};
export const requestGetCountriesListFailed = (message) => {
  return {
    type: REQUEST_COUNTRIES_FAILED,
  };
};

// ** Actions for Country Item
export const requestGetCountryItem = () => {
  return {
    type: REQUEST_COUNTRIES_ITEM,
  };
};
export const requestGetCountryItemSuccess = (countrytItem) => {
  return {
    type: REQUEST_COUNTRIES_ITEM_SUCCESS,
    countrytItem: countrytItem,
  };
};
export const requestGetCountryItemFailed = () => {
  return {
    type: REQUEST_COUNTRIES_ITEM_FAILED,
  };
};
//** Actions For Add Country
export const requestAddCountry = () => {
  return {
    type: REQUEST_ADD_COUNTRY,
  };
};
export const requestAddCountrySuccess = (message) => {
  return {
    type: REQUEST_ADD_COUNTRY_SUCCESS,
    message: message,
  };
};
export const requestAddCountryFaield = (message) => {
  return {
    type: REQUEST_ADD_COUNTRY_FAILED,
    message: message,
  };
};

//** Actions For Edit Country
export const requestEditCountry = () => {
  return {
    type: REQUEST_EDIT_COUNTRY,
  };
};
export const requestEditCountrySuccess = (message) => {
  return {
    type: REQUEST_EDIT_COUNTRY_SUCCESS,
    message: message,
  };
};
export const requestEditCountryFaield = (message) => {
  return {
    type: REQUEST_EDIT_COUNTRY_FAILED,
    message: message,
  };
};
