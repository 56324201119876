// ** Actions Types for Projects Life Cycle List
const REQUEST_PROJECTS_LIFE_CYCLES = "REQUEST_PROJECTS_LIFE_CYCLES";
const REQUEST_PROJECTS_LIFE_CYCLES_SUCCESS =
  "REQUEST_PROJECTS_LIFE_CYCLES_SUCCESS";
const REQUEST_PROJECTS_LIFE_CYCLES_FAILED =
  "REQUEST_PROJECTS_LIFE_CYCLES_FAILED";
// ** Actions Types for Project Life Cycle  Item
const REQUEST_PROJECT_LIFE_CYCLES_ITEM = "REQUEST_PROJECT_LIFE_CYCLES_ITEM";
const REQUEST_PROJECT_LIFE_CYCLES_ITEM_SUCCESS =
  "REQUEST_PROJECT_LIFE_CYCLES_ITEM_SUCCESS";
const REQUEST_PROJECT_LIFE_CYCLES_ITEM_FAILED =
  "REQUEST_PROJECT_LIFE_CYCLES_ITEM_FAILED";
//** Actions Types For Add Project Life Cycle
const REQUEST_ADD_PROJECT_LIFE_CYCLES = "REQUEST_ADD_PROJECT_LIFE_CYCLES";
const REQUEST_ADD_PROJECT_LIFE_CYCLES_SUCCESS =
  "REQUEST_ADD_PROJECT_LIFE_CYCLES_SUCCESS";
const REQUEST_ADD_PROJECT_LIFE_CYCLES_FAILED =
  "REQUEST_ADD_PROJECT_LIFE_CYCLES_FAILED";
//** Actions Types For Edit Project Life Cycle
const REQUEST_EDIT_PROJECT_LIFE_CYCLES = "REQUEST_EDIT_PROJECT_LIFE_CYCLES";
const REQUEST_EDIT_PROJECT_LIFE_CYCLES_SUCCESS =
  "REQUEST_EDIT_PROJECT_LIFE_CYCLES_SUCCESS";
const REQUEST_EDIT_PROJECT_LIFE_CYCLES_FAILED =
  "REQUEST_EDIT_PROJECT_LIFE_CYCLES_FAILED";

// ** Initial State
const initialState = {
  projectsLifeCyclesList: [],
  projectsLifeCyclesPagesCount: "",
  isLoadingGetProjectsLifeCyclesList: false,
  isFailedGetProjectsLifeCyclesList: false,
  // ** State For Project Life Cycle Item
  projectLifeCyclesItem: {},
  isLoadingProjectLifeCyclesItem: false,
  isfailedProjectLifeCyclesItem: false,
  // ** Add Project Life Cycle
  isloadingAddProjectLifeCycles: false,
  successAddProjectLifeCycles: false,
  isfailedAddProjectLifeCycles: false,
  //** Edit Project Life Cycle
  isloadingEditProjectLifeCycles: false,
  successEditProjectLifeCycles: false,
  isfailedEditProjectLifeCycles: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Projects Life Cycle List
    case REQUEST_PROJECTS_LIFE_CYCLES:
      return {
        ...state,
        isLoadingGetProjectsLifeCyclesList: true,
      };
    case REQUEST_PROJECTS_LIFE_CYCLES_SUCCESS:
      return {
        ...state,
        projectsLifeCyclesList: action.projectsLifeCyclesList,
        projectsLifeCyclesPagesCount: action.projectsLifeCyclesPagesCount,
        isLoadingGetProjectsLifeCyclesList: false,
        isFailedGetProjectsLifeCyclesList: false,
      };
    case REQUEST_PROJECTS_LIFE_CYCLES_FAILED:
      return {
        ...state,
        isLoadingGetProjectsLifeCyclesList: false,
        isFailedGetProjectsLifeCyclesList: true,
      };
    // ** Reducers for Project Life Cycle Item
    case REQUEST_PROJECT_LIFE_CYCLES_ITEM:
      return {
        ...state,
        isLoadingProjectLifeCyclesItem: true,
      };
    case REQUEST_PROJECT_LIFE_CYCLES_ITEM_SUCCESS:
      return {
        ...state,
        projectLifeCyclesItem: action.projectLifeCyclesItem,
        isLoadingProjectLifeCyclesItem: false,
        isfailedProjectLifeCyclesItem: false,
      };
    case REQUEST_PROJECT_LIFE_CYCLES_ITEM_FAILED:
      return {
        ...state,
        isLoadingProjectLifeCyclesItem: false,
        isfailedProjectLifeCyclesItem: true,
      };
    // ** Reducers for Add Project Life Cycle
    case REQUEST_ADD_PROJECT_LIFE_CYCLES:
      return {
        ...state,
        isloadingAddProjectLifeCycles: true,
      };
    case REQUEST_ADD_PROJECT_LIFE_CYCLES_SUCCESS:
      return {
        ...state,
        isloadingAddProjectLifeCycles: false,
        successAddProjectLifeCycles: true,
        isfailedAddProjectLifeCycles: false,
      };
    case REQUEST_ADD_PROJECT_LIFE_CYCLES_FAILED:
      return {
        ...state,
        isloadingAddProjectLifeCycles: false,
        successAddProjectLifeCycles: false,
        isfailedAddProjectLifeCycles: true,
      };
    //**  Reducers for Edit Project Life Cycle
    case REQUEST_EDIT_PROJECT_LIFE_CYCLES:
      return {
        ...state,
        isloadingEditProjectLifeCycles: true,
      };
    case REQUEST_EDIT_PROJECT_LIFE_CYCLES_SUCCESS:
      return {
        ...state,
        isloadingEditProjectLifeCycles: false,
        successEditProjectLifeCycles: true,
        isfailedEditProjectLifeCycles: false,
      };
    case REQUEST_EDIT_PROJECT_LIFE_CYCLES_FAILED:
      return {
        ...state,
        isloadingEditProjectLifeCycles: false,
        successEditProjectLifeCycles: false,
        isfailedEditProjectLifeCycles: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Projects Life Cycle
export const requestGetProjectsLifeCyclesList = () => {
  return {
    type: REQUEST_PROJECTS_LIFE_CYCLES,
  };
};
export const requestGetProjectsLifeCyclesListSuccess = (
  projectsLifeCyclesList,
  projectsLifeCyclesPagesCount
) => {
  return {
    type: REQUEST_PROJECTS_LIFE_CYCLES_SUCCESS,
    projectsLifeCyclesList: projectsLifeCyclesList,
    projectsLifeCyclesPagesCount: projectsLifeCyclesPagesCount,
  };
};
export const requestGetProjectsLifeCyclesListFailed = (message) => {
  return {
    type: REQUEST_PROJECTS_LIFE_CYCLES_FAILED,
  };
};

// ** Actions for Project Life Cycle Item
export const requestGetProjectLifeCyclesItem = () => {
  return {
    type: REQUEST_PROJECT_LIFE_CYCLES_ITEM,
  };
};
export const requestGetProjectLifeCyclesItemSuccess = (
  projectLifeCyclesItem
) => {
  return {
    type: REQUEST_PROJECT_LIFE_CYCLES_ITEM_SUCCESS,
    projectLifeCyclesItem: projectLifeCyclesItem,
  };
};
export const requestGetProjectLifeCyclesItemFailed = () => {
  return {
    type: REQUEST_PROJECT_LIFE_CYCLES_ITEM_FAILED,
  };
};

//** Actions For Add Project Life Cycle
export const requestAddProjectLifeCycles = () => {
  return {
    type: REQUEST_ADD_PROJECT_LIFE_CYCLES,
  };
};
export const requestAddProjectLifeCyclesSuccess = (message) => {
  return {
    type: REQUEST_ADD_PROJECT_LIFE_CYCLES_SUCCESS,
    message: message,
  };
};
export const requestAddProjectLifeCyclesFaield = (message) => {
  return {
    type: REQUEST_ADD_PROJECT_LIFE_CYCLES_FAILED,
    message: message,
  };
};

//** Actions For Edit Project Life Cycle
export const requestEditProjectLifeCycles = () => {
  return {
    type: REQUEST_EDIT_PROJECT_LIFE_CYCLES,
  };
};
export const requestEditProjectLifeCyclesSuccess = (message) => {
  return {
    type: REQUEST_EDIT_PROJECT_LIFE_CYCLES_SUCCESS,
    message: message,
  };
};
export const requestEditProjectLifeCyclesFaield = (message) => {
  return {
    type: REQUEST_EDIT_PROJECT_LIFE_CYCLES_FAILED,
    message: message,
  };
};
