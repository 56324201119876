// Actions Types for Statistics
const REQUEST_STATISTICS = "REQUEST_STATISTICS";
const REQUEST_STATISTICS_SUCCESS = "REQUEST_STATISTICS_SUCCESS";
const REQUEST_STATISTICS_FAILED = "REQUEST_STATISTICS_FAILED";
// Actions Types for Statistics Item
const REQUEST_STATISTICS_ITEM = "REQUEST_STATISTICS_ITEM";
const REQUEST_STATISTICS_ITEM_SUCCESS = "REQUEST_STATISTICS_ITEM_SUCCESS";
const REQUEST_STATISTICS_ITEM_FAILED = "REQUEST_STATISTICS_ITEM_FAILED";
// Actions Types For Add Statistic
const REQUEST_ADD_STATISTIC = "REQUEST_ADD_STATISTIC";
const REQUEST_ADD_STATISTIC_SUCCESS = "REQUEST_ADD_STATISTIC_SUCCESS";
const REQUEST_ADD_STATISTIC_FAILED = "REQUEST_ADD_STATISTIC_FAILED";
// Actions Types For Edit Statistic
const REQUEST_EDIT_STATISTIC = "REQUEST_EDIT_STATISTIC";
const REQUEST_EDIT_STATISTIC_SUCCESS = "REQUEST_EDIT_STATISTIC_SUCCESS";
const REQUEST_EDIT_STATISTIC_FAILED = "REQUEST_EDIT_STATISTIC_FAILED";

// Initial State
const initialState = {
  statisticsList: [],
  statisticsTotalNum: "",
  isLoadingGetStatisticsList: false,
  isFailedGetStatisticsList: false,
  // State For Statistics Item
  statisticsItem: {},
  isLoadingStatisticsItem: false,
  isFailedStatisticsItem: false,
  // Add Statistic
  isLoadingAddStatistic: false,
  successAddStatistic: false,
  isFailedAddStatistic: false,
  // Edit Statistic
  isLoadingEditStatistic: false,
  successEditStatistic: false,
  isFailedEditStatistics: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    //  Reducers for Statistic List
    case REQUEST_STATISTICS:
      return {
        ...state,
        isLoadingGetStatisticsList: true,
      };
    case REQUEST_STATISTICS_SUCCESS:
      return {
        ...state,
        statisticsList: action.statisticsList,
        statisticsTotalNum: action.statisticsTotalNum,
        isLoadingGetStatisticsList: false,
        isFailedGetStatisticsList: false,
      };
    case REQUEST_STATISTICS_FAILED:
      return {
        ...state,
        isLoadingGetStatisticsList: false,
        isFailedGetStatisticsList: true,
      };
    //  Reducers for Statistic Item
    case REQUEST_STATISTICS_ITEM:
      return {
        ...state,
        isLoadingStatisticsItem: true,
      };
    case REQUEST_STATISTICS_ITEM_SUCCESS:
      return {
        ...state,
        statisticsItem: action.statisticsItem,
        isLoadingStatisticsItem: false,
        isFailedStatisticsItem: false,
      };
    case REQUEST_STATISTICS_ITEM_FAILED:
      return {
        ...state,
        isLoadingStatisticsItem: false,
        isFailedStatisticsItem: true,
      };
    //  Reducers for Add Statisti
    case REQUEST_ADD_STATISTIC:
      return {
        ...state,
        isLoadingAddStatistic: true,
      };
    case REQUEST_ADD_STATISTIC_SUCCESS:
      return {
        ...state,
        isLoadingAddStatistic: false,
        successAddStatistic: true,
        isFailedAddStatistic: false,
      };
    case REQUEST_ADD_STATISTIC_FAILED:
      return {
        ...state,
        isLoadingAddStatistic: false,
        successAddStatistic: false,
        isFailedAddStatistic: true,
      };
    // Reducers for Edit Statisti
    case REQUEST_EDIT_STATISTIC:
      return {
        ...state,
        isLoadingEditStatistic: true,
      };
    case REQUEST_EDIT_STATISTIC_SUCCESS:
      return {
        ...state,
        isLoadingEditStatistic: false,
        successEditStatistic: true,
        isFailedEditStatistics: false,
      };
    case REQUEST_EDIT_STATISTIC_FAILED:
      return {
        ...state,
        isLoadingEditStatistic: false,
        successEditStatistic: false,
        isFailedEditStatistics: true,
      };
    default:
      return state;
  }
};

// Actions for All Statistic
export const requestGetStatisticsList = () => {
  return {
    type: REQUEST_STATISTICS,
  };
};
export const requestGetStatisticsListSuccess = (
  statisticsList,
  statisticsTotalNum
) => {
  return {
    type: REQUEST_STATISTICS_SUCCESS,
    statisticsList: statisticsList,
    statisticsTotalNum: statisticsTotalNum,
  };
};
export const requestGetStatisticsListFailed = (message) => {
  return {
    type: REQUEST_STATISTICS_FAILED,
  };
};

// Actions for Statistic Item
export const requestGetStatisticItem = () => {
  return {
    type: REQUEST_STATISTICS_ITEM,
  };
};
export const requestGetStatisticItemSuccess = (statisticsItem) => {
  return {
    type: REQUEST_STATISTICS_ITEM_SUCCESS,
    statisticsItem: statisticsItem,
  };
};
export const requestGetStatisticItemFailed = () => {
  return {
    type: REQUEST_STATISTICS_ITEM_FAILED,
  };
};

// Actions For Add Statistic
export const requestAddStatistic = () => {
  return {
    type: REQUEST_ADD_STATISTIC,
  };
};
export const requestAddStatisticSuccess = (message) => {
  return {
    type: REQUEST_ADD_STATISTIC_SUCCESS,
    message: message,
  };
};
export const requestAddStatisticFaield = (message) => {
  return {
    type: REQUEST_ADD_STATISTIC_FAILED,
    message: message,
  };
};

// Actions For Edit Statistic
export const requestEditStatistic = () => {
  return {
    type: REQUEST_EDIT_STATISTIC,
  };
};
export const requestEditStatisticSuccess = (message) => {
  return {
    type: REQUEST_EDIT_STATISTIC_SUCCESS,
    message: message,
  };
};
export const requestEditStatisticFaield = (message) => {
  return {
    type: REQUEST_EDIT_STATISTIC_FAILED,
    message: message,
  };
};
