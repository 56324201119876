// ** Actions Types for Products
const REQUEST_PRODUCTS = "REQUEST_PRODUCTS";
const REQUEST_PRODUCTS_SUCCESS = "REQUEST_PRODUCTS_SUCCESS";
const REQUEST_PRODUCTS_FAILED = "REQUEST_PRODUCTS_FAILED";
// ** Actions Types for Product Item
const REQUEST_PRODUCT_ITEM = "REQUEST_PRODUCT_ITEM";
const REQUEST_PRODUCT_ITEM_SUCCESS = "REQUEST_PRODUCT_ITEM_SUCCESS";
const REQUEST_PRODUCT_ITEM_FAILED = "REQUEST_PRODUCT_ITEM_FAILED";
//** Actions Types For Add Product
const REQUEST_ADD_PRODUCT = "REQUEST_ADD_PRODUCT";
const REQUEST_ADD_PRODUCT_SUCCESS = "REQUEST_ADD_PRODUCT_SUCCESS";
const REQUEST_ADD_PRODUCT_FAILED = "REQUEST_ADD_PRODUCT_FAILED";
//** Actions Types For Edit Product
const REQUEST_EDIT_PRODUCT = "REQUEST_EDIT_PRODUCT";
const REQUEST_EDIT_PRODUCT_SUCCESS = "REQUEST_EDIT_PRODUCT_SUCCESS";
const REQUEST_EDIT_PRODUCT_FAILED = "REQUEST_EDIT_PRODUCT_FAILED";

// ** Initial State
const initialState = {
  productsList: [],
  productsPagesCount: "",
  isLoadingGetProductsList: false,
  isFailedGetProductsList: false,
  // ** State For Product Item
  productItem: {},
  isLoadingProductItem: false,
  isfailedProductItem: false,
  // ** Add Product
  isloadingAddProduct: false,
  successAddProduct: false,
  isfailedAddProduct: false,
  //** Edit Product
  isloadingEditProduct: false,
  successEditProduct: false,
  isfailedEditProduct: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Products List
    case REQUEST_PRODUCTS:
      return {
        ...state,
        isLoadingGetProductsList: true,
      };
    case REQUEST_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsList: action.productsList,
        productsPagesCount: action.productsPagesCount,
        isLoadingGetProductsList: false,
        isFailedGetProductsList: false,
      };
    case REQUEST_PRODUCTS_FAILED:
      return {
        ...state,
        isLoadingGetProductsList: false,
        isFailedGetProductsList: true,
      };
    // ** Reducers for Product Item
    case REQUEST_PRODUCT_ITEM:
      return {
        ...state,
        isLoadingProductItem: true,
      };
    case REQUEST_PRODUCT_ITEM_SUCCESS:
      return {
        ...state,
        productItem: action.productItem,
        isLoadingProductItem: false,
        isfailedProductItem: false,
      };
    case REQUEST_PRODUCT_ITEM_FAILED:
      return {
        ...state,
        isLoadingProductItem: false,
        isfailedProductItem: true,
      };
    // ** Reducers for Add Product
    case REQUEST_ADD_PRODUCT:
      return {
        ...state,
        isloadingAddProduct: true,
      };
    case REQUEST_ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        isloadingAddProduct: false,
        successAddProduct: true,
        isfailedAddProduct: false,
      };
    case REQUEST_ADD_PRODUCT_FAILED:
      return {
        ...state,
        isloadingAddProduct: false,
        successAddProduct: false,
        isfailedAddProduct: true,
      };
    //**  Reducers for Edit Product
    case REQUEST_EDIT_PRODUCT:
      return {
        ...state,
        isloadingEditProduct: true,
      };
    case REQUEST_EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        isloadingEditProduct: false,
        successEditProduct: true,
        isfailedEditProduct: false,
      };
    case REQUEST_EDIT_PRODUCT_FAILED:
      return {
        ...state,
        isloadingEditProduct: false,
        successEditProduct: false,
        isfailedEditProduct: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Products
export const requestGetProductsList = () => {
  return {
    type: REQUEST_PRODUCTS,
  };
};
export const requestGetProductsListSuccess = (
  productsList,
  productsPagesCount
) => {
  return {
    type: REQUEST_PRODUCTS_SUCCESS,
    productsList: productsList,
    productsPagesCount: productsPagesCount,
  };
};
export const requestGetProductsListFailed = (message) => {
  return {
    type: REQUEST_PRODUCTS_FAILED,
  };
};

// ** Actions for Product Item
export const requestGetProductItem = () => {
  return {
    type: REQUEST_PRODUCT_ITEM,
  };
};
export const requestGetProductItemSuccess = (productItem) => {
  return {
    type: REQUEST_PRODUCT_ITEM_SUCCESS,
    productItem: productItem,
  };
};
export const requestGetProductItemFailed = () => {
  return {
    type: REQUEST_PRODUCT_ITEM_FAILED,
  };
};

//** Actions For Add Product
export const requestAddProduct = () => {
  return {
    type: REQUEST_ADD_PRODUCT,
  };
};
export const requestAddProductSuccess = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_SUCCESS,
    message: message,
  };
};
export const requestAddProductFaield = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_FAILED,
    message: message,
  };
};

//** Actions For Edit Product
export const requestEditProduct = () => {
  return {
    type: REQUEST_EDIT_PRODUCT,
  };
};
export const requestEditProductSuccess = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_SUCCESS,
    message: message,
  };
};
export const requestEditProductFaield = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_FAILED,
    message: message,
  };
};
