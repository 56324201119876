// ** Actions Types for Testimonials
const REQUEST_TESTIMONIALS = "REQUEST_TESTIMONIALS";
const REQUEST_TESTIMONIALS_SUCCESS = "REQUEST_TESTIMONIALS_SUCCESS";
const REQUEST_TESTIMONIALS_FAILED = "REQUEST_TESTIMONIALS_FAILED";
// ** Actions Types for Testimonial Item
const REQUEST_TESTIMONIAL_ITEM = "REQUEST_TESTIMONIAL_ITEM";
const REQUEST_TESTIMONIAL_ITEM_SUCCESS = "REQUEST_TESTIMONIAL_ITEM_SUCCESS";
const REQUEST_TESTIMONIAL_ITEM_FAILED = "REQUEST_TESTIMONIAL_ITEM_FAILED";
//** Actions Types For Add Testimonial
const REQUEST_ADD_TESTIMONIAL = "REQUEST_ADD_TESTIMONIAL";
const REQUEST_ADD_TESTIMONIAL_SUCCESS = "REQUEST_ADD_TESTIMONIAL_SUCCESS";
const REQUEST_ADD_TESTIMONIAL_FAILED = "REQUEST_ADD_TESTIMONIAL_FAILED";
//** Actions Types For Edit Testimonial
const REQUEST_EDIT_TESTIMONIAL = "REQUEST_EDIT_TESTIMONIAL";
const REQUEST_EDIT_TESTIMONIAL_SUCCESS = "REQUEST_EDIT_TESTIMONIAL_SUCCESS";
const REQUEST_EDIT_TESTIMONIAL_FAILED = "REQUEST_EDIT_TESTIMONIAL_FAILED";

// ** Initial State
const initialState = {
  testimonialsList: [],
  testimonialsTotalNum: "",
  isLoadingGetTestimonialsList: false,
  isFailedGetTestimonialsList: false,
  // ** State For Testimonial Item
  testimonialItem: {},
  isLoadingTestimonialItem: false,
  isfailedTestimonialItem: false,
  // ** Add Testimonial
  isloadingAddTestimonial: false,
  successAddTestimonial: false,
  isfailedAddTestimonial: false,
  //** Edit Testimonial
  isloadingEditTestimonial: false,
  successEditTestimonial: false,
  isfailedEditTestimonial: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Testimonials List
    case REQUEST_TESTIMONIALS:
      return {
        ...state,
        isLoadingGetTestimonialsList: true,
      };
    case REQUEST_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        testimonialsList: action.testimonialsList,
        testimonialsTotalNum: action.testimonialsTotalNum,
        isLoadingGetTestimonialsList: false,
        isFailedGetTestimonialsList: false,
      };
    case REQUEST_TESTIMONIALS_FAILED:
      return {
        ...state,
        isLoadingGetTestimonialsList: false,
        isFailedGetTestimonialsList: true,
      };
    // ** Reducers for Testimonial Item
    case REQUEST_TESTIMONIAL_ITEM:
      return {
        ...state,
        isLoadingTestimonialItem: true,
      };
    case REQUEST_TESTIMONIAL_ITEM_SUCCESS:
      return {
        ...state,
        testimonialItem: action.testimonialItem,
        isLoadingTestimonialItem: false,
        isfailedTestimonialItem: false,
      };
    case REQUEST_TESTIMONIAL_ITEM_FAILED:
      return {
        ...state,
        isLoadingTestimonialItem: false,
        isfailedTestimonialItem: true,
      };
    // ** Reducers for Add Testimonial
    case REQUEST_ADD_TESTIMONIAL:
      return {
        ...state,
        isloadingAddTestimonial: true,
      };
    case REQUEST_ADD_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        isloadingAddTestimonial: false,
        successAddTestimonial: true,
        isfailedAddTestimonial: false,
      };
    case REQUEST_ADD_TESTIMONIAL_FAILED:
      return {
        ...state,
        isloadingAddTestimonial: false,
        successAddTestimonial: false,
        isfailedAddTestimonial: true,
      };
    //**  Reducers for Edit Testimonial
    case REQUEST_EDIT_TESTIMONIAL:
      return {
        ...state,
        isloadingEditTestimonial: true,
      };
    case REQUEST_EDIT_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        isloadingEditTestimonial: false,
        successEditTestimonial: true,
        isfailedEditTestimonial: false,
      };
    case REQUEST_EDIT_TESTIMONIAL_FAILED:
      return {
        ...state,
        isloadingEditTestimonial: false,
        successEditTestimonial: false,
        isfailedEditTestimonial: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Testimonials
export const requestGetTestimonialsList = () => {
  return {
    type: REQUEST_TESTIMONIALS,
  };
};
export const requestGetTestimonialsListSuccess = (
  testimonialsList,
  testimonialsTotalNum
) => {
  return {
    type: REQUEST_TESTIMONIALS_SUCCESS,
    testimonialsList: testimonialsList,
    testimonialsTotalNum: testimonialsTotalNum,
  };
};
export const requestGetTestimonialsListFailed = (message) => {
  return {
    type: REQUEST_TESTIMONIALS_FAILED,
  };
};

// ** Actions for Testimonial Item
export const requestGetTestimonialItem = () => {
  return {
    type: REQUEST_TESTIMONIAL_ITEM,
  };
};
export const requestGetTestimonialItemSuccess = (testimonialItem) => {
  return {
    type: REQUEST_TESTIMONIAL_ITEM_SUCCESS,
    testimonialItem: testimonialItem,
  };
};
export const requestGetTestimonialItemFailed = () => {
  return {
    type: REQUEST_TESTIMONIAL_ITEM_FAILED,
  };
};

//** Actions For Add Testimonial
export const requestAddTestimonial = () => {
  return {
    type: REQUEST_ADD_TESTIMONIAL,
  };
};
export const requestAddTestimonialSuccess = (message) => {
  return {
    type: REQUEST_ADD_TESTIMONIAL_SUCCESS,
    message: message,
  };
};
export const requestAddTestimonialFaield = (message) => {
  return {
    type: REQUEST_ADD_TESTIMONIAL_FAILED,
    message: message,
  };
};

//** Actions For Edit Testimonial
export const requestEditTestimonial = () => {
  return {
    type: REQUEST_EDIT_TESTIMONIAL,
  };
};
export const requestEditTestimonialSuccess = (message) => {
  return {
    type: REQUEST_EDIT_TESTIMONIAL_SUCCESS,
    message: message,
  };
};
export const requestEditTestimonialFaield = (message) => {
  return {
    type: REQUEST_EDIT_TESTIMONIAL_FAILED,
    message: message,
  };
};
