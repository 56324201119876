// ** Actions Types for Clients
const REQUEST_CLIENTS = "REQUEST_CLIENTS";
const REQUEST_CLIENTS_SUCCESS = "REQUEST_CLIENTS_SUCCESS";
const REQUEST_CLIENTS_FAILED = "REQUEST_CLIENTS_FAILED";
// ** Actions Types for Clients Item
const REQUEST_CLIENTS_ITEM = "REQUEST_CLIENTS_ITEM";
const REQUEST_CLIENTS_ITEM_SUCCESS = "REQUEST_CLIENTS_ITEM_SUCCESS";
const REQUEST_CLIENTS_ITEM_FAILED = "REQUEST_CLIENTS_ITEM_FAILED";
//** Actions Types For Add Client
const REQUEST_ADD_CLIENT = "REQUEST_ADD_CLIENT";
const REQUEST_ADD_CLIENT_SUCCESS = "REQUEST_ADD_CLIENT_SUCCESS";
const REQUEST_ADD_CLIENT_FAILED = "REQUEST_ADD_CLIENT_FAILED";
//** Actions Types For Edit Client
const REQUEST_EDIT_CLIENT = "REQUEST_EDIT_CLIENT";
const REQUEST_EDIT_CLIENT_SUCCESS = "REQUEST_EDIT_CLIENT_SUCCESS";
const REQUEST_EDIT_CLIENT_FAILED = "REQUEST_EDIT_CLIENT_FAILED";

// ** Initial State
const initialState = {
  clientsList: [],
  clientsTotalNum: "",
  isLoadingGetClientsList: false,
  isFailedGetClientsList: false,
  // ** State For Clients Item
  clientItem: {},
  isLoadingClientItem: false,
  isfailedClientItem: false,
  // ** Add Client
  isloadingAddClient: false,
  successAddClient: false,
  isfailedAddClient: false,
  //** Edit Client
  isloadingEditClient: false,
  successEditClient: false,
  isfailedEditClient: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Clients List
    case REQUEST_CLIENTS:
      return {
        ...state,
        isLoadingGetClientsList: true,
      };
    case REQUEST_CLIENTS_SUCCESS:
      return {
        ...state,
        clientsList: action.clientsList,
        clientsTotalNum: action.clientsTotalNum,
        isLoadingGetClientsList: false,
        isFailedGetClientsList: false,
      };
    case REQUEST_CLIENTS_FAILED:
      return {
        ...state,
        isLoadingGetClientsList: false,
        isFailedGetClientsList: true,
      };
    // ** Reducers for Clients Item
    case REQUEST_CLIENTS_ITEM:
      return {
        ...state,
        isLoadingClientItem: true,
      };
    case REQUEST_CLIENTS_ITEM_SUCCESS:
      return {
        ...state,
        clientItem: action.clientItem,
        isLoadingClientItem: false,
        isfailedClientItem: false,
      };
    case REQUEST_CLIENTS_ITEM_FAILED:
      return {
        ...state,
        isLoadingClientItem: false,
        isfailedClientItem: true,
      };
    // ** Reducers for Add Client
    case REQUEST_ADD_CLIENT:
      return {
        ...state,
        isloadingAddClient: true,
      };
    case REQUEST_ADD_CLIENT_SUCCESS:
      return {
        ...state,
        isloadingAddClient: false,
        successAddClient: true,
        isfailedAddClient: false,
      };
    case REQUEST_ADD_CLIENT_FAILED:
      return {
        ...state,
        isloadingAddClient: false,
        successAddClient: false,
        isfailedAddClient: true,
      };
    //**  Reducers for Edit Client
    case REQUEST_EDIT_CLIENT:
      return {
        ...state,
        isloadingEditClient: true,
      };
    case REQUEST_EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        isloadingEditClient: false,
        successEditClient: true,
        isfailedEditClient: false,
      };
    case REQUEST_EDIT_CLIENT_FAILED:
      return {
        ...state,
        isloadingEditClient: false,
        successEditClient: false,
        isfailedEditClient: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Clients List
export const requestGetClientsList = () => {
  return {
    type: REQUEST_CLIENTS,
  };
};
export const requestGetClientsListSuccess = (clientsList, clientsTotalNum) => {
  return {
    type: REQUEST_CLIENTS_SUCCESS,
    clientsList: clientsList,
    clientsTotalNum: clientsTotalNum,
  };
};
export const requestGetClientsListFailed = (message) => {
  return {
    type: REQUEST_CLIENTS_FAILED,
  };
};

// ** Actions for Clients Item
export const requestGetClientsItem = () => {
  return {
    type: REQUEST_CLIENTS_ITEM,
  };
};
export const requestGetClientsItemSuccess = (clientItem) => {
  return {
    type: REQUEST_CLIENTS_ITEM_SUCCESS,
    clientItem: clientItem,
  };
};
export const requestGetClientsItemFailed = () => {
  return {
    type: REQUEST_CLIENTS_ITEM_FAILED,
  };
};
//** Actions For Add Client
export const requestAddClient = () => {
  return {
    type: REQUEST_ADD_CLIENT,
  };
};
export const requestAddClientSuccess = (message) => {
  return {
    type: REQUEST_ADD_CLIENT_SUCCESS,
    message: message,
  };
};
export const requestAddClientFaield = (message) => {
  return {
    type: REQUEST_ADD_CLIENT_FAILED,
    message: message,
  };
};

//** Actions For Edit Client
export const requestEditClient = () => {
  return {
    type: REQUEST_EDIT_CLIENT,
  };
};
export const requestEditClientSuccess = (message) => {
  return {
    type: REQUEST_EDIT_CLIENT_SUCCESS,
    message: message,
  };
};
export const requestEditClientFaield = (message) => {
  return {
    type: REQUEST_EDIT_CLIENT_FAILED,
    message: message,
  };
};
