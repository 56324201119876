// ** Actions Types for City
const REQUEST_CITIES = "REQUEST_CITIES";
const REQUEST_CITIES_SUCCESS = "REQUEST_CITIES_SUCCESS";
const REQUEST_CITIES_FAILED = "REQUEST_CITIES_FAILED";
// ** Actions Types for City Item
const REQUEST_CITY_ITEM = "REQUEST_CITY_ITEM";
const REQUEST_CITY_ITEM_SUCCESS = "REQUEST_CITY_ITEM_SUCCESS";
const REQUEST_CITY_ITEM_FAILED = "REQUEST_CITY_ITEM_FAILED";
//** Actions Types For Add City
const REQUEST_ADD_CITY = "REQUEST_ADD_CITY";
const REQUEST_ADD_CITY_SUCCESS = "REQUEST_ADD_CITY_SUCCESS";
const REQUEST_ADD_CITY_FAILED = "REQUEST_ADD_CITY_FAILED";
//** Actions Types For Edit City
const REQUEST_EDIT_CITY = "REQUEST_EDIT_CITY";
const REQUEST_EDIT_CITY_SUCCESS = "REQUEST_EDIT_CITY_SUCCESS";
const REQUEST_EDIT_CITY_FAILED = "REQUEST_EDIT_CITY_FAILED";

// ** Initial State
const initialState = {
  citiesList: [],
  citiesListTotalNum: "",
  isLoadingGetCitiesList: false,
  isFailedGetCitiesList: false,
  // ** State For City Item
  citytItem: {},
  isLoadingCitytItem: false,
  isFailedCitytItem: false,
  // ** Add City
  isLoadingAddCity: false,
  successAddCity: false,
  isFailedAddCity: false,
  //** Edit City
  isLoadingEditCity: false,
  successEditCity: false,
  isFailedEditCity: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for City List
    case REQUEST_CITIES:
      return {
        ...state,
        isLoadingGetCitiesList: true,
      };
    case REQUEST_CITIES_SUCCESS:
      return {
        ...state,
        citiesList: action.citiesList,
        citiesListTotalNum: action.citiesListTotalNum,
        isLoadingGetCitiesList: false,
        isFailedGetCitiesList: false,
      };
    case REQUEST_CITIES_FAILED:
      return {
        ...state,
        isLoadingGetCitiesList: false,
        isFailedGetCitiesList: true,
      };
    // ** Reducers for City Item
    case REQUEST_CITY_ITEM:
      return {
        ...state,
        isLoadingCitytItem: true,
      };
    case REQUEST_CITY_ITEM_SUCCESS:
      return {
        ...state,
        citytItem: action.citytItem,
        isLoadingCitytItem: false,
        isFailedCitytItem: false,
      };
    case REQUEST_CITY_ITEM_FAILED:
      return {
        ...state,
        isLoadingCitytItem: false,
        isFailedCitytItem: true,
      };
    // ** Reducers for Add City
    case REQUEST_ADD_CITY:
      return {
        ...state,
        isLoadingAddCity: true,
      };
    case REQUEST_ADD_CITY_SUCCESS:
      return {
        ...state,
        isLoadingAddCity: false,
        successAddCity: true,
        isFailedAddCity: false,
      };
    case REQUEST_ADD_CITY_FAILED:
      return {
        ...state,
        isLoadingAddCity: false,
        successAddCity: false,
        isFailedAddCity: true,
      };
    //**  Reducers for Edit City
    case REQUEST_EDIT_CITY:
      return {
        ...state,
        isLoadingEditCity: true,
      };
    case REQUEST_EDIT_CITY_SUCCESS:
      return {
        ...state,
        isLoadingEditCity: false,
        successEditCity: true,
        isFailedEditCity: false,
      };
    case REQUEST_EDIT_CITY_FAILED:
      return {
        ...state,
        isLoadingEditCity: false,
        successEditCity: false,
        isFailedEditCity: true,
      };
    default:
      return state;
  }
};

// ** Actions for All City List
export const requestGetCitiesList = () => {
  return {
    type: REQUEST_CITIES,
  };
};
export const requestGetCitiesListSuccess = (citiesList, citiesListTotalNum) => {
  return {
    type: REQUEST_CITIES_SUCCESS,
    citiesList: citiesList,
    citiesListTotalNum: citiesListTotalNum,
  };
};
export const requestGetCitiesListFailed = (message) => {
  return {
    type: REQUEST_CITIES_FAILED,
  };
};

// ** Actions for City Item
export const requestGetCityItem = () => {
  return {
    type: REQUEST_CITY_ITEM,
  };
};
export const requestGetCityItemSuccess = (citytItem) => {
  return {
    type: REQUEST_CITY_ITEM_SUCCESS,
    citytItem: citytItem,
  };
};
export const requestGetCityItemFailed = () => {
  return {
    type: REQUEST_CITY_ITEM_FAILED,
  };
};
//** Actions For Add City
export const requestAddCity = () => {
  return {
    type: REQUEST_ADD_CITY,
  };
};
export const requestAddCitySuccess = (message) => {
  return {
    type: REQUEST_ADD_CITY_SUCCESS,
    message: message,
  };
};
export const requestAddCityFaield = (message) => {
  return {
    type: REQUEST_ADD_CITY_FAILED,
    message: message,
  };
};

//** Actions For Edit City
export const requestEditCity = () => {
  return {
    type: REQUEST_EDIT_CITY,
  };
};
export const requestEditCitySuccess = (message) => {
  return {
    type: REQUEST_EDIT_CITY_SUCCESS,
    message: message,
  };
};
export const requestEditCityFaield = (message) => {
  return {
    type: REQUEST_EDIT_CITY_FAILED,
    message: message,
  };
};
