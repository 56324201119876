// Actions Types for News Letter
const REQUEST_NEWS_LETTER = "REQUEST_NEWS_LETTER";
const REQUEST_NEWS_LETTER_SUCCESS = "REQUEST_NEWS_LETTER_SUCCESS";
const REQUEST_NEWS_LETTER_FAILED = "REQUEST_NEWS_LETTER_FAILED";
//  Actions Types for News Letter Item
const REQUEST_NEWS_LETTER_ITEM = "REQUEST_NEWS_LETTER_ITEM";
const REQUEST_NEWS_LETTER_ITEM_SUCCESS = "REQUEST_NEWS_LETTER_ITEM_SUCCESS";
const REQUEST_NEWS_LETTER_ITEM_FAILED = "REQUEST_NEWS_LETTER_ITEM_FAILED";

// Initial State
const initialState = {
  newsLetterList: [],
  newsLetterTotalNum: "",
  isLoadingGetNewsLetterList: false,
  isFailedGetNewsLetterList: false,
  // State For News Item
  newsLetterItem: {},
  isLoadingNewsLetterItem: false,
  isFailedNewsLetterItem: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // Reducers for News Letter List
    case REQUEST_NEWS_LETTER:
      return {
        ...state,
        isLoadingGetNewsLetterList: true,
      };
    case REQUEST_NEWS_LETTER_SUCCESS:
      return {
        ...state,
        newsLetterList: action.newsLetterList,
        newsLetterTotalNum: action.newsLetterTotalNum,
        isLoadingGetNewsLetterList: false,
        isFailedGetNewsLetterList: false,
      };
    case REQUEST_NEWS_LETTER_FAILED:
      return {
        ...state,
        isLoadingGetNewsLetterList: false,
        isFailedGetNewsLetterList: true,
      };
    // Reducers for News Letter Item
    case REQUEST_NEWS_LETTER_ITEM:
      return {
        ...state,
        isLoadingNewsLetterItem: true,
      };
    case REQUEST_NEWS_LETTER_ITEM_SUCCESS:
      return {
        ...state,
        newsLetterItem: action.newsLetterItem,
        isLoadingNewsLetterItem: false,
        isFailedNewsLetterItem: false,
      };
    case REQUEST_NEWS_LETTER_ITEM_FAILED:
      return {
        ...state,
        isLoadingNewsLetterItem: false,
        isFailedNewsLetterItem: true,
      };
    default:
      return state;
  }
};

// Actions for All News Letter List
export const requestGetNewsLetterList = () => {
  return {
    type: REQUEST_NEWS_LETTER,
  };
};
export const requestGetNewsLetterListSuccess = (
  newsLetterList,
  newsLetterTotalNum
) => {
  return {
    type: REQUEST_NEWS_LETTER_SUCCESS,
    newsLetterList: newsLetterList,
    newsLetterTotalNum: newsLetterTotalNum,
  };
};
export const requestGetNewsLetterListFailed = (message) => {
  return {
    type: REQUEST_NEWS_LETTER_FAILED,
  };
};

// Actions for News Letter Item
export const requestGetNewsLetterItem = () => {
  return {
    type: REQUEST_NEWS_LETTER_ITEM,
  };
};
export const requestGetNewsLetterItemSuccess = (newsLetterItem) => {
  return {
    type: REQUEST_NEWS_LETTER_ITEM_SUCCESS,
    newsLetterItem: newsLetterItem,
  };
};
export const requestGetNewsLetterItemFailed = () => {
  return {
    type: REQUEST_NEWS_LETTER_ITEM_FAILED,
  };
};
