// ** Actions Types for Companies
const REQUEST_COMPANIES = "REQUEST_COMPANIES";
const REQUEST_COMPANIES_SUCCESS = "REQUEST_COMPANIES_SUCCESS";
const REQUEST_COMPANIES_FAILED = "REQUEST_COMPANIES_FAILED";
// ** Actions Types for Company Item
const REQUEST_COMPANIE_ITEM = "REQUEST_COMPANIE_ITEM";
const REQUEST_COMPANIE_ITEM_SUCCESS = "REQUEST_COMPANIE_ITEM_SUCCESS";
const REQUEST_COMPANIE_ITEM_FAILED = "REQUEST_COMPANIE_ITEM_FAILED";
//** Actions Types For Add Company
const REQUEST_ADD_COMPANIE = "REQUEST_ADD_COMPANIE";
const REQUEST_ADD_COMPANIE_SUCCESS = "REQUEST_ADD_COMPANIE_SUCCESS";
const REQUEST_ADD_COMPANIE_FAILED = "REQUEST_ADD_COMPANIE_FAILED";
//** Actions Types For Edit Company
const REQUEST_EDIT_COMPANIE = "REQUEST_EDIT_COMPANIE";
const REQUEST_EDIT_COMPANIE_SUCCESS = "REQUEST_EDIT_COMPANIE_SUCCESS";
const REQUEST_EDIT_COMPANIE_FAILED = "REQUEST_EDIT_COMPANIE_FAILED";

// ** Initial State
const initialState = {
  companiesList: [],
  companiesTotalNum: "",
  isLoadingGetCompaniesList: false,
  isFailedGetCompaniesList: false,
  // ** State For Company Item
  companietItem: {},
  isLoadingCompanieItem: false,
  isfailedCompanieItem: false,
  // ** Add Company
  isloadingAddCompanie: false,
  successAddCompanie: false,
  isfailedAddCompanie: false,
  //** Edit Company
  isloadingEditCompanie: false,
  successEditCompanie: false,
  isfailedEditCompanie: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Company List
    case REQUEST_COMPANIES:
      return {
        ...state,
        isLoadingGetCompaniesList: true,
      };
    case REQUEST_COMPANIES_SUCCESS:
      return {
        ...state,
        companiesList: action.companiesList,
        companiesTotalNum: action.companiesTotalNum,
        isLoadingGetCompaniesList: false,
        isFailedGetCompaniesList: false,
      };
    case REQUEST_COMPANIES_FAILED:
      return {
        ...state,
        isLoadingGetCompaniesList: false,
        isFailedGetCompaniesList: true,
      };
    // ** Reducers for Company Item
    case REQUEST_COMPANIE_ITEM:
      return {
        ...state,
        isLoadingCompanieItem: true,
      };
    case REQUEST_COMPANIE_ITEM_SUCCESS:
      return {
        ...state,
        companietItem: action.companietItem,
        isLoadingCompanieItem: false,
        isfailedCompanieItem: false,
      };
    case REQUEST_COMPANIE_ITEM_FAILED:
      return {
        ...state,
        isLoadingCompanieItem: false,
        isfailedCompanieItem: true,
      };
    // ** Reducers for Add Company
    case REQUEST_ADD_COMPANIE:
      return {
        ...state,
        isloadingAddCompanie: true,
      };
    case REQUEST_ADD_COMPANIE_SUCCESS:
      return {
        ...state,
        isloadingAddCompanie: false,
        successAddCompanie: true,
        isfailedAddCompanie: false,
      };
    case REQUEST_ADD_COMPANIE_FAILED:
      return {
        ...state,
        isloadingAddCompanie: false,
        successAddCompanie: false,
        isfailedAddCompanie: true,
      };
    //**  Reducers for Edit Company
    case REQUEST_EDIT_COMPANIE:
      return {
        ...state,
        isloadingEditCompanie: true,
      };
    case REQUEST_EDIT_COMPANIE_SUCCESS:
      return {
        ...state,
        isloadingEditCompanie: false,
        successEditCompanie: true,
        isfailedEditCompanie: false,
      };
    case REQUEST_EDIT_COMPANIE_FAILED:
      return {
        ...state,
        isloadingEditCompanie: false,
        successEditCompanie: false,
        isfailedEditCompanie: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Companies List
export const requestGetCompaniesList = () => {
  return {
    type: REQUEST_COMPANIES,
  };
};
export const requestGetCompaniesListSuccess = (
  companiesList,
  companiesTotalNum
) => {
  return {
    type: REQUEST_COMPANIES_SUCCESS,
    companiesList: companiesList,
    companiesTotalNum: companiesTotalNum,
  };
};
export const requestGetCompaniesListFailed = (message) => {
  return {
    type: REQUEST_COMPANIES_FAILED,
  };
};
// ** Actions for Company Item
export const requestGetCompanyItem = () => {
  return {
    type: REQUEST_COMPANIE_ITEM,
  };
};
export const requestGetCompanyItemSuccess = (companietItem) => {
  return {
    type: REQUEST_COMPANIE_ITEM_SUCCESS,
    companietItem: companietItem,
  };
};
export const requestGetCompanyItemFailed = () => {
  return {
    type: REQUEST_COMPANIE_ITEM_FAILED,
  };
};
//** Actions For Add Company
export const requestAddCompany = () => {
  return {
    type: REQUEST_ADD_COMPANIE,
  };
};
export const requestAddCompanySuccess = (message) => {
  return {
    type: REQUEST_ADD_COMPANIE_SUCCESS,
    message: message,
  };
};
export const requestAddCompanyFaield = (message) => {
  return {
    type: REQUEST_ADD_COMPANIE_FAILED,
    message: message,
  };
};
//** Actions For Edit Company
export const requestEditCompany = () => {
  return {
    type: REQUEST_EDIT_COMPANIE,
  };
};
export const requestEditCompanySuccess = (message) => {
  return {
    type: REQUEST_EDIT_COMPANIE_SUCCESS,
    message: message,
  };
};
export const requestEditCompanyFaield = (message) => {
  return {
    type: REQUEST_EDIT_COMPANIE_FAILED,
    message: message,
  };
};
