// ** Actions Types for Products Life Cycle Gallery List
const REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY =
  "REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY";
const REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY_SUCCESS =
  "REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY_SUCCESS";
const REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY_FAILED =
  "REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY_FAILED";
// ** Actions Types for Product Life Cycle Gallery Item
const REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM =
  "REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM";
const REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM_SUCCESS =
  "REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM_SUCCESS";
const REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM_FAILED =
  "REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM_FAILED";
//** Actions Types For Add Product Life Cycle Gallery
const REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY =
  "REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY";
const REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY_SUCCESS =
  "REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY_SUCCESS";
const REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY_FAILED =
  "REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY_FAILED";
//** Actions Types For Edit Product Life Cycle Gallery
const REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY =
  "REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY";
const REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY_SUCCESS =
  "REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY_SUCCESS";
const REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY_FAILED =
  "REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY_FAILED";

// ** Initial State
const initialState = {
  productsLifeCycleGalleryList: [],
  productsLifeCycleGalleryPagesCount: "",
  isLoadingGetProductsLifeCycleGalleryList: false,
  isFailedGetProductsLifeCycleGalleryList: false,
  // ** State For Product Life Cycle Gallery Item
  productLifeCycleGalleryItem: {},
  isLoadingProductLifeCycleGalleryItem: false,
  isFailedProductLifeCycleGalleryItem: false,
  // ** Add Product Life Cycle Gallery
  isLoadingAddProductLifeCycleGallery: false,
  successAddProductLifeCycleGallery: false,
  isFailedAddProductLifeCycleGallery: false,
  //** Edit Product Life Cycle Gallery
  isLoadingEditProductLifeCycleGallery: false,
  successEditProductLifeCycleGallery: false,
  isFailedEditProductLifeCycleGallery: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Products Life Cycle Gallery List
    case REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY:
      return {
        ...state,
        isLoadingGetProductsLifeCycleGalleryList: true,
      };
    case REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY_SUCCESS:
      return {
        ...state,
        productsLifeCycleGalleryList: action.productsLifeCycleGalleryList,
        productsLifeCycleGalleryPagesCount:
          action.productsLifeCycleGalleryPagesCount,
        isLoadingGetProductsLifeCycleGalleryList: false,
        isFailedGetProductsLifeCyclesList: false,
      };
    case REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY_FAILED:
      return {
        ...state,
        isLoadingGetProductsLifeCycleGalleryList: false,
        isFailedGetProductsLifeCyclesList: true,
      };
    // ** Reducers for Product Life Cycle Gallery Item
    case REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM:
      return {
        ...state,
        isLoadingProductLifeCycleGalleryItem: true,
      };
    case REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM_SUCCESS:
      return {
        ...state,
        productLifeCycleGalleryItem: action.productLifeCycleGalleryItem,
        isLoadingProductLifeCycleGalleryItem: false,
        isFailedProductLifeCycleGalleryItem: false,
      };
    case REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM_FAILED:
      return {
        ...state,
        isLoadingProductLifeCycleGalleryItem: false,
        isFailedProductLifeCycleGalleryItem: true,
      };
    // ** Reducers for Add Product Life Cycle Gallery
    case REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY:
      return {
        ...state,
        isLoadingAddProductLifeCycleGallery: true,
      };
    case REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY_SUCCESS:
      return {
        ...state,
        isLoadingAddProductLifeCycleGallery: false,
        successAddProductLifeCycleGallery: true,
        isFailedAddProductLifeCycleGallery: false,
      };
    case REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY_FAILED:
      return {
        ...state,
        isLoadingAddProductLifeCycleGallery: false,
        successAddProductLifeCycleGallery: false,
        isFailedAddProductLifeCycleGallery: true,
      };
    //**  Reducers for Edit Product Life Cycle Gallery
    case REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY:
      return {
        ...state,
        isLoadingEditProductLifeCycleGallery: true,
      };
    case REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY_SUCCESS:
      return {
        ...state,
        isLoadingEditProductLifeCycleGallery: false,
        successEditProductLifeCycleGallery: true,
        isFailedEditProductLifeCycleGallery: false,
      };
    case REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY_FAILED:
      return {
        ...state,
        isLoadingEditProductLifeCycleGallery: false,
        successEditProductLifeCycleGallery: false,
        isFailedEditProductLifeCycleGallery: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Products Life Cycle Gallery
export const requestGetProductsLifeCyclesGalleryList = () => {
  return {
    type: REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY,
  };
};
export const requestGetProductsLifeCyclesGalleryListSuccess = (
  productsLifeCycleGalleryList,
  productsLifeCycleGalleryPagesCount
) => {
  return {
    type: REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY_SUCCESS,
    productsLifeCycleGalleryList: productsLifeCycleGalleryList,
    productsLifeCycleGalleryPagesCount: productsLifeCycleGalleryPagesCount,
  };
};
export const requestGetProductsLifeCyclesGalleryListFailed = (message) => {
  return {
    type: REQUEST_PRODUCTS_LIFE_CYCLE_GALLERY_FAILED,
  };
};

// ** Actions for Product Life Cycle Gallery Item
export const requestGetProductLifeCycleGalleryItem = () => {
  return {
    type: REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM,
  };
};
export const requestGetProductLifeCycleGalleryItemSuccess = (
  productLifeCycleGalleryItem
) => {
  return {
    type: REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM_SUCCESS,
    productLifeCycleGalleryItem: productLifeCycleGalleryItem,
  };
};
export const requestGetProductLifeCycleGalleryItemFailed = () => {
  return {
    type: REQUEST_PRODUCT_LIFE_CYCLE_GALLERY_ITEM_FAILED,
  };
};

//** Actions For Add Product Life Cycle Gallery
export const requestAddProductLifeCycleGallery = () => {
  return {
    type: REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY,
  };
};
export const requestAddProductLifeCycleGallerySuccess = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestAddProductLifeCycleGalleryFaield = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_LIFE_CYCLE_GALLERY_FAILED,
    message: message,
  };
};

//** Actions For Edit Product Life Cycle Gallery
export const requestEditProductLifeCycleGallery = () => {
  return {
    type: REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY,
  };
};
export const requestEditProductLifeCycleGallerySuccess = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestEditProductLifeCycleGalleryFaield = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_LIFE_CYCLE_GALLERY_FAILED,
    message: message,
  };
};
