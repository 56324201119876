// ** Actions Types for Candidate Employment Phases
const REQUEST_CANDIDATE_EMPLOYMENT_PHASES =
  "REQUEST_CANDIDATE_EMPLOYMENT_PHASES";
const REQUEST_CANDIDATE_EMPLOYMENT_PHASES_SUCCESS =
  "REQUEST_CANDIDATE_EMPLOYMENT_PHASES_SUCCESS ";
const REQUEST_CANDIDATE_EMPLOYMENT_PHASES_FAILED =
  "REQUEST_CANDIDATE_EMPLOYMENT_PHASES_FAILED";
// ** Actions Types for Candidate Employment Phase Item
const REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM =
  "REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM";
const REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM_SUCCESS =
  "REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM_SUCCESS";
const REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM_FAILED =
  "REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM_FAILED";
//** Actions Types For Add Candidate Employment Phase
const REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE =
  "REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE";
const REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE_SUCCESS =
  "REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE_SUCCESS";
const REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE_FAILED =
  "REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE_FAILED";
//** Actions Types For Edit Candidate Employment Phase
const REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE =
  "REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE";
const REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE_SUCCESS =
  "REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE_SUCCESS";
const REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE_FAILED =
  "REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE_FAILED";

// ** Initial State
const initialState = {
  candidateEmploymentPhasesList: [],
  candidateEmploymentPhasesTotalNum: "",
  isLoadingGetCandidateEmploymentPhasesList: false,
  isFailedGetCandidateEmploymentPhasesList: false,
  // ** State For Candidate Employment Phases Item
  candidateEmploymentPhasesItem: {},
  isLoadingCandidateEmploymentPhasesItem: false,
  isFailedCandidateEmploymentPhasesItem: false,
  // ** Add Candidate Employment Phases
  isLoadingAddCandidateEmploymentPhases: false,
  successAddCandidateEmploymentPhases: false,
  isFailedAddCandidateEmploymentPhases: false,
  //** Edit Candidate Employment Phases
  isLoadingEditCandidateEmploymentPhases: false,
  successEditCandidateEmploymentPhases: false,
  isFailedEditCandidateEmploymentPhases: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Candidate Employment Phases List
    case REQUEST_CANDIDATE_EMPLOYMENT_PHASES:
      return {
        ...state,
        isLoadingGetCandidateEmploymentPhasesList: true,
      };
    case REQUEST_CANDIDATE_EMPLOYMENT_PHASES_SUCCESS:
      return {
        ...state,
        candidateEmploymentPhasesList: action.candidateEmploymentPhasesList,
        candidateEmploymentPhasesTotalNum:
          action.candidateEmploymentPhasesTotalNum,
        isLoadingGetCandidateEmploymentPhasesList: false,
        isFailedGetCandidateEmploymentPhasesList: false,
      };
    case REQUEST_CANDIDATE_EMPLOYMENT_PHASES_FAILED:
      return {
        ...state,
        isLoadingGetCandidateEmploymentPhasesList: false,
        isFailedGetCandidateEmploymentPhasesList: true,
      };
    // ** Reducers for Candidate Employment Phase Item
    case REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM:
      return {
        ...state,
        isLoadingCandidateEmploymentPhasesItem: true,
      };
    case REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM_SUCCESS:
      return {
        ...state,
        candidateEmploymentPhasesItem: action.candidateEmploymentPhasesItem,
        isLoadingCandidateEmploymentPhasesItem: false,
        isFailedCandidateEmploymentPhasesItem: false,
      };
    case REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM_FAILED:
      return {
        ...state,
        isLoadingCandidateEmploymentPhasesItem: false,
        isFailedCandidateEmploymentPhasesItem: true,
      };
    // ** Reducers for Add Candidate Employment Phase
    case REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE:
      return {
        ...state,
        isLoadingAddCandidateEmploymentPhases: true,
      };
    case REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE_SUCCESS:
      return {
        ...state,
        isLoadingAddCandidateEmploymentPhases: false,
        successAddCandidateEmploymentPhases: true,
        isFailedAddCandidateEmploymentPhases: false,
      };
    case REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE_FAILED:
      return {
        ...state,
        isLoadingAddCandidateEmploymentPhases: false,
        successAddCandidateEmploymentPhases: false,
        isFailedAddCandidateEmploymentPhases: true,
      };
    //**  Reducers for Edit Candidate Employment Phase
    case REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE:
      return {
        ...state,
        isLoadingEditCandidateEmploymentPhases: true,
      };
    case REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE_SUCCESS:
      return {
        ...state,
        isLoadingEditCandidateEmploymentPhases: false,
        successEditCandidateEmploymentPhases: true,
        isFailedEditCandidateEmploymentPhases: false,
      };
    case REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE_FAILED:
      return {
        ...state,
        isLoadingEditCandidateEmploymentPhases: false,
        successEditCandidateEmploymentPhases: false,
        isFailedEditCandidateEmploymentPhases: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Candidate Employment Phases List
export const requestGetCandidateEmploymentPhasesList = () => {
  return {
    type: REQUEST_CANDIDATE_EMPLOYMENT_PHASES,
  };
};
export const requestGetCandidateEmploymentPhasesListSuccess = (
  candidateEmploymentPhasesList,
  candidateEmploymentPhasesTotalNum
) => {
  return {
    type: REQUEST_CANDIDATE_EMPLOYMENT_PHASES_SUCCESS,
    candidateEmploymentPhasesList: candidateEmploymentPhasesList,
    candidateEmploymentPhasesTotalNum: candidateEmploymentPhasesTotalNum,
  };
};
export const requestGetCandidateEmploymentPhasesListFailed = (message) => {
  return {
    type: REQUEST_CANDIDATE_EMPLOYMENT_PHASES_FAILED,
  };
};

// ** Actions for Candidate Employment Phase Item
export const requestGetCandidateEmploymentPhaseItem = () => {
  return {
    type: REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM,
  };
};
export const requestGetCandidateEmploymentPhaseItemSuccess = (
  candidateEmploymentPhasesItem
) => {
  return {
    type: REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM_SUCCESS,
    candidateEmploymentPhasesItem: candidateEmploymentPhasesItem,
  };
};
export const requestGetCandidateEmploymentPhaseItemFailed = () => {
  return {
    type: REQUEST_CANDIDATE_EMPLOYMENT_PHASE_ITEM_FAILED,
  };
};

//** Actions For Add Candidate Employment Phase
export const requestAddCandidateEmploymentPhase = () => {
  return {
    type: REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE,
  };
};
export const requestAddCandidateEmploymentPhaseSuccess = (message) => {
  return {
    type: REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE_SUCCESS,
    message: message,
  };
};
export const requestAddCandidateEmploymentPhaseFaield = (message) => {
  return {
    type: REQUEST_ADD_CANDIDATE_EMPLOYMENT_PHASE_FAILED,
    message: message,
  };
};

//** Actions For Edit Candidate Employment Phase
export const requestEditCandidateEmploymentPhase = () => {
  return {
    type: REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE,
  };
};
export const requestEditCandidateEmploymentPhaseSuccess = (message) => {
  return {
    type: REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE_SUCCESS,
    message: message,
  };
};
export const requestEditCandidateEmploymentPhaseFaield = (message) => {
  return {
    type: REQUEST_EDIT_CANDIDATE_EMPLOYMENT_PHASE_FAILED,
    message: message,
  };
};
