// Actions Types for Job Candidates
const REQUEST_JOB_CANDIDATES = "REQUEST_JOB_CANDIDATES";
const REQUEST_JOB_CANDIDATES_SUCCESS = "REQUEST_JOB_CANDIDATES_SUCCESS";
const REQUEST_JOB_CANDIDATES_FAILED = "REQUEST_JOB_CANDIDATES_FAILED";
//  Actions Types for Job Candidate Item
const REQUEST_JOB_CANDIDATE_ITEM = "REQUEST_JOB_CANDIDATE_ITEM";
const REQUEST_JOB_CANDIDATE_ITEM_SUCCESS = "REQUEST_JOB_CANDIDATE_ITEM_SUCCESS";
const REQUEST_JOB_CANDIDATE_ITEM_FAILED = "REQUEST_JOB_CANDIDATE_ITEM_FAILED";

// Initial State
const initialState = {
  jobCandidatesList: [],
  jobCandidatesTotalNum: "",
  isLoadingGetJobCandidatesList: false,
  isFailedGetJobCandidatesList: false,
  // State For Open Applications Item
  jobCandidateItem: {},
  isLoadingJobCandidatesItem: false,
  isFailedJobCandidatesItem: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // Reducers for Job Candidates List
    case REQUEST_JOB_CANDIDATES:
      return {
        ...state,
        isLoadingGetJobCandidatesList: true,
      };
    case REQUEST_JOB_CANDIDATES_SUCCESS:
      return {
        ...state,
        jobCandidatesList: action.jobCandidatesList,
        jobCandidatesTotalNum: action.jobCandidatesTotalNum,
        isLoadingGetJobCandidatesList: false,
        isFailedGetJobCandidatesList: false,
      };
    case REQUEST_JOB_CANDIDATES_FAILED:
      return {
        ...state,
        isLoadingGetJobCandidatesList: false,
        isFailedGetJobCandidatesList: true,
      };
    // Reducers for Job Candidates Item
    case REQUEST_JOB_CANDIDATE_ITEM:
      return {
        ...state,
        isLoadingJobCandidatesItem: true,
      };
    case REQUEST_JOB_CANDIDATE_ITEM_SUCCESS:
      return {
        ...state,
        jobCandidateItem: action.jobCandidateItem,
        isLoadingJobCandidatesItem: false,
        isFailedJobCandidatesItem: false,
      };
    case REQUEST_JOB_CANDIDATE_ITEM_FAILED:
      return {
        ...state,
        isLoadingJobCandidatesItem: false,
        isFailedJobCandidatesItem: true,
      };
    default:
      return state;
  }
};

// Actions for All Job Candidates List
export const requestGetJobCandidatesList = () => {
  return {
    type: REQUEST_JOB_CANDIDATES,
  };
};
export const requestGetJobCandidatesListSuccess = (
  jobCandidatesList,
  jobCandidatesTotalNum
) => {
  return {
    type: REQUEST_JOB_CANDIDATES_SUCCESS,
    jobCandidatesList: jobCandidatesList,
    jobCandidatesTotalNum: jobCandidatesTotalNum,
  };
};
export const requestGetJobCandidatesListFailed = (message) => {
  return {
    type: REQUEST_JOB_CANDIDATES_FAILED,
  };
};

// Actions for Job Candidates  Item
export const requestGetJobCandidateItem = () => {
  return {
    type: REQUEST_JOB_CANDIDATE_ITEM,
  };
};
export const requestGetJobCandidateItemSuccess = (jobCandidateItem) => {
  return {
    type: REQUEST_JOB_CANDIDATE_ITEM_SUCCESS,
    jobCandidateItem: jobCandidateItem,
  };
};
export const requestGetJobCandidateItemFailed = () => {
  return {
    type: REQUEST_JOB_CANDIDATE_ITEM_FAILED,
  };
};
