// ** Actions Types for Team
const REQUEST_TEAM = "REQUEST_TEAM";
const REQUEST_TEAM_SUCCESS = "REQUEST_TEAM_SUCCESS";
const REQUEST_TEAM_FAILED = "REQUEST_TEAM_FAILED";
// ** Actions Types for Team Item
const REQUEST_TEAM_ITEM = "REQUEST_TEAM_ITEM";
const REQUEST_TEAM_ITEM_SUCCESS = "REQUEST_TEAM_ITEM_SUCCESS";
const REQUEST_TEAM_ITEM_FAILED = "REQUEST_TEAM_ITEM_FAILED";
//** Actions Types For Add Team Employee
const REQUEST_ADD_TEAM_EMPLOYEE = "REQUEST_ADD_TEAM_EMPLOYEE";
const REQUEST_ADD_TEAM_EMPLOYEE_SUCCESS = "REQUEST_ADD_TEAM_EMPLOYEE_SUCCESS";
const REQUEST_ADD_TEAM_EMPLOYEE_FAILED = "REQUEST_ADD_TEAM_EMPLOYEE_FAILED";
//** Actions Types For Edit Team Employee
const REQUEST_EDIT_TEAM_EMPLOYEE = "REQUEST_EDIT_TEAM_EMPLOYEE";
const REQUEST_EDIT_TEAM_EMPLOYEE_SUCCESS = "REQUEST_EDIT_TEAM_EMPLOYEE_SUCCESS";
const REQUEST_EDIT_TEAM_EMPLOYEE_FAILED = "REQUEST_EDIT_TEAM_EMPLOYEE_FAILED";

// ** Initial State
const initialState = {
  teamList: [],
  teamPagesCount: "",
  isLoadingGetTeamList: false,
  isFailedGetTeamList: false,
  // ** State For Team Item
  teamItem: {},
  isLoadingTeamItem: false,
  isfailedTeamItem: false,
  // ** Add Team Employee
  isloadingAddTeamEmployee: false,
  successAddTeamEmployee: false,
  isfailedAddTeamEmployee: false,
  //** Edit Team Employee
  isloadingEditTeamEmployee: false,
  successEditTeamEmployee: false,
  isfailedEditTeamEmployee: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Team List
    case REQUEST_TEAM:
      return {
        ...state,
        isLoadingGetTeamList: true,
      };
    case REQUEST_TEAM_SUCCESS:
      return {
        ...state,
        teamList: action.teamList,
        teamPagesCount: action.teamPagesCount,
        isLoadingGetTeamList: false,
        isFailedGetTeamList: false,
      };
    case REQUEST_TEAM_FAILED:
      return {
        ...state,
        isLoadingGetTeamList: false,
        isFailedGetTeamList: true,
      };
    // ** Reducers for Team Item
    case REQUEST_TEAM_ITEM:
      return {
        ...state,
        isLoadingTeamItem: true,
      };
    case REQUEST_TEAM_ITEM_SUCCESS:
      return {
        ...state,
        teamItem: action.teamItem,
        isLoadingTeamItem: false,
        isfailedTeamItem: false,
      };
    case REQUEST_TEAM_ITEM_FAILED:
      return {
        ...state,
        isLoadingTeamItem: false,
        isfailedTeamItem: true,
      };
    // ** Reducers for Add Team Employee
    case REQUEST_ADD_TEAM_EMPLOYEE:
      return {
        ...state,
        isloadingAddTeamEmployee: true,
      };
    case REQUEST_ADD_TEAM_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isloadingAddTeamEmployee: false,
        successAddTeamEmployee: true,
        isfailedAddTeamEmployee: false,
      };
    case REQUEST_ADD_TEAM_EMPLOYEE_FAILED:
      return {
        ...state,
        isloadingAddTeamEmployee: false,
        successAddTeamEmployee: false,
        isfailedAddTeamEmployee: true,
      };
    //**  Reducers for Edit Team Employee
    case REQUEST_EDIT_TEAM_EMPLOYEE:
      return {
        ...state,
        isloadingEditTeamEmployee: true,
      };
    case REQUEST_EDIT_TEAM_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isloadingEditTeamEmployee: false,
        successEditTeamEmployee: true,
        isfailedEditTeamEmployee: false,
      };
    case REQUEST_EDIT_TEAM_EMPLOYEE_FAILED:
      return {
        ...state,
        isloadingEditTeamEmployee: false,
        successEditTeamEmployee: false,
        isfailedEditTeamEmployee: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Team
export const requestGetTeamList = () => {
  return {
    type: REQUEST_TEAM,
  };
};
export const requestGetTeamListSuccess = (teamList, teamPagesCount) => {
  return {
    type: REQUEST_TEAM_SUCCESS,
    teamList: teamList,
    teamPagesCount: teamPagesCount,
  };
};
export const requestGetTeamListFailed = (message) => {
  return {
    type: REQUEST_TEAM_FAILED,
  };
};

// ** Actions for Team Item
export const requestGetTeamItem = () => {
  return {
    type: REQUEST_TEAM_ITEM,
  };
};
export const requestGetTeamItemSuccess = (teamItem) => {
  return {
    type: REQUEST_TEAM_ITEM_SUCCESS,
    teamItem: teamItem,
  };
};
export const requestGetTeamItemFailed = () => {
  return {
    type: REQUEST_TEAM_ITEM_FAILED,
  };
};
//** Actions For Add Team Employee
export const requestAddTeamEmployee = () => {
  return {
    type: REQUEST_ADD_TEAM_EMPLOYEE,
  };
};
export const requestAddTeamEmployeeSuccess = (message) => {
  return {
    type: REQUEST_ADD_TEAM_EMPLOYEE_SUCCESS,
    message: message,
  };
};
export const requestAddTeamEmployeeFaield = (message) => {
  return {
    type: REQUEST_ADD_TEAM_EMPLOYEE_FAILED,
    message: message,
  };
};

//** Actions For Edit Team Employee
export const requestEditTeamEmployee = () => {
  return {
    type: REQUEST_EDIT_TEAM_EMPLOYEE,
  };
};
export const requestEditTeamEmployeeSuccess = (message) => {
  return {
    type: REQUEST_EDIT_TEAM_EMPLOYEE_SUCCESS,
    message: message,
  };
};
export const requestEditTeamEmployeeFaield = (message) => {
  return {
    type: REQUEST_EDIT_TEAM_EMPLOYEE_FAILED,
    message: message,
  };
};
