// ** Actions Types for Services
const REQUEST_SERVICES = "REQUEST_SERVICES";
const REQUEST_SERVICES_SUCCESS = "REQUEST_SERVICES_SUCCESS";
const REQUEST_SERVICES_FAILED = "REQUEST_SERVICES_FAILED";
// ** Actions Types for Services Item
const REQUEST_SERVICES_ITEM = "REQUEST_SERVICES_ITEM";
const REQUEST_SERVICES_ITEM_SUCCESS = "REQUEST_SERVICES_ITEM_SUCCESS";
const REQUEST_SERVICES_ITEM_FAILED = "REQUEST_SERVICES_ITEM_FAILED";
//** Actions Types For Add Service
const REQUEST_ADD_SERVICE = "REQUEST_ADD_SERVICE";
const REQUEST_ADD_SERVICE_SUCCESS = "REQUEST_ADD_SERVICE_SUCCESS";
const REQUEST_ADD_SERVICE_FAILED = "REQUEST_ADD_SERVICE_FAILED";
//** Actions Types For Edit Service
const REQUEST_EDIT_SERVICE = "REQUEST_EDIT_SERVICE";
const REQUEST_EDIT_SERVICE_SUCCESS = "REQUEST_EDIT_SERVICE_SUCCESS";
const REQUEST_EDIT_SERVICE_FAILED = "REQUEST_EDIT_SERVICE_FAILED";

// ** Initial State
const initialState = {
  servicesList: [],
  servicesTotalNum: "",
  isLoadingGetServicesList: false,
  isFailedGetServicesList: false,
  // ** State For Services Item
  serviceItem: {},
  isLoadingServiceItem: false,
  isfailedServiceItem: false,
  // ** Add Service
  isloadingAddService: false,
  successAddService: false,
  isfailedAddService: false,
  //** Edit Service
  isloadingEditService: false,
  successEditService: false,
  isfailedEditService: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Services List
    case REQUEST_SERVICES:
      return {
        ...state,
        isLoadingGetServicesList: true,
      };
    case REQUEST_SERVICES_SUCCESS:
      return {
        ...state,
        servicesList: action.servicesList,
        servicesTotalNum: action.servicesTotalNum,
        isLoadingGetServicesList: false,
        isFailedGetServicesList: false,
      };
    case REQUEST_SERVICES_FAILED:
      return {
        ...state,
        isLoadingGetServicesList: false,
        isFailedGetServicesList: true,
      };
    // ** Reducers for Services Item
    case REQUEST_SERVICES_ITEM:
      return {
        ...state,
        isLoadingServiceItem: true,
      };
    case REQUEST_SERVICES_ITEM_SUCCESS:
      return {
        ...state,
        serviceItem: action.serviceItem,
        isLoadingServiceItem: false,
        isfailedServiceItem: false,
      };
    case REQUEST_SERVICES_ITEM_FAILED:
      return {
        ...state,
        isLoadingServiceItem: false,
        isfailedServiceItem: true,
      };
    // ** Reducers for Add Service
    case REQUEST_ADD_SERVICE:
      return {
        ...state,
        isloadingAddService: true,
      };
    case REQUEST_ADD_SERVICE_SUCCESS:
      return {
        ...state,
        isloadingAddService: false,
        successAddService: true,
        isfailedEditService: false,
      };
    case REQUEST_ADD_SERVICE_FAILED:
      return {
        ...state,
        isloadingAddService: false,
        successAddService: false,
        isfailedEditService: true,
      };
    //**  Reducers for Edit Service
    case REQUEST_EDIT_SERVICE:
      return {
        ...state,
        isloadingEditService: true,
      };
    case REQUEST_EDIT_SERVICE_SUCCESS:
      return {
        ...state,
        isloadingEditService: false,
        successEditService: true,
        isfailedEditService: false,
      };
    case REQUEST_EDIT_SERVICE_FAILED:
      return {
        ...state,
        isloadingEditService: false,
        successEditService: false,
        isfailedEditService: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Service
export const requestGetServicesList = () => {
  return {
    type: REQUEST_SERVICES,
  };
};
export const requestGetServicesListSuccess = (
  servicesList,
  servicesTotalNum
) => {
  return {
    type: REQUEST_SERVICES_SUCCESS,
    servicesList: servicesList,
    servicesTotalNum: servicesTotalNum,
  };
};
export const requestGetServicesListFailed = (message) => {
  return {
    type: REQUEST_SERVICES_FAILED,
  };
};

// ** Actions for Service Item
export const requestGetServiceItem = () => {
  return {
    type: REQUEST_SERVICES_ITEM,
  };
};
export const requestGetServiceItemSuccess = (serviceItem) => {
  return {
    type: REQUEST_SERVICES_ITEM_SUCCESS,
    serviceItem: serviceItem,
  };
};
export const requestGetServiceItemFailed = () => {
  return {
    type: REQUEST_SERVICES_ITEM_FAILED,
  };
};
//** Actions For Add Servic
export const requestAddServic = () => {
  return {
    type: REQUEST_ADD_SERVICE,
  };
};
export const requestAddServicSuccess = (message) => {
  return {
    type: REQUEST_ADD_SERVICE_SUCCESS,
    message: message,
  };
};
export const requestAddServicFaield = (message) => {
  return {
    type: REQUEST_ADD_SERVICE_FAILED,
    message: message,
  };
};

//** Actions For Edit Service
export const requestEditServic = () => {
  return {
    type: REQUEST_EDIT_SERVICE,
  };
};
export const requestEditServicSuccess = (message) => {
  return {
    type: REQUEST_EDIT_SERVICE_SUCCESS,
    message: message,
  };
};
export const requestEditServicFaield = (message) => {
  return {
    type: REQUEST_EDIT_SERVICE_FAILED,
    message: message,
  };
};
