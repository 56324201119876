// ** Actions Types for Project Gallery
const REQUEST_PROJECT_GALLERY = "REQUEST_PROJECT_GALLERY";
const REQUEST_PROJECT_GALLERY_SUCCESS = "REQUEST_PROJECT_GALLERY_SUCCESS";
const REQUEST_PROJECT_GALLERY_FAILED = "REQUEST_PROJECT_GALLERY_FAILED";
// ** Actions Types for Project Gallery Item
const REQUEST_PROJECT_GALLERY_ITEM = "REQUEST_PROJECT_GALLERY_ITEM";
const REQUEST_PROJECT_GALLERY_ITEM_SUCCESS =
  "REQUEST_PROJECT_GALLERY_ITEM_SUCCESS";
const REQUEST_PROJECT_GALLERY_ITEM_FAILED =
  "REQUEST_PROJECT_GALLERY_ITEM_FAILED";
//** Actions Types For Add Project Gallery
const REQUEST_ADD_PROJECT_GALLERY = "REQUEST_ADD_PROJECT_GALLERY";
const REQUEST_ADD_PROJECT_GALLERY_SUCCESS =
  "REQUEST_ADD_PROJECT_GALLERY_SUCCESS";
const REQUEST_ADD_PROJECT_GALLERY_FAILED = "REQUEST_ADD_PROJECT_GALLERY_FAILED";
//** Actions Types For Edit Project Gallery
const REQUEST_EDIT_PROJECT_GALLERY = "REQUEST_EDIT_PROJECT_GALLERY";
const REQUEST_EDIT_PROJECT_GALLERY_SUCCESS =
  "REQUEST_EDIT_PROJECT_GALLERY_SUCCESS";
const REQUEST_EDIT_PROJECT_GALLERY_FAILED =
  "REQUEST_EDIT_PROJECT_GALLERY_FAILED";

// ** Initial State
const initialState = {
  projectsGalleryList: [],
  projectsGalleryPagesCount: "",
  isLoadingGetProjectsGalleryList: false,
  isFailedGetProjectsGalleryList: false,
  // ** State For Project Gallery Item
  projectGalleryItem: {},
  isLoadingProjectGalleryItem: false,
  isfailedProjectGalleryItem: false,
  // ** Add Project Gallery
  isloadingAddProjectGallery: false,
  successAddProjectGallery: false,
  isfailedAddProjectGallery: false,
  //** Edit Project Gallery
  isloadingEditProjectGallery: false,
  successEditProjectGallery: false,
  isfailedEditProjectGallery: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Projects Gallery List
    case REQUEST_PROJECT_GALLERY:
      return {
        ...state,
        isLoadingGetProjectsGalleryList: true,
      };
    case REQUEST_PROJECT_GALLERY_SUCCESS:
      return {
        ...state,
        projectsGalleryList: action.projectsGalleryList,
        projectsGalleryPagesCount: action.projectsGalleryPagesCount,
        isLoadingGetProjectsGalleryList: false,
        isFailedGetProjectsGalleryList: false,
      };
    case REQUEST_PROJECT_GALLERY_FAILED:
      return {
        ...state,
        isLoadingGetProjectsGalleryList: false,
        isFailedGetProjectsGalleryList: true,
      };
    // ** Reducers for Project Gallery Item
    case REQUEST_PROJECT_GALLERY_ITEM:
      return {
        ...state,
        isLoadingProjectGalleryItem: true,
      };
    case REQUEST_PROJECT_GALLERY_ITEM_SUCCESS:
      return {
        ...state,
        projectGalleryItem: action.projectGalleryItem,
        isLoadingProjectGalleryItem: false,
        isfailedProjectGalleryItem: false,
      };
    case REQUEST_PROJECT_GALLERY_ITEM_FAILED:
      return {
        ...state,
        isLoadingProjectGalleryItem: false,
        isfailedProjectGalleryItem: true,
      };
    // ** Reducers for Add Project Gallery
    case REQUEST_ADD_PROJECT_GALLERY:
      return {
        ...state,
        isloadingAddProjectGallery: true,
      };
    case REQUEST_ADD_PROJECT_GALLERY_SUCCESS:
      return {
        ...state,
        isloadingAddProjectGallery: false,
        successAddProjectGallery: true,
        isfailedAddProjectGallery: false,
      };
    case REQUEST_ADD_PROJECT_GALLERY_FAILED:
      return {
        ...state,
        isloadingAddProjectGallery: false,
        successAddProjectGallery: false,
        isfailedAddProjectGallery: true,
      };
    //**  Reducers for Edit Project Gallery
    case REQUEST_EDIT_PROJECT_GALLERY:
      return {
        ...state,
        isloadingEditProjectGallery: true,
      };
    case REQUEST_EDIT_PROJECT_GALLERY_SUCCESS:
      return {
        ...state,
        isloadingEditProjectGallery: false,
        successEditProjectGallery: true,
        isfailedEditProjectGallery: false,
      };
    case REQUEST_EDIT_PROJECT_GALLERY_FAILED:
      return {
        ...state,
        isloadingEditProjectGallery: false,
        successEditProjectGallery: false,
        isfailedEditProjectGallery: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Projects Gallery
export const requestGetProjectsGalleryList = () => {
  return {
    type: REQUEST_PROJECT_GALLERY,
  };
};
export const requestGetProjectsGalleryListSuccess = (
  projectsGalleryList,
  projectsGalleryPagesCount
) => {
  return {
    type: REQUEST_PROJECT_GALLERY_SUCCESS,
    projectsGalleryList: projectsGalleryList,
    projectsGalleryPagesCount: projectsGalleryPagesCount,
  };
};
export const requestGetProjectsGalleryListFailed = (message) => {
  return {
    type: REQUEST_PROJECT_GALLERY_FAILED,
  };
};

// ** Actions for Project Gallery Item
export const requestGetProjectGalleryItem = () => {
  return {
    type: REQUEST_PROJECT_GALLERY_ITEM,
  };
};
export const requestGetProjectGalleryItemSuccess = (projectGalleryItem) => {
  return {
    type: REQUEST_PROJECT_GALLERY_ITEM_SUCCESS,
    projectGalleryItem: projectGalleryItem,
  };
};
export const requestGetProjectGalleryItemFailed = () => {
  return {
    type: REQUEST_PROJECT_GALLERY_ITEM_FAILED,
  };
};

//** Actions For Add Project Gallery
export const requestAddProjectGallery = () => {
  return {
    type: REQUEST_ADD_PROJECT_GALLERY,
  };
};
export const requestAddProjectGallerySuccess = (message) => {
  return {
    type: REQUEST_ADD_PROJECT_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestAddProjectGalleryFaield = (message) => {
  return {
    type: REQUEST_ADD_PROJECT_GALLERY_FAILED,
    message: message,
  };
};

//** Actions For Edit Project Gallery
export const requestEditProjectGallery = () => {
  return {
    type: REQUEST_EDIT_PROJECT_GALLERY,
  };
};
export const requestEditProjectGallerySuccess = (message) => {
  return {
    type: REQUEST_EDIT_PROJECT_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestEditProjectGalleryFaield = (message) => {
  return {
    type: REQUEST_EDIT_PROJECT_GALLERY_FAILED,
    message: message,
  };
};
