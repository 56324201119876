// ** Actions Types for FAQS
const REQUEST_FAQS = "REQUEST_FAQS";
const REQUEST_FAQS_SUCCESS = "REQUEST_FAQS_SUCCESS";
const REQUEST_FAQS_FAILED = "REQUEST_FAQS_FAILED";
// ** Actions Types for FAQS Item
const REQUEST_FAQS_ITEM = "REQUEST_FAQS_ITEM";
const REQUEST_FAQS_ITEM_SUCCESS = "REQUEST_FAQS_ITEM_SUCCESS";
const REQUEST_FAQS_ITEM_FAILED = "REQUEST_FAQS_ITEM_FAILED";
//** Actions Types For Add FAQ
const REQUEST_ADD_FAQ= "REQUEST_ADD_FAQ";
const REQUEST_ADD_FAQ_SUCCESS = "REQUEST_ADD_FAQ_SUCCESS";
const REQUEST_ADD_FAQ_FAILED = "REQUEST_ADD_FAQ_FAILED";
//** Actions Types For Edit FAQ
const REQUEST_EDIT_FAQ = "REQUEST_EDIT_FAQ";
const REQUEST_EDIT_FAQ_SUCCESS = "REQUEST_EDIT_FAQ_SUCCESS";
const REQUEST_EDIT_FAQ_FAILED = "REQUEST_EDIT_FAQ_FAILED";

// ** Initial State
const initialState = {
    FaqsList: [],
    FaqsTotalNum: "",
    isLoadingGetFaqsList: false,
    isFailedGetFaqsList: false,
    // ** State For FAQs Item
    faqItem: {},
    isLoadingFaqItem: false,
    isfailedFaqItem: false,
    // ** Add FAQ
    isloadingAddFaq: false,
    successAddFaq: false,
    isfailedAddFaq: false,
    //** Edit FAQ
    isloadingEditFaq: false,
    successEditFaq: false,
    isfailedEditFaq: false,
  };
  
  // Reducer
export default (state = initialState, action) => {
    switch (action.type) {
      // ** Reducers for Faqs List
      case REQUEST_FAQS:
        return {
          ...state,
          isLoadingGetfaqsList: true,
        };
      case REQUEST_FAQS_SUCCESS:
        return {
          ...state,
          faqsList: action.faqsList,
          faqsTotalNum: action.faqsTotalNum,
          isLoadingGetfaqsList: false,
          isFailedGetfaqsList: false,
        };
      case REQUEST_FAQS_FAILED:
        return {
          ...state,
          isLoadingGetFaqsList: false,
          isFailedGetFaqsList: true,
        };
      // ** Reducers for Faqs Item
      case REQUEST_FAQS_ITEM:
        return {
          ...state,
          isLoadingFaqItem: true,
        };
      case REQUEST_FAQS_ITEM_SUCCESS:
        return {
          ...state,
          faqItem: action.faqItem,
          isLoadingFaqItem: false,
          isfailedFaqItem: false,
        };
      case REQUEST_FAQS_ITEM_FAILED:
        return {
          ...state,
          isLoadingFaqItem: false,
          isfailedFaqItem: true,
        };
      // ** Reducers for Add Faq
      case REQUEST_ADD_FAQ:
        return {
          ...state,
          isloadingAddFaq: true,
        };
      case REQUEST_ADD_FAQ_SUCCESS:
        return {
          ...state,
          isloadingAddFaq: false,
          successAddFaq: true,
          isfailedAddFaq: false,
        };
      case REQUEST_ADD_FAQ_FAILED:
        return {
          ...state,
          isloadingAddFaq: false,
          successAddFaq: false,
          isfailedAddFaq: true,
        };
      //**  Reducers for Edit Faq
      case REQUEST_EDIT_FAQ:
        return {
          ...state,
          isloadingEditFaq: true,
        };
      case REQUEST_EDIT_FAQ_SUCCESS:
        return {
          ...state,
          isloadingEditFaq: false,
          successEditFaq: true,
          isfailedEditFaq: false,
        };
      case REQUEST_EDIT_FAQ_FAILED:
        return {
          ...state,
          isloadingEditFaq: false,
          successEditFaq: false,
          isfailedEditFaq: true,
        };
      default:
        return state;
    }
  };

  // ** Actions for All Faqs List
export const requestGetFaqsList = () => {
    return {
      type: REQUEST_FAQS,
    };
  };
  export const requestGetFaqsListSuccess = (faqsList, faqsTotalNum) => {
    return {
      type: REQUEST_FAQS_SUCCESS,
      faqsList: faqsList,
      faqsTotalNum: faqsTotalNum,
    };
  };
  export const requestGetFaqsListFailed = (message) => {
    return {
      type: REQUEST_FAQS_FAILED,
    };
  };
  
  // ** Actions for Faqs Item
  export const requestGetFaqsItem = () => {
    return {
      type: REQUEST_FAQS_ITEM,
    };
  };
  export const requestGetFaqsItemSuccess = (faqItem) => {
    return {
      type: REQUEST_FAQS_ITEM_SUCCESS,
      faqItem: faqItem,
    };
  };
  export const requestGetFaqsItemFailed = () => {
    return {
      type: REQUEST_FAQS_ITEM_FAILED,
    };
  };
  //** Actions For Add Faq
  export const requestAddFaq = () => {
    return {
      type: REQUEST_ADD_FAQ,
    };
  };
  export const requestAddFaqSuccess = (message) => {
    return {
      type: REQUEST_ADD_FAQ_SUCCESS,
      message: message,
    };
  };
  export const requestAddFaqFaield = (message) => {
    return {
      type: REQUEST_ADD_FAQ_FAILED,
      message: message,
    };
  };
  
  //** Actions For Edit Faq
  export const requestEditFaq = () => {
    return {
      type: REQUEST_EDIT_FAQ,
    };
  };
  export const requestEditFaqSuccess = (message) => {
    return {
      type: REQUEST_EDIT_FAQ_SUCCESS,
      message: message,
    };
  };
  export const requestEditFaqFaield = (message) => {
    return {
      type: REQUEST_EDIT_FAQ_FAILED,
      message: message,
    };
  };