// ** Actions Types for Projects Life Cycle Gallery List
const REQUEST_PROJECTS_LIFE_CYCLE_GALLERY =
  "REQUEST_PROJECTS_LIFE_CYCLE_GALLERY";
const REQUEST_PROJECTS_LIFE_CYCLE_GALLERY_SUCCESS =
  "REQUEST_PROJECTS_LIFE_CYCLE_GALLERY_SUCCESS";
const REQUEST_PROJECTS_LIFE_CYCLE_GALLERY_FAILED =
  "REQUEST_PROJECTS_LIFE_CYCLE_GALLERY_FAILED";
// ** Actions Types for Project Life Cycle Gallery Item
const REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM =
  "REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM";
const REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM_SUCCESS =
  "REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM_SUCCESS";
const REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM_FAILED =
  "REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM_FAILED";
//** Actions Types For Add Project Life Cycle Gallery
const REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY =
  "REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY";
const REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY_SUCCESS =
  "REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY_SUCCESS";
const REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY_FAILED =
  "REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY_FAILED";
//** Actions Types For Edit Project Life Cycle Gallery
const REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY =
  "REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY";
const REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY_SUCCESS =
  "REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY_SUCCESS";
const REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY_FAILED =
  "REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY_FAILED";

// ** Initial State
const initialState = {
  projectsLifeCycleGalleryList: [],
  projectsLifeCycleGalleryPagesCount: "",
  isLoadingGetProjectsLifeCycleGalleryList: false,
  isFailedGetProjectsLifeCycleGalleryList: false,
  // ** State For Project Life Cycle Gallery Item
  projectLifeCycleGalleryItem: {},
  isLoadingProjectLifeCycleGalleryItem: false,
  isFailedProjectLifeCycleGalleryItem: false,
  // ** Add Project Life Cycle Gallery
  isLoadingAddProjectLifeCycleGallery: false,
  successAddProjectLifeCycleGallery: false,
  isFailedAddProjectLifeCycleGallery: false,
  //** Edit Project Life Cycle Gallery
  isLoadingEditProjectLifeCycleGallery: false,
  successEditProjectLifeCycleGallery: false,
  isFailedEditProjectLifeCycleGallery: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Projects Life Cycle Gallery List
    case REQUEST_PROJECTS_LIFE_CYCLE_GALLERY:
      return {
        ...state,
        isLoadingGetProjectsLifeCycleGalleryList: true,
      };
    case REQUEST_PROJECTS_LIFE_CYCLE_GALLERY_SUCCESS:
      return {
        ...state,
        projectsLifeCycleGalleryList: action.projectsLifeCycleGalleryList,
        projectsLifeCycleGalleryPagesCount:
          action.projectsLifeCycleGalleryPagesCount,
        isLoadingGetProjectsLifeCycleGalleryList: false,
        isFailedGetProjectsLifeCyclesList: false,
      };
    case REQUEST_PROJECTS_LIFE_CYCLE_GALLERY_FAILED:
      return {
        ...state,
        isLoadingGetProjectsLifeCycleGalleryList: false,
        isFailedGetProjectsLifeCyclesList: true,
      };
    // ** Reducers for Project Life Cycle Gallery Item
    case REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM:
      return {
        ...state,
        isLoadingProjectLifeCycleGalleryItem: true,
      };
    case REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM_SUCCESS:
      return {
        ...state,
        projectLifeCycleGalleryItem: action.projectLifeCycleGalleryItem,
        isLoadingProjectLifeCycleGalleryItem: false,
        isFailedProjectLifeCycleGalleryItem: false,
      };
    case REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM_FAILED:
      return {
        ...state,
        isLoadingProjectLifeCycleGalleryItem: false,
        isFailedProjectLifeCycleGalleryItem: true,
      };
    // ** Reducers for Add Project Life Cycle Gallery
    case REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY:
      return {
        ...state,
        isLoadingAddProjectLifeCycleGallery: true,
      };
    case REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY_SUCCESS:
      return {
        ...state,
        isLoadingAddProjectLifeCycleGallery: false,
        successAddProjectLifeCycleGallery: true,
        isFailedAddProjectLifeCycleGallery: false,
      };
    case REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY_FAILED:
      return {
        ...state,
        isLoadingAddProjectLifeCycleGallery: false,
        successAddProjectLifeCycleGallery: false,
        isFailedAddProjectLifeCycleGallery: true,
      };
    //**  Reducers for Edit Project Life Cycle Gallery
    case REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY:
      return {
        ...state,
        isLoadingEditProjectLifeCycleGallery: true,
      };
    case REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY_SUCCESS:
      return {
        ...state,
        isLoadingEditProjectLifeCycleGallery: false,
        successEditProjectLifeCycleGallery: true,
        isFailedEditProjectLifeCycleGallery: false,
      };
    case REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY_FAILED:
      return {
        ...state,
        isLoadingEditProjectLifeCycleGallery: false,
        successEditProjectLifeCycleGallery: false,
        isFailedEditProjectLifeCycleGallery: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Projects Life Cycle Gallery
export const requestGetProjectsLifeCyclesGalleryList = () => {
  return {
    type: REQUEST_PROJECTS_LIFE_CYCLE_GALLERY,
  };
};
export const requestGetProjectsLifeCyclesGalleryListSuccess = (
  projectsLifeCycleGalleryList,
  projectsLifeCycleGalleryPagesCount
) => {
  return {
    type: REQUEST_PROJECTS_LIFE_CYCLE_GALLERY_SUCCESS,
    projectsLifeCycleGalleryList: projectsLifeCycleGalleryList,
    projectsLifeCycleGalleryPagesCount: projectsLifeCycleGalleryPagesCount,
  };
};
export const requestGetProjectsLifeCyclesGalleryListFailed = (message) => {
  return {
    type: REQUEST_PROJECTS_LIFE_CYCLE_GALLERY_FAILED,
  };
};

// ** Actions for Project Life Cycle Gallery Item
export const requestGetProjectLifeCycleGalleryItem = () => {
  return {
    type: REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM,
  };
};
export const requestGetProjectLifeCycleGalleryItemSuccess = (
  projectLifeCycleGalleryItem
) => {
  return {
    type: REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM_SUCCESS,
    projectLifeCycleGalleryItem: projectLifeCycleGalleryItem,
  };
};
export const requestGetProjectLifeCycleGalleryItemFailed = () => {
  return {
    type: REQUEST_PROJECT_LIFE_CYCLE_GALLERY_ITEM_FAILED,
  };
};

//** Actions For Add Project Life Cycle Gallery
export const requestAddProjectLifeCycleGallery = () => {
  return {
    type: REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY,
  };
};
export const requestAddProjectLifeCycleGallerySuccess = (message) => {
  return {
    type: REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestAddProjectLifeCycleGalleryFaield = (message) => {
  return {
    type: REQUEST_ADD_PROJECT_LIFE_CYCLE_GALLERY_FAILED,
    message: message,
  };
};

//** Actions For Edit Project Life Cycle Gallery
export const requestEditProjectLifeCycleGallery = () => {
  return {
    type: REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY,
  };
};
export const requestEditProjectLifeCycleGallerySuccess = (message) => {
  return {
    type: REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestEditProjectLifeCycleGalleryFaield = (message) => {
  return {
    type: REQUEST_EDIT_PROJECT_LIFE_CYCLE_GALLERY_FAILED,
    message: message,
  };
};
