// ** Actions Types for Products Life Cycle List
const REQUEST_PRODUCTS_LIFE_CYCLES = "REQUEST_PRODUCTS_LIFE_CYCLES";
const REQUEST_PRODUCTS_LIFE_CYCLES_SUCCESS =
  "REQUEST_PRODUCTS_LIFE_CYCLES_SUCCESS";
const REQUEST_PRODUCTS_LIFE_CYCLES_FAILED =
  "REQUEST_PRODUCTS_LIFE_CYCLES_FAILED";
// ** Actions Types for Product Life Cycle  Item
const REQUEST_PRODUCT_LIFE_CYCLES_ITEM = "REQUEST_PRODUCT_LIFE_CYCLES_ITEM";
const REQUEST_PRODUCT_LIFE_CYCLES_ITEM_SUCCESS =
  "REQUEST_PRODUCT_LIFE_CYCLES_ITEM_SUCCESS";
const REQUEST_PRODUCT_LIFE_CYCLES_ITEM_FAILED =
  "REQUEST_PRODUCT_LIFE_CYCLES_ITEM_FAILED";
//** Actions Types For Add Product Life Cycle
const REQUEST_ADD_PRODUCT_LIFE_CYCLES = "REQUEST_ADD_PRODUCT_LIFE_CYCLES";
const REQUEST_ADD_PRODUCT_LIFE_CYCLES_SUCCESS =
  "REQUEST_ADD_PRODUCT_LIFE_CYCLES_SUCCESS";
const REQUEST_ADD_PRODUCT_LIFE_CYCLES_FAILED =
  "REQUEST_ADD_PRODUCT_LIFE_CYCLES_FAILED";
//** Actions Types For Edit Product Life Cycle
const REQUEST_EDIT_PRODUCT_LIFE_CYCLES = "REQUEST_EDIT_PRODUCT_LIFE_CYCLES";
const REQUEST_EDIT_PRODUCT_LIFE_CYCLES_SUCCESS =
  "REQUEST_EDIT_PRODUCT_LIFE_CYCLES_SUCCESS";
const REQUEST_EDIT_PRODUCT_LIFE_CYCLES_FAILED =
  "REQUEST_EDIT_PRODUCT_LIFE_CYCLES_FAILED";

// ** Initial State
const initialState = {
  productsLifeCyclesList: [],
  productsLifeCyclesPagesCount: "",
  isLoadingGetProductsLifeCyclesList: false,
  isFailedGetProductsLifeCyclesList: false,
  // ** State For Product Life Cycle Item
  productLifeCyclesItem: {},
  isLoadingProductLifeCyclesItem: false,
  isfailedProductLifeCyclesItem: false,
  // ** Add Product Life Cycle
  isloadingAddProductLifeCycles: false,
  successAddProductLifeCycles: false,
  isfailedAddProductLifeCycles: false,
  //** Edit Product Life Cycle
  isloadingEditProductLifeCycles: false,
  successEditProductLifeCycles: false,
  isfailedEditProductLifeCycles: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Products Life Cycle List
    case REQUEST_PRODUCTS_LIFE_CYCLES:
      return {
        ...state,
        isLoadingGetProductsLifeCyclesList: true,
      };
    case REQUEST_PRODUCTS_LIFE_CYCLES_SUCCESS:
      return {
        ...state,
        productsLifeCyclesList: action.productsLifeCyclesList,
        productsLifeCyclesPagesCount: action.productsLifeCyclesPagesCount,
        isLoadingGetProductsLifeCyclesList: false,
        isFailedGetProductsLifeCyclesList: false,
      };
    case REQUEST_PRODUCTS_LIFE_CYCLES_FAILED:
      return {
        ...state,
        isLoadingGetProductsLifeCyclesList: false,
        isFailedGetProductsLifeCyclesList: true,
      };
    // ** Reducers for Product Life Cycle Item
    case REQUEST_PRODUCT_LIFE_CYCLES_ITEM:
      return {
        ...state,
        isLoadingProductLifeCyclesItem: true,
      };
    case REQUEST_PRODUCT_LIFE_CYCLES_ITEM_SUCCESS:
      return {
        ...state,
        productLifeCyclesItem: action.productLifeCyclesItem,
        isLoadingProductLifeCyclesItem: false,
        isfailedProductLifeCyclesItem: false,
      };
    case REQUEST_PRODUCT_LIFE_CYCLES_ITEM_FAILED:
      return {
        ...state,
        isLoadingProductLifeCyclesItem: false,
        isfailedProductLifeCyclesItem: true,
      };
    // ** Reducers for Add Product Life Cycle
    case REQUEST_ADD_PRODUCT_LIFE_CYCLES:
      return {
        ...state,
        isloadingAddProductLifeCycles: true,
      };
    case REQUEST_ADD_PRODUCT_LIFE_CYCLES_SUCCESS:
      return {
        ...state,
        isloadingAddProductLifeCycles: false,
        successAddProductLifeCycles: true,
        isfailedAddProductLifeCycles: false,
      };
    case REQUEST_ADD_PRODUCT_LIFE_CYCLES_FAILED:
      return {
        ...state,
        isloadingAddProductLifeCycles: false,
        successAddProductLifeCycles: false,
        isfailedAddProductLifeCycles: true,
      };
    //**  Reducers for Edit Product Life Cycle
    case REQUEST_EDIT_PRODUCT_LIFE_CYCLES:
      return {
        ...state,
        isloadingEditProductLifeCycles: true,
      };
    case REQUEST_EDIT_PRODUCT_LIFE_CYCLES_SUCCESS:
      return {
        ...state,
        isloadingEditProductLifeCycles: false,
        successEditProductLifeCycles: true,
        isfailedEditProductLifeCycles: false,
      };
    case REQUEST_EDIT_PRODUCT_LIFE_CYCLES_FAILED:
      return {
        ...state,
        isloadingEditProductLifeCycles: false,
        successEditProductLifeCycles: false,
        isfailedEditProductLifeCycles: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Products Life Cycle
export const requestGetProductsLifeCyclesList = () => {
  return {
    type: REQUEST_PRODUCTS_LIFE_CYCLES,
  };
};
export const requestGetProductsLifeCyclesListSuccess = (
  productsLifeCyclesList,
  productsLifeCyclesPagesCount
) => {
  return {
    type: REQUEST_PRODUCTS_LIFE_CYCLES_SUCCESS,
    productsLifeCyclesList: productsLifeCyclesList,
    productsLifeCyclesPagesCount: productsLifeCyclesPagesCount,
  };
};
export const requestGetProductsLifeCyclesListFailed = (message) => {
  return {
    type: REQUEST_PRODUCTS_LIFE_CYCLES_FAILED,
  };
};

// ** Actions for Product Life Cycle Item
export const requestGetProductLifeCyclesItem = () => {
  return {
    type: REQUEST_PRODUCT_LIFE_CYCLES_ITEM,
  };
};
export const requestGetProductLifeCyclesItemSuccess = (
  productLifeCyclesItem
) => {
  return {
    type: REQUEST_PRODUCT_LIFE_CYCLES_ITEM_SUCCESS,
    productLifeCyclesItem: productLifeCyclesItem,
  };
};
export const requestGetProductLifeCyclesItemFailed = () => {
  return {
    type: REQUEST_PRODUCT_LIFE_CYCLES_ITEM_FAILED,
  };
};

//** Actions For Add Product Life Cycle
export const requestAddProductLifeCycles = () => {
  return {
    type: REQUEST_ADD_PRODUCT_LIFE_CYCLES,
  };
};
export const requestAddProductLifeCyclesSuccess = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_LIFE_CYCLES_SUCCESS,
    message: message,
  };
};
export const requestAddProductLifeCyclesFaield = (message) => {
  return {
    type: REQUEST_ADD_PRODUCT_LIFE_CYCLES_FAILED,
    message: message,
  };
};

//** Actions For Edit Product Life Cycle
export const requestEditProductLifeCycles = () => {
  return {
    type: REQUEST_EDIT_PRODUCT_LIFE_CYCLES,
  };
};
export const requestEditProductLifeCyclesSuccess = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_LIFE_CYCLES_SUCCESS,
    message: message,
  };
};
export const requestEditProductLifeCyclesFaield = (message) => {
  return {
    type: REQUEST_EDIT_PRODUCT_LIFE_CYCLES_FAILED,
    message: message,
  };
};
