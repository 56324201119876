// ** Actions Types for EVENT Gallery
const REQUEST_EVENT_GALLERY = "REQUEST_EVENT_GALLERY";
const REQUEST_EVENT_GALLERY_SUCCESS = "REQUEST_EVENT_GALLERY_SUCCESS";
const REQUEST_EVENT_GALLERY_FAILED = "REQUEST_EVENT_GALLERY_FAILED";
// ** Actions Types for EVENT Gallery Item
const REQUEST_EVENT_GALLERY_ITEM = "REQUEST_EVENT_GALLERY_ITEM";
const REQUEST_EVENT_GALLERY_ITEM_SUCCESS = "REQUEST_EVENT_GALLERY_ITEM_SUCCESS";
const REQUEST_EVENT_GALLERY_ITEM_FAILED = "REQUEST_EVENT_GALLERY_ITEM_FAILED";
//** Actions Types For Add EVENT Gallery
const REQUEST_ADD_EVENT_GALLERY = "REQUEST_ADD_EVENT_GALLERY";
const REQUEST_ADD_EVENT_GALLERY_SUCCESS = "REQUEST_ADD_EVENT_GALLERY_SUCCESS";
const REQUEST_ADD_EVENT_GALLERY_FAILED = "REQUEST_ADD_EVENT_GALLERY_FAILED";
//** Actions Types For Edit EVENT Gallery
const REQUEST_EDIT_EVENT_GALLERY = "REQUEST_EDIT_EVENT_GALLERY";
const REQUEST_EDIT_EVENT_GALLERY_SUCCESS = "REQUEST_EDIT_EVENT_GALLERY_SUCCESS";
const REQUEST_EDIT_EVENT_GALLERY_FAILED = "REQUEST_EDIT_EVENT_GALLERY_FAILED";

// ** Initial State
const initialState = {
  eventsGalleryList: [],
  eventsGalleryPagesCount: "",
  isLoadingGetEventsGalleryList: false,
  isFailedGetEventsGalleryList: false,
  // ** State For Event Gallery Item
  eventGalleryItem: {},
  isLoadingEventGalleryItem: false,
  isFailedEventGalleryItem: false,
  // ** Add Event Gallery
  isLoadingAddEventGallery: false,
  successAddEventGallery: false,
  isFailedAddEventGallery: false,
  //** Edit Event Gallery
  isLoadingEditEventGallery: false,
  successEditEventGallery: false,
  isFailedEditEventGallery: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for Event Gallery List
    case REQUEST_EVENT_GALLERY:
      return {
        ...state,
        isLoadingGetEventsGalleryList: true,
      };
    case REQUEST_EVENT_GALLERY_SUCCESS:
      return {
        ...state,
        eventsGalleryList: action.eventsGalleryList,
        eventsGalleryPagesCount: action.eventsGalleryPagesCount,
        isLoadingGetEventsGalleryList: false,
        isFailedGetEventsGalleryList: false,
      };
    case REQUEST_EVENT_GALLERY_FAILED:
      return {
        ...state,
        isLoadingGetEventsGalleryList: false,
        isFailedGetEventsGalleryList: true,
      };
    // ** Reducers for Event Gallery Item
    case REQUEST_EVENT_GALLERY_ITEM:
      return {
        ...state,
        isLoadingEventGalleryItem: true,
      };
    case REQUEST_EVENT_GALLERY_ITEM_SUCCESS:
      return {
        ...state,
        eventGalleryItem: action.eventGalleryItem,
        isLoadingEventGalleryItem: false,
        isFailedEventGalleryItem: false,
      };
    case REQUEST_EVENT_GALLERY_ITEM_FAILED:
      return {
        ...state,
        isLoadingEventGalleryItem: false,
        isFailedEventGalleryItem: true,
      };
    // ** Reducers for Add Event Gallery
    case REQUEST_ADD_EVENT_GALLERY:
      return {
        ...state,
        isLoadingAddEventGallery: true,
      };
    case REQUEST_ADD_EVENT_GALLERY_SUCCESS:
      return {
        ...state,
        isLoadingAddEventGallery: false,
        successAddEventGallery: true,
        isFailedAddEventGallery: false,
      };
    case REQUEST_ADD_EVENT_GALLERY_FAILED:
      return {
        ...state,
        isLoadingAddEventGallery: false,
        successAddEventGallery: false,
        isFailedAddEventGallery: true,
      };
    //**  Reducers for Edit Event Gallery
    case REQUEST_EDIT_EVENT_GALLERY:
      return {
        ...state,
        isLoadingEditEventGallery: true,
      };
    case REQUEST_EDIT_EVENT_GALLERY_SUCCESS:
      return {
        ...state,
        isLoadingEditEventGallery: false,
        successEditEventGallery: true,
        isFailedEditEventGallery: false,
      };
    case REQUEST_EDIT_EVENT_GALLERY_FAILED:
      return {
        ...state,
        isLoadingEditEventGallery: false,
        successEditEventGallery: false,
        isFailedEditEventGallery: true,
      };
    default:
      return state;
  }
};

// ** Actions for All Event Gallery
export const requestGetEventGalleryList = () => {
  return {
    type: REQUEST_EVENT_GALLERY,
  };
};
export const requestGetEventGalleryListSuccess = (
  eventsGalleryList,
  eventsGalleryPagesCount
) => {
  return {
    type: REQUEST_EVENT_GALLERY_SUCCESS,
    eventsGalleryList: eventsGalleryList,
    eventsGalleryPagesCount: eventsGalleryPagesCount,
  };
};
export const requestGetEventGalleryListFailed = (message) => {
  return {
    type: REQUEST_EVENT_GALLERY_FAILED,
  };
};

// ** Actions for Event Gallery Item
export const requestGetEventGalleryItem = () => {
  return {
    type: REQUEST_EVENT_GALLERY_ITEM,
  };
};
export const requestGetEventGalleryItemSuccess = (eventGalleryItem) => {
  return {
    type: REQUEST_EVENT_GALLERY_ITEM_SUCCESS,
    eventGalleryItem: eventGalleryItem,
  };
};
export const requestGetEventGalleryItemFailed = () => {
  return {
    type: REQUEST_EVENT_GALLERY_ITEM_FAILED,
  };
};

//** Actions For Add Event Gallery
export const requestAddEventGallery = () => {
  return {
    type: REQUEST_ADD_EVENT_GALLERY,
  };
};
export const requestAddEventGallerySuccess = (message) => {
  return {
    type: REQUEST_ADD_EVENT_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestAddEventGalleryFaield = (message) => {
  return {
    type: REQUEST_ADD_EVENT_GALLERY_FAILED,
    message: message,
  };
};

//** Actions For Edit Event Gallery
export const requestEditEventGallery = () => {
  return {
    type: REQUEST_EDIT_EVENT_GALLERY,
  };
};
export const requestEditEventGallerySuccess = (message) => {
  return {
    type: REQUEST_EDIT_EVENT_GALLERY_SUCCESS,
    message: message,
  };
};
export const requestEditEventGalleryFaield = (message) => {
  return {
    type: REQUEST_EDIT_EVENT_GALLERY_FAILED,
    message: message,
  };
};
