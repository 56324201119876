// ** Actions Types for PROJECT_CATEGORIES
const REQUEST_PROJECT_CATEGORIES = "REQUEST_PROJECT_CATEGORIES";
const REQUEST_PROJECT_CATEGORIES_SUCCESS = "REQUEST_PROJECT_CATEGORIES_SUCCESS";
const REQUEST_PROJECT_CATEGORIES_FAILED = "REQUEST_PROJECT_CATEGORIES_FAILED";
// ** Actions Types for PROJECT_CATEGORIES Item
const REQUEST_PROJECT_CATEGORIES_ITEM = "REQUEST_PROJECT_CATEGORIES_ITEM";
const REQUEST_PROJECT_CATEGORIES_ITEM_SUCCESS =
  "REQUEST_PROJECT_CATEGORIES_ITEM_SUCCESS";
const REQUEST_PROJECT_CATEGORIES_ITEM_FAILED =
  "REQUEST_PROJECT_CATEGORIES_ITEM_FAILED";
//** Actions Types For Add PROJECT_CATEGORIE
const REQUEST_ADD_PROJECT_CATEGORIE = "REQUEST_ADD_PROJECT_CATEGORIE";
const REQUEST_ADD_PROJECT_CATEGORIE_SUCCESS =
  "REQUEST_ADD_PROJECT_CATEGORIE_SUCCESS";
const REQUEST_ADD_PROJECT_CATEGORIE_FAILED =
  "REQUEST_ADD_PROJECT_CATEGORIE_FAILED";
//** Actions Types For Edit PROJECT_CATEGORIE
const REQUEST_EDIT_PROJECT_CATEGORIE = "REQUEST_EDIT_PROJECT_CATEGORIE";
const REQUEST_EDIT_PROJECT_CATEGORIE_SUCCESS =
  "REQUEST_EDIT_PROJECT_CATEGORIE_SUCCESS";
const REQUEST_EDIT_PROJECT_CATEGORIE_FAILED =
  "REQUEST_EDIT_PROJECT_CATEGORIE_FAILED";

// ** Initial State
const initialState = {
  projectCategoriesList: [],
  projectCategoriesTotalNum: "",
  isLoadingGetProjectCategoriesList: false,
  isFailedGetProjectCategoriesList: false,
  // ** State For PROJECT_CATEGORIES Item
  projectCategorieItem: {},
  isLoadingProjectCategorieItem: false,
  isFailedProjectCategorieItem: false,
  // ** Add PROJECT_CATEGORIE
  isLoadingAddProjectCategorie: false,
  successAddProjectCategorie: false,
  isFailedAddProjectCategorie: false,
  //** Edit PROJECT_CATEGORIES
  isLoadingEditProjectCategorie: false,
  successEditProjectCategorie: false,
  isFailedEditProjectCategorie: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // ** Reducers for PROJECT_CATEGORIES List
    case REQUEST_PROJECT_CATEGORIES:
      return {
        ...state,
        isLoadingGetProjectCategoriesList: true,
      };
    case REQUEST_PROJECT_CATEGORIES_SUCCESS:
      return {
        ...state,
        projectCategoriesList: action.projectCategoriesList,
        projectCategoriesTotalNum: action.projectCategoriesTotalNum,
        isLoadingGetProjectCategoriesList: false,
        isFailedGetProjectCategoriesList: false,
      };
    case REQUEST_PROJECT_CATEGORIES_FAILED:
      return {
        ...state,
        isLoadingGetProjectCategoriesList: false,
        isFailedGetProjectCategoriesList: true,
      };
    // ** Reducers for ProjectCategories Item
    case REQUEST_PROJECT_CATEGORIES_ITEM:
      return {
        ...state,
        isLoadingProjectCategorieItem: true,
      };
    case REQUEST_PROJECT_CATEGORIES_ITEM_SUCCESS:
      return {
        ...state,
        projectCategorieItem: action.projectCategorieItem,
        isLoadingProjectCategorieItem: false,
        isFailedProjectCategorieItem: false,
      };
    case REQUEST_PROJECT_CATEGORIES_ITEM_FAILED:
      return {
        ...state,
        isLoadingProjectCategorieItem: false,
        isFailedProjectCategorieItem: true,
      };
    // ** Reducers for Add PROJECT_CATEGORIE
    case REQUEST_ADD_PROJECT_CATEGORIE:
      return {
        ...state,
        isLoadingAddProjectCategorie: true,
      };
    case REQUEST_ADD_PROJECT_CATEGORIE_SUCCESS:
      return {
        ...state,
        isLoadingAddProjectCategorie: false,
        successAddProjectCategorie: true,
        isFailedAddProjectCategorie: false,
      };
    case REQUEST_ADD_PROJECT_CATEGORIE_FAILED:
      return {
        ...state,
        isLoadingAddProjectCategorie: false,
        successAddProjectCategorie: false,
        isFailedAddProjectCategorie: true,
      };
    //**  Reducers for Edit PROJECT_CATEGORIE
    case REQUEST_EDIT_PROJECT_CATEGORIE:
      return {
        ...state,
        isLoadingEditProjectCategorie: true,
      };
    case REQUEST_EDIT_PROJECT_CATEGORIE_SUCCESS:
      return {
        ...state,
        isLoadingEditProjectCategorie: false,
        successEditProjectCategorie: true,
        isFailedEditProjectCategorie: false,
      };
    case REQUEST_EDIT_PROJECT_CATEGORIE_FAILED:
      return {
        ...state,
        isLoadingEditProjectCategorie: false,
        successEditProjectCategorie: false,
        isFailedEditProjectCategorie: true,
      };
    default:
      return state;
  }
};

// ** Actions for All PROJECT_CATEGORIEs List
export const requestGetProjectCategoriesList = () => {
  return {
    type: REQUEST_PROJECT_CATEGORIES,
  };
};
export const requestGetProjectCategoriesListSuccess = (
  projectCategoriesList,
  projectCategoriesTotalNum
) => {
  return {
    type: REQUEST_PROJECT_CATEGORIES_SUCCESS,
    projectCategoriesList: projectCategoriesList,
    projectCategoriesTotalNum: projectCategoriesTotalNum,
  };
};
export const requestGetProjectCategoriesListFailed = (message) => {
  return {
    type: REQUEST_PROJECT_CATEGORIES_FAILED,
  };
};

// ** Actions for ProjectCategories Item
export const requestGetProjectCategoriesItem = () => {
  return {
    type: REQUEST_PROJECT_CATEGORIES_ITEM,
  };
};
export const requestGetProjectCategoriesItemSuccess = (
  projectCategorieItem
) => {
  return {
    type: REQUEST_PROJECT_CATEGORIES_ITEM_SUCCESS,
    projectCategorieItem: projectCategorieItem,
  };
};
export const requestGetProjectCategoriesItemFailed = () => {
  return {
    type: REQUEST_PROJECT_CATEGORIES_ITEM_FAILED,
  };
};
//** Actions For Add PROJECT_CATEGORIE
export const requestAddProjectCategorie = () => {
  return {
    type: REQUEST_ADD_PROJECT_CATEGORIE,
  };
};
export const requestAddProjectCategorieSuccess = (message) => {
  return {
    type: REQUEST_ADD_PROJECT_CATEGORIE_SUCCESS,
    message: message,
  };
};
export const requestAddProjectCategorieFaield = (message) => {
  return {
    type: REQUEST_ADD_PROJECT_CATEGORIE_FAILED,
    message: message,
  };
};

//** Actions For Edit PROJECT_CATEGORIE
export const requestEditProjectCategorie = () => {
  return {
    type: REQUEST_EDIT_PROJECT_CATEGORIE,
  };
};
export const requestEditProjectCategorieSuccess = (message) => {
  return {
    type: REQUEST_EDIT_PROJECT_CATEGORIE_SUCCESS,
    message: message,
  };
};
export const requestEditProjectCategorieFaield = (message) => {
  return {
    type: REQUEST_EDIT_PROJECT_CATEGORIE_FAILED,
    message: message,
  };
};
