// Actions Types for Web Visitors
const REQUEST_WEB_VISITORS = "REQUEST_WEB_VISITORS";
const REQUEST_WEB_VISITORS_SUCCESS = "REQUEST_WEB_VISITORS_SUCCESS";
const REQUEST_WEB_VISITORS_FAILED = "REQUEST_WEB_VISITORS_FAILED";
// Actions Types for Web Visitors Item
const REQUEST_WEB_VISITORS_ITEM = "REQUEST_WEB_VISITORS_ITEM";
const REQUEST_WEB_VISITORS_ITEM_SUCCESS = "REQUEST_WEB_VISITORS_ITEM_SUCCESS";
const REQUEST_WEB_VISITORS_ITEM_FAILED = "REQUEST_WEB_VISITORS_ITEM_FAILED";

// Initial State
const initialState = {
  webVisitorsList: [],
  webVisitorsTotalNum: "",
  isLoadingGetWebVisitorsList: false,
  isFailedGetWebVisitorsList: false,
  // State For Web Visitors Item
  webVisitorsItem: {},
  isLoadingWebVisitorsItem: false,
  isFailedWebVisitorsItem: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // Reducers for Web Visitors List
    case REQUEST_WEB_VISITORS:
      return {
        ...state,
        isLoadingGetWebVisitorsList: true,
      };
    case REQUEST_WEB_VISITORS_SUCCESS:
      return {
        ...state,
        webVisitorsList: action.webVisitorsList,
        webVisitorsTotalNum: action.webVisitorsTotalNum,
        isLoadingGetWebVisitorsList: false,
        isFailedGetWebVisitorsList: false,
      };
    case REQUEST_WEB_VISITORS_FAILED:
      return {
        ...state,
        isLoadingGetWebVisitorsList: false,
        isFailedGetWebVisitorsList: true,
      };
    // Reducers for Web Visitors Item
    case REQUEST_WEB_VISITORS_ITEM:
      return {
        ...state,
        isLoadingWebVisitorsItem: true,
      };
    case REQUEST_WEB_VISITORS_ITEM_SUCCESS:
      return {
        ...state,
        webVisitorsItem: action.webVisitorsItem,
        isLoadingWebVisitorsItem: false,
        isFailedWebVisitorsItem: false,
      };
    case REQUEST_WEB_VISITORS_ITEM_FAILED:
      return {
        ...state,
        isLoadingWebVisitorsItem: false,
        isFailedWebVisitorsItem: true,
      };
    default:
      return state;
  }
};

//  Actions for All Web Visitors
export const requestGetWebVisitorsList = () => {
  return {
    type: REQUEST_WEB_VISITORS,
  };
};
export const requestGetWebVisitorsListSuccess = (
  webVisitorsList,
  webVisitorsTotalNum
) => {
  return {
    type: REQUEST_WEB_VISITORS_SUCCESS,
    webVisitorsList: webVisitorsList,
    webVisitorsTotalNum: webVisitorsTotalNum,
  };
};
export const requestGetWebVisitorsListFailed = (message) => {
  return {
    type: REQUEST_WEB_VISITORS_FAILED,
  };
};

// Actions for Web Visitors Item
export const requestGetWebVisitorsItem = () => {
  return {
    type: REQUEST_WEB_VISITORS_ITEM,
  };
};
export const requestGetWebVisitorsItemSuccess = (webVisitorsItem) => {
  return {
    type: REQUEST_WEB_VISITORS_ITEM_SUCCESS,
    webVisitorsItem: webVisitorsItem,
  };
};
export const requestGetWebVisitorsItemFailed = () => {
  return {
    type: REQUEST_WEB_VISITORS_ITEM_FAILED,
  };
};
